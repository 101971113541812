import React from 'react';
import { Typography } from 'antd';

interface TypoProps {
    text: string | undefined,
    type?: "text" | "title" | "paragraph";
    className?: string
}

const { Text, Title, Paragraph } = Typography;

const WSTypo = (porps: TypoProps) => {
    const {
        text = "",
        type = "text",
        className
    } = porps
    switch (type) {
        case "text":
            return (
                <Text className={className} > {text}</Text >
            )
        case "title":
            return (
                <Title className={className}>{text}</Title>
            )
        case "paragraph":
            return (
                <Paragraph>{text}</Paragraph>
            )

    }
}

export default WSTypo