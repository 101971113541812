import React from "react";
import { withFormik } from "formik";
import * as Yup from 'yup';
import { useMutation, MutationFunction } from "@apollo/react-hooks";
// components
import { FeaturesModalForm, FeaturesModalProps } from "./FeaturesModal";
import { FeatureModal } from "../event/eventForms/CommonSubModals/FeaturesReuseableParts";
// constants
import { CallMutation } from "graphQl/graphqlHandler";
import { ImageUpload } from "./HandleImage";
import { FEATURES_NAME, MEDIA_NAME, NOTIFICATION_STATUS } from "constants/Enums";
import { addMedia } from "constants/GlobalEventTypeScript";
import NotificationWithIcon from "constants/Toster";
import { requiredMessage } from "utils/helper";
// schema
import { MODAL_ADDED } from "utils/NotificationMessages";
import { VIRTUAL_EXPO_MUTATION } from "graphQl/Features/MutationSchema";

interface VirtualExpoProps extends FeaturesModalProps {
  modalState?: number;
  showModal?: () => void;
  index?: number;
  virtualExpo?: MutationFunction;
  data?: object;
  ids: { eventId: string; featureId: string };
}
const VirtualExpo = (props: VirtualExpoProps) => {
  const {
    modalState,
    showModal,
    index,
    values,
    setFieldValue,
    loading
  } = props;

  return (
      <FeatureModal
        infoMessage="A brief paragraph to describe this item."
        showModal={modalState === index}
        onCancel={showModal}
        title="Virtual Expo"
        loading={loading}
      >
        <ImageUpload
          values={values}
          setFieldValue={setFieldValue}
        />
      </FeatureModal>
  );
};

const VirtualExpoFormikApp = withFormik<VirtualExpoProps, FeaturesModalForm>({
  mapPropsToValues: () => {
    return {
      file: [],
      removeMedia: [],
    };
  },

  validationSchema: Yup.object().shape({
    file: Yup.string().required(requiredMessage('Image')),
  }),

  handleSubmit: ({file, removeMedia}, { props, resetForm }) => {
    const { ids, virtualExpo } = props
    const { eventId } = ids;
    CallMutation(virtualExpo, {
      variables: {
        input: {
          eventId: eventId,
          addMedia: addMedia(
            (file && file[0]) || { key: "", type: "" },
            MEDIA_NAME.FEATURE_VIRTUAL_EXPO
          ),
          removeMedia: removeMedia,
        },
      },
    });
    resetForm()
  },
})(VirtualExpo);

const VirtualExpoFormikWrapper = (props: VirtualExpoProps) => {
  const [virtualExpo, { data, loading }] = useMutation(VIRTUAL_EXPO_MUTATION, {
    update(cache, { data: APIResponse }) {
      props.showModal && props.showModal();
      NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS, MODAL_ADDED(FEATURES_NAME.VIRTUAL_EXPO));
    },
  });
  return (
    <VirtualExpoFormikApp
      virtualExpo={virtualExpo}
      data={data}
      loading={loading}
      {...props}
    />
  );
};
export default VirtualExpoFormikWrapper;
