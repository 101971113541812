import React from 'react';
import TopNav from 'containers/TopNav/TopNav';

interface AppLayoutProps {
    children: React.ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = (props) => {
    const { children } = props
    return (
        <>
            <TopNav />
            {children}
        </>
    );
}

export default AppLayout;