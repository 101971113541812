import gql from "graphql-tag";
import { imageSchema } from "graphQl/CommonSchemas";

const GET_ALL_NETWORKING = gql`
  query getAllFeatureNetworking(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingNetworkArgs
    $searchTerm: String
  ) {
    getAllFeatureNetworking(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        name
        subTitle
        designation {
          _id
          name
        }
        image {
          url
        }
        department {
          _id
          name
        }
        location
      }
      totalCount
    }
  }
`;

const GET_PROVIDER = gql`
  query getProviderList($eventId: String!){
    getProviderList(eventId: $eventId){
     _id
     name
    }
  }
`;

const GET_ALL_LIVE_STREAM = gql`
  query getAllFeatureLiveStream($offset: Int, $limit: Int, $eventId: String!){
    getAllFeatureLiveStream(offset: $offset, limit: $limit, eventId: $eventId){
      nodes{
        _id
        title
        meetingUrl
        image {
          url
        }
        provider {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_BREAKOUT_SESSION = gql`
  query getAllBreakoutSession($offset: Int, $limit: Int, $eventId: String!, $sortBy: SortingArgs){
    getAllBreakoutSession(offset: $offset, limit: $limit, eventId: $eventId, sortBy: $sortBy){
      nodes{
        _id
        meetingUrl
        image {
          url
        }
        provider {
          _id
          name
        }
      }
      totalCount
    }
  }
`; 

const GET_ALL_AMA_SPONSOR = gql`
  query getAllAmaSessionSponsors($eventId: String!){
    getAllAmaSessionSponsors(eventId: $eventId) {
       _id
        name
        subTitle
        image {
          url
        }
        category {
          _id
          name
        }
    }
  }`

const GET_ALL_SPONSORS = gql`
  query getAllFeatureSponsors(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingSponsorArgs
    $searchTerm: String
  ) {
    getAllFeatureSponsors(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        name
        subTitle
        image {
          url
        }
        category {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_SCHEDULE = gql`
  query getAllSchedule(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingScheduleArgs
    $searchTerm: String
  ) {
    getAllSchedule(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        tracks {
          _id
          name
        }
        name
        sessionDate
        sessionTime
        location
      }
      totalCount
    }
  }
`;

const GET_ALL_ATTENDEES = gql`
  query getAllFeatureAttendees($offset: Int, $limit: Int, $scheduleId: String!, $searchTerm: String){
    getAllFeatureAttendees(offset: $offset, limit: $limit, scheduleId: $scheduleId, searchTerm: $searchTerm){
      nodes{
        _id
        name
        email
        subTitle
      }
      totalCount
    }
  }
`

const GET_ALL_ANNOUNCEMENTS = gql`
  query getAllFeatureAnnouncements(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingAnnouncementArgs
    $searchTerm: String
  ) {
    getAllFeatureAnnouncements(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        from
        to {
          _id
          name
        }
        message
        link
        send
        scheduleDateTime
      }
      totalCount
    }
  }
`;

const GET_ALL_PRESENTERS = gql`
  query getAllPresenters(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingPresenterArgs
    $searchTerm: String
  ) {
    getAllPresenters(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        name
        image {
          url
        }
        designation {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_CATEGORIES = gql`
  query getAllCategories($offset: Int, $limit: Int, $eventId: String!, $searchTerm: String) {
    getAllCategories(offset: $offset, limit: $limit, eventId: $eventId , searchTerm:$searchTerm) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`;

const GET_ALL_TRACKS = gql`
  query getAllTracks($offset: Int, $limit: Int, $eventId: String!, $searchTerm: String) {
    getAllTracks(offset: $offset, limit: $limit, eventId: $eventId, searchTerm: $searchTerm) {
      nodes {
        _id
        name
        color
        shortName
      }
      totalCount
    }
  }
`;

const GET_ALL_POLLS = gql`
  query getAllPolls($offset: Int, $limit: Int, $eventId: String!) {
    getAllPolls(offset: $offset, limit: $limit, eventId: $eventId) {
      nodes {
        _id
        questions {
          _id
          question
          answerOptions
        }
        isPollClosed
      }
      totalCount
    }
  }
`;

const GET_ALL_RECORDINGS = gql`
  query getAllFeatureRecordings(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingRecordingArgs
    $searchTerm: String
  ) {
    getAllFeatureRecordings(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        name
        link
        attachments {
          key
          mediaType
          mediaName
          type
          url
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_SLIDES = gql`
  query getAllFeatureSlides(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingSlideArgs
    $searchTerm: String
  ) {
    getAllFeatureSlides(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      nodes {
        _id
        name
        documentType
        attachments {
          key
          mediaType
          mediaName
          type
          url
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_VIRTUALEXPO = gql`
  query getAllVirtualExpo($offset: Int, $limit: Int, $eventId: String!) {
    getAllVirtualExpo(offset: $offset, limit: $limit, eventId: $eventId) {
      nodes {
        _id
        image {
          _id
          mediaType
          key
          isDeleted
          url
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_SURVEY_QUESTIONS = gql`
  query getAllSurveys($offset: Int, $limit: Int, $eventId: String!) {
    getAllSurveys(offset: $offset, limit: $limit, eventId: $eventId) {
      nodes {
        _id
        questions {
          _id
          question
          answerType
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_APP_GUIDE = gql`
  query getAllFeatureAppGuides($eventId: String!) {
    getAllFeatureAppGuides(eventId: $eventId) {
      _id
      name
      documentType
      attachments {
        key
        mediaType
        mediaName
        type
        url
      }
    }
  }
`;

const GET_ALL_VIP_CONTENT = gql`
  query getAllVipContent($eventId: String!) {
    getAllVipContent(eventId: $eventId) {
      _id
      name
      overview
      select {
        _id
        name
      }
      availableSession
    }
  }
`;

const GET_ALL_STREAM_OPTIONS = gql`
  query getAllStreamOptions($offset: Int, $limit: Int, $eventId: String!, $searchTerm:String) {
    getAllStreamOptions(offset: $offset, limit: $limit, eventId: $eventId, searchTerm: $searchTerm) {
      nodes {
        _id
        name {
          _id
          name
        }
        subTitle
        costing
        image {
          _id
          url
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_BOOK_MEETINGS = gql`
  query getAllBookingMeeting($offset: Int, $limit: Int, $eventId: String!) {
    getAllBookingMeeting(offset: $offset, limit: $limit, eventId: $eventId) {
      totalCount
      nodes {
        _id
        name
        subTitle
        category {
          _id
          name
        }
      }
    }
  }
`;

const GET_ALL_SCHEDULEV1 = gql`
  query getAllScheduleV1s {
    getAllScheduleV1s {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`;

const GET_ALL_AMA_SESSION = gql`
  query getAllFeatureAmaSessions(
    $offset: Int
    $limit: Int
    $eventId: String!
    $sortBy: SortingAmaSessionArgs
    $searchTerm: String
  ) {
    getAllFeatureAmaSessions(
      offset: $offset
      limit: $limit
      eventId: $eventId
      sortBy: $sortBy
      searchTerm:$searchTerm
    ) {
      nodes {
        _id
        document {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

const GET_ALL_HELP_DESK = gql`
  query getAllHelpDesk($offset: Int, $limit: Int, $moduleId: String!) {
    getAllHelpDesk(offset: $offset, limit: $limit, moduleId: $moduleId) {
      nodes {
        createdAt
        _id
        message
        image {
          url
        }
        file{
          url
        }
        attachments{
          url
        }
        userId {
          _id
          name
          image {
            _id
            url
          }
          designation {
            _id
            name
          }
          companyName
        }
        likes
        isLiked
        comments {
          comment
          file {
            url
          }
          image {
            url
          }
          userId {
            name
            image {
              url
            }
          }
        }
      }
      totalCount
    }
  }
`;
const GET_ALL_QUESTIONS = gql`
  query getAllQuestions($offset: Int, $limit: Int, $moduleId: String!) {
    getAllQuestions(offset: $offset, limit: $limit, moduleId: $moduleId) {
      nodes {
        createdAt
        _id
        message
        image {
          url
        }
        file{
          url
        }
        attachments{
          url
        }
        userId {
          _id
          name
          image {
            _id
            url
          }
          designation {
            _id
            name
          }
          companyName
        }
        likes
        isLiked
        comments {
          comment
          file {
            url
          }
          image {
            url
          }
          userId {
            name
            image {
              url
            }
          }
        }
      }
      totalCount
    }
  }
`;

const VIP_CONTENT_SELECT = gql`
  query getAllVipContentFeatures($eventId: String!) {
    getAllVipContentFeatures(eventId: $eventId) {
      _id
      name
    }
  }
`;

const GET_ADMIN_BANNER = gql`
  query getAdminBanner {
    getAdminBanner{
    _id
    ${imageSchema}
  }
}
`

const LIVE_STREAM_LOGS = gql`
query getAllLivestreamLogs($eventId: String!, $scheduleId: String, $sessionId: String){
  getAllLivestreamLogs(eventId: $eventId, scheduleId: $scheduleId, sessionId: $sessionId){
    _id
    createdAt
    user{
    name
    email
    }
    eventId{
      name
    }
  }
}
`

export {
  GET_ALL_AMA_SPONSOR,
  GET_PROVIDER,
  GET_ALL_TRACKS,
  GET_ALL_CATEGORIES,
  GET_ALL_NETWORKING,
  GET_ALL_LIVE_STREAM,
  GET_ALL_BREAKOUT_SESSION,
  GET_ALL_SPONSORS,
  GET_ALL_SCHEDULE,
  GET_ALL_ANNOUNCEMENTS,
  GET_ALL_PRESENTERS,
  GET_ALL_POLLS,
  GET_ALL_RECORDINGS,
  GET_ALL_SLIDES,
  GET_ALL_VIRTUALEXPO,
  GET_ALL_SURVEY_QUESTIONS,
  GET_ALL_APP_GUIDE,
  GET_ALL_VIP_CONTENT,
  GET_ALL_STREAM_OPTIONS,
  GET_ALL_BOOK_MEETINGS,
  GET_ALL_SCHEDULEV1,
  GET_ALL_AMA_SESSION,
  GET_ALL_HELP_DESK,
  GET_ALL_QUESTIONS,
  GET_ALL_ATTENDEES,
  VIP_CONTENT_SELECT,
  GET_ADMIN_BANNER,
  LIVE_STREAM_LOGS
};
