import React from "react";
import { Form } from "formik";
import { MutationFunction } from "@apollo/react-hooks";
import {
  WSButton,
  WSImage,
  WSInput,
} from "components/FormComponent";
import { BtnVariant } from "constants/Enums";
import images from "constants/Images";
import { getLocalInfo } from "utils/helper";
import { HelpDeskCommentProps } from "../CommonSubModals/SubModals";
import ShowComments from "./ShowComments";
import { ImageAndDoc } from "./ImageAndDoc";
import { CommentListingProps } from "constants/GlobalEventTypeScript";
export interface CommentProps extends HelpDeskCommentProps {
  livePolls?: boolean;
  comments?: CommentListingProps[];
  data?: object;
  id?: string;
  createComment?: MutationFunction;
  eventId?: string;
  commentVariables?: { offset: number; limit: number; moduleId: string };
}

const Comments = ({ comments, values, setFieldValue }: CommentProps) => {
  const userData = getLocalInfo();
  const { defaultUser, nextArrowOrange } = images;
  return (
    <>
      {comments?.length ? (
        <div className="comments-list">{ShowComments(comments)}</div>
      ) : null}
      <Form>
        <div className="comment-div d-flex position-relative">
          <div className="help-desk-comment-field">
            <WSInput
              type="text"
              name="comment"
              divClass="comment-input-div d-flex justify-content-center"
              inputClass="comment-field"
              placeholder="Write a Comments"
            />
          </div>
          <WSImage
            imagesPath={userData?.image?.url || defaultUser}
            height={42}
            width={42}
            inlineClass="inline-user-image"
          />
          <div className="d-flex help-desk-input-right-div">
            {ImageAndDoc(setFieldValue, values)}
            <WSButton
              variant={BtnVariant.LINK}
              label="Post"
              className={"post-btn"}
              inlineClass="inline-post-btn"
              rightIcon={nextArrowOrange}
              width={13}
              height={15}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default Comments;
