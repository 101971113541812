import React, { useState } from 'react'
import { Checkbox } from 'antd'
// import { useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
// constants
import images from 'constants/Images'
import TableCard from 'containers/TableCard'
import AppLayout from 'layout/AppLayout'
import { DeleteAPI } from 'utils/helper'
import {
  StreamOptionsSortBy,
  FEATURES_API,
  FEATURES_NAME,
} from 'constants/Enums'
import {
  ImageProps,
  LookupProps,
  PaginationProps,
} from 'constants/GlobalEventTypeScript'
// components
import WSTable from 'components/common/WSTable'
import {
  FeatureHeaderLeftContent,
  FeatureHeaderRightContent,
} from '../CommonSubModals/FeaturesReuseableParts'
import LiveStreamFormikWrapper from './LiveStream'
import { UserManageBtns } from 'components/common/UserWithThumbnail'
import StreamLogsModal from 'containers/StreamLogsModal'
// graphql
import {
  GET_ALL_LIVE_STREAM,
  LIVE_STREAM_LOGS,
} from 'graphQl/Features/QuerySchema'
import { DELETE_LIVE_STREAM } from 'graphQl/Features/DeleteMutation'
import { GET_SINGLE_LIVE_STREAM } from 'graphQl/Features/SingleQuerySchema'
// style
import '../eventForms.scss'
import './LiveStream.scss'
import { useLocation } from 'react-router-dom'

let locale = {
  emptyText: "You don't have any items yet!",
}

interface LiveStreamProps {
  image?: ImageProps
  meetingUrl?: string
  title?: string
  _id?: string
  provider?: LookupProps
}

const LiveStreamTable = () => {
  // const history = useHistory()
  const location = useLocation()
  // state
  const { eventId, propState } =location.state
  const [currentPage, setCurrentPage] = useState(1)
  const [isSorted, setIsSorted] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState({ state: false, data: '' })
  const [logsSessionId, setLogsSessionId] = useState<string>('')
  // destructuring
  const { getAllFeatureLiveStream, deleteFeatureLiveStream } = FEATURES_API
  // API Calls
  const { data: liveStreamData, loading } = useQuery(GET_ALL_LIVE_STREAM, {
    variables: { offset: currentPage, limit: 10, eventId: eventId },
  })
  const [
    singleLiveStream,
    { data: singleData, loading: singleLoading },
  ] = useLazyQuery(GET_SINGLE_LIVE_STREAM, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setModal({
        state: true,
        data: data.getSingleFeatureLiveStream,
      })
    },
  })

  const [sorting, { data: sortedData, loading: sortingLoading }] = useLazyQuery(
    GET_ALL_LIVE_STREAM
  )

  const [
    getLiveStreamLogs,
    { data: logsData, loading: logsLoading },
  ] = useLazyQuery(LIVE_STREAM_LOGS, { fetchPolicy: 'network-only' })

  // handle pagination
  const handelPageChange = ({ current }: PaginationProps) => {
    setIsSorted(false)
    setCurrentPage(current)
  }

  const modalClose = () => setLogsSessionId('')

  // Action buttons
  const LiveStreamActions = (details: any, eventId: any) => {
    const [open, setOpen] = useState(false)
    const { _id = '' } = details.details
    const [deleteLiveStream, { loading }] = useMutation(
      DELETE_LIVE_STREAM
    )
    const onConfirm = () => {
      DeleteAPI(
        deleteLiveStream,
        _id,
        GET_ALL_LIVE_STREAM,
        getAllFeatureLiveStream,
        deleteFeatureLiveStream,
        { offset: 1, limit: 10, eventId: eventId },
        setOpen
      )
    }
    return (
      <UserManageBtns
        loading={loading}
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
        setIsEdit={setIsEdit}
        onEdit={() => {
          singleLiveStream({
            variables: { id: _id },
          })
          setModal({
            state: true,
            data:
              (_id === singleData?.getSingleFeatureLiveStream?._id &&
                singleData?.getSingleFeatureLiveStream) ||
              '',
          })
        }}
      />
    )
  }

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'provider',
      render: (text: LookupProps) => text?.name,
    },
    {
      title: 'Meeting Url',
      dataIndex: 'meetingUrl',
      render: (text: string) => (
        <div onClick={() => window.open(text)}>{text}</div>
      ),
    },
    {
      title: 'Logs',
      render: (text: LiveStreamProps) => {
        const { _id = '', title = '' } = text
        const onClick = () => {
          setLogsSessionId(_id)
          getLiveStreamLogs({
            variables: { sessionId: _id, eventId },
          })
        }
        return (
          <div className='d-flex live-stream-logs'>
            <StreamLogsModal
              onClick={onClick}
              onModalClose={modalClose}
              apiData={logsData?.getAllLivestreamLogs || []}
              apiLoading={logsLoading}
              showModal={_id === logsSessionId}
              name={title}
            />
          </div>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: string, record: LiveStreamProps) => {
        return <LiveStreamActions details={record} eventId={eventId}/>
      },
    },
  ]

  return (
    <AppLayout>
      <div className='left-right-10'>
        <TableCard
          state={propState}
          eventTitle={FEATURES_NAME.MAIN_SESSION}
          imagePath={images.blackLeftArrow}
          tableHeaderContent={
            <div className='d-flex justify-content-between'>
              <FeatureHeaderLeftContent
                hideNewItem={
                  liveStreamData?.getAllFeatureLiveStream?.nodes?.length
                }
                NewItemModal={LiveStreamFormikWrapper}
                showNewItemModal={modal.state}
                onCancelNewItemModal={() =>
                  setModal({ state: false, data: '' })
                }
                isLoading={singleLoading}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                initData={modal.data}
              />
              <FeatureHeaderRightContent
                sortingFunction={sorting}
                currentPage={currentPage}
                setSorting={setIsSorted}
                sortByArray={StreamOptionsSortBy}
                eventId={eventId}
              />
            </div>
          }
        />
        <WSTable
          loading={loading || sortingLoading}
          dataSource={
            isSorted
              ? sortedData?.getAllFeatureLiveStream?.nodes
              : liveStreamData?.getAllFeatureLiveStream?.nodes
          }
          columns={columns}
          className='custom-table-card-features live-stream-table'
          locale={locale}
          rowSelection={Checkbox}
          onChange={handelPageChange}
          pagination={{
            pageSize: 10,
            current: currentPage,
            total: isSorted
              ? sortedData?.getAllFeatureLiveStream?.totalCount
              : liveStreamData?.getAllFeatureLiveStream?.totalCount,
          }}
        />
      </div>
    </AppLayout>
  )
}

export default LiveStreamTable
