export const imageSchemaFields = `
  _id
  url
  largeUrl
  mediaType
  mediaName
  type
  key
`

export const imageSchema = `
  image {
   ${imageSchemaFields}
  }
`

export const eventSettingSchema = `
eventSetting {
  banner {
    images {
      duration
      name
      link
      internalLinkUrl{
        _id
        name
      }
      externalLink
      _id
      ${imageSchema}
    }
  }
  loginBanner {
    ${imageSchemaFields}
  }
  appLogo {
    ${imageSchemaFields}
  }
  colorCodes {
    primary
    secondary
    ternary
  }
  cover {
    ${imageSchemaFields}
  }
  logo {
    ${imageSchemaFields}
  }
  layout {
    _id
    name
  }
}
`

export const privacySettingSchema = `
privacySetting {
  setting {
    _id
    name
  }
  code
  isHide
  poweredBy {
    eve
    byCustom
    url
  }
}
`
export const externalLinksSchema = ` 
  externalLinks {
    linkName
    linkType
    websiteUrl
    emailAddress
    socialMediaName
    phoneNumber
    openlinkExternalBrowser
    passdataToUrl
  }`;

export const UserSchema = `
  _id
  name
  image {
    _id
    url
  }
  status
  designation {
    _id
    name
  }
  companyName  
`

export const ApplicationSchema = `
  _id
  url
  iosurl
`