// created successfully notifications
// event
export const EVENT_CREATED = (state: boolean) => `Event ${state ? "updated" : "created"} successfully`
export const EVENT_CLONED = "Event cloned successfully"
export const EVENT_STORED = "Event basic detail stored successfully."
export const EVENT_NOT_PUBLISH = "Event is not published yet!!"
export const EVENT_PUBLISHED = "Event Published Successfully."
export const LINK_COPIED = "Link copied!"
export const BANNER_MESSAGE = (id?: string) => `Event Banner ${id ? "updated" : "created"} successfully`
export const BANNER_ADDED = "Banner added successfully"
// features and modals
export const FEATURES_ADDED = (state: boolean) => `Event Features ${state ? "updated" : "added"} in the event successfully.`
export const NEW_ITEM_CREATED = (featureName: string) => `New Item is created in ${featureName}`
export const MODAL_ADDED = (featureName: string) => `${featureName} added successfully`
export const MANAGER_ASSIGNED = "System Managers Assigned"
export const NEW_TRACK = "New Category created successfully"
export const NEW_ATTENDEE = "New Attendee created successfully"
export const IMPORT_FILE = "Imported one file successfully"
// user
export const USER_CREATED = (name: string) => `${name} is created successfully`
// app url
export const MANAGE_APP_URL = (state: boolean) => `App URL ${state ? "updated" : "created"} successfully` 
// cms
export const MANAGE_CMS = (state: boolean) => `Terms & Conditions ${state ? "updated" : "created"} successfully`
// deleted successfully notifications
export const RECORD_DELETED = "One Record is deleted Successfully";
export const NO_RECORD_DELETED = (no: number) => `${no} Records deleted Successfully`
export const FEATURE_REMOVED = "One Feature removed from the event"
export const BANNER_DELETED = "One Event Banner is removed successfully"
// updated successfully notifications
export const NEW_ITEM_UPDATED = (featureName: string) => `One Record is updated in ${featureName}`
export const DETAILS_UPDATED = "Details Updated Successfully";
export const PROFILE_UPDATED = "Profile updated successfully"
export const PASSWORD_CHANGED = "Password changed Successfully"

// error messages
export const SELECT_FILE = "You didn't select any file"
export const UPLOAD_CSV = "Please upload only .csv file."
export const ID_ERROR = "Record id not found"