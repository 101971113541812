import * as React from "react";
import { TimePicker } from "antd";
import { Field, FieldProps } from 'formik';
import MyErrorMessage from "components/FormComponent/WSErrorMessage";
import Labels from "./Labels/Labels";
import dayjs from 'dayjs'
import moment from 'moment'
interface TimeProps {
  className?: string;
  placeholder?: string;
  formate?: string;
  use12Hours?: boolean;
  name: string;
  label?: string;
  required?: boolean;
  infoMessage?: string;
  labelClass?: string;
  disabled?: boolean
}

const WSTime = (props: TimeProps) => {
  const {
    className,
    placeholder,
    formate = "h:mm a",
    use12Hours,
    name,
    label,
    required,
    infoMessage,
    labelClass,
    disabled
  } = props;

 
  return (
    <>
      {label && (
        <Labels
          required={required}
          infoMessage={infoMessage}
          label={label}
          labelClass={labelClass}
        />
      )}
      <Field name={name}>
        {({ form }: FieldProps) => (
          <>
            <TimePicker
              className={`width-100 ${className}`}
              // value={moment(form.values[name], formate)}
              value={dayjs(form.values[name] || moment(), formate)}
              placeholder={placeholder}
              format={formate}
              use12Hours={use12Hours}
              onChange={(e, e1) => form.setFieldValue(name, e1 || moment(moment(), formate).format(formate))}
              onBlur={() => form.setFieldTouched(name, true)}
              disabled={disabled}
            />
            <MyErrorMessage name={name} />
          </>
        )}
      </Field>
    </>
  );
};

export default WSTime;
