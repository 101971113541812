import React, { ReactNode } from "react";
import { Field, FieldProps } from "formik";
import Password from "antd/lib/input/Password";
import MyErrorMessage from "./WSErrorMessage";
import Labels from "../common/Labels/Labels";
interface InputProps {
  name: string;
  label?: string;
  required?: boolean;
  type?: string;
  divClass?: string;
  inputClass?: string;
  labelClass?: string;
  bordered?: boolean;
  placeholder?: string;
  icon?: ReactNode;
  suffix?: ReactNode;
  value?: string;
  style?: React.CSSProperties;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  infoMessage?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const WSInput = (props: InputProps) => {
  const {
    name,
    type = "",
    divClass = "",
    inputClass = "",
    labelClass,
    placeholder,
    icon,
    label,
    required,
    infoMessage,
    disabled,
    onKeyDown,
    onChange,
    value
  } = props;

  return (
    <div className={divClass}>
      {name && (
        <Labels required={required} infoMessage={infoMessage} label={label || name} labelClass={labelClass} />
      )}
      {type === "password" ? (
        <>
          <Field name={name}>
            {({ field }: FieldProps) => (
              <Password
                className={`ant-input d-flex ${inputClass}`}
                placeholder={placeholder}
                prefix={icon}
                onKeyDown={onKeyDown}
                {...field}
              />
            )}
          </Field>
          <MyErrorMessage name={name} />
        </>
      ) : type === "question" ?
          <>
            <Field
              className={`ant-input ${inputClass}`}
              placeholder={placeholder}
              prefix={icon}
              type={type}
              name={name}
              onKeyDown={onKeyDown}
              disabled={disabled}
              onChange={onChange}
            />
            <MyErrorMessage name={name} />
          </>
          : (
            <>
              <Field
                className={`ant-input ${inputClass}`}
                placeholder={placeholder}
                prefix={icon}
                type={type}
                name={name}
                onKeyDown={onKeyDown}
                disabled={disabled}
              />
              <MyErrorMessage name={name} />
            </>
          )}
    </div>
  );
};

export default WSInput;
