// Basics Fields Tips
export const eventDescTip =
    "A brief paragraph describing your event, could be copied and pasted from your event website"
export const emailTip =
    "This email serves as the main point of contact for the Socio Platform, and is invisible to app users"
export const attendeesCountTip = 
    "Number of attendees that you are anticipating for this event, helps us measure adoption of your event app"
export const startDateTimeTip = 
    "The start of all of your sessions or festivities, including pre-conference workshops, post-conference workshops, and social events"
export const endDateTimeTip = 
    "The end of all of your sessions or festivities, including pre-conference workshops, post-conference workshops, and social events"
// GeneralSettings Tips
export const previewTip = 
    "View from the app user perspective"

// Inner Modal Fields Tips
export const labelSubtitleTip =
  "The Label/Subtitle will display directly below the name throughout the app. While optional, this field can add more needed detail to any item. For speakers, consider adding the speakers' credentials and company. For sponsors, their industry or region are both great options to add!";
export const tracksTip =
    "Categorize sessions and allow users to easily filter on sessions that match their role or interest"
export const locationTip =
    "Place or room where something is located, can be linked to the location on the Map feature if used"

// Collapse Tips
export const attendeeCallToActionTip =
    "This section is optional. Users can easily engage with this item by tapping one of these buttons in the item's profile. A label and URL are required for each button utilized."
export const addInternalLinksTip =
    "Link to other areas inside of your app, such as a session to a speaker"
export const addExternalLinksTip =
    "Link to a URL outside of your app, such as a website, poll or survey"
export const externalBrowserCheckboxTip =
    "Launch link outside of the app (Safari, Chrome), recommended for donations";
export const dataToURLCheckboxTip =
    "Pass information from the app to external websites"
export const attendeeManagementTip =
    "Optional setting that allows for open, limited capacity, or closed sessions"

// Modal Header Tips
export const importHeaderTip = 
    "Import items in bulk"
export const liveStreamTip =
    "Optionally, add a live stream and/or recording link for this session."

export const trackModalTip = 
    "Categorize sessions and allow users to easily filter on sessions that match their role or interest"

// Button Tips
export const AddAttendeeTip = "Add Attendee"
export const ImportAttendeeTip = "Import Attendees"
export const LiveStreamLogsTip = "See Stream Logs"