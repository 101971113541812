import React from "react";
import { MobileTitle } from "components/common/WSTitle";
import { WSImage } from "components/FormComponent";
import { CommentListingProps } from "constants/GlobalEventTypeScript";
import images from "constants/Images";

const ShowComments = (data: CommentListingProps[]) => {
  return (
    <>
      {data.map(({ comment, file, image, userId }, index: number) => {
        const { name = "", image: userImage = { url: "" } } = userId || {};
        const { defaultUser } = images;
        return (
          <div key={index}>
            <div className="d-flex align-item-center user-info">
              <WSImage
                imagesPath={userImage?.url || defaultUser}
                width="24px"
                height="24px"
              />
              <div className="user-name-div">
                <span>{name} </span>
              </div>
            </div>
            <div className="comment-div">
              <MobileTitle name={comment} inlineClassName="comment-desc" />
              {image && (
                <WSImage
                  imagesPath={image?.url}
                  inlineClass="comment-image"
                  width="100%"
                />
              )}
              {file && (
                <span
                  className="width-100 cursor-pointer comment-file"
                  onClick={() => window.open(file?.url)}
                >
                  Click here to check file
                </span>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ShowComments;
