import { requiredMessage, invalidMessage, dateConvert } from './helper';
import * as Yup from 'yup';
import moment, { Moment } from 'moment'
import { Collapse_LinkType, poweredByName } from 'constants/Enums';

// Common Fields schema
const commonStringField = (msg: string) => Yup.string().required(requiredMessage(msg))
const fieldNotBlank = (msg = "Name") => commonStringField(msg).test(msg, `${msg} can not be Blank Spaces`, function (value) {
    return value?.trim() !== "" || false
})
const nameFieldLetters = (msg = "Name") => fieldNotBlank(msg).matches(/^[aA-zZ\s]+$/, "Only Alphabetical letters are allowed")
const emailField = (msg = "E-mail") => Yup.string().email(invalidMessage(msg)).required(requiredMessage(msg))
const skillsField = Yup.array(Yup.string()).required(requiredMessage("Skills")); 
const maxAttendee = (min = 0, max = 9999) => Yup.number().required(requiredMessage('Event Attendees')).min(min, "Number of attendees must be a positive number.").max(max, `Number of attendees can not be more than ${max}`)
const phoneNumberField = commonStringField("Phone Number").test("phone-number", "Phone Number must be between 10-12 digits", function(value){
    return value ? (value.length > 9 && value.length < 13) : true
}).matches( /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,"Invalid phone number")
const passwordField = (name = 'Password') => commonStringField(name).matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Must contain number, uppercase letter, lowercase letter, and 8 or more characters").max(20, `${name} can not be more than 20 characters`)

const htmlField = (name: string) => Yup.string().required(requiredMessage(name)).test("trimHtml", `${name} can not be blank spaces`, function (value) {
    const toNodes = (html: string) => new DOMParser().parseFromString(html, 'text/html').body.childNodes[0]
    const el = toNodes(value || '')
    return el?.textContent?.trim() ? true : false
})

const externalLinksSchema = {
    externalLinks: Yup.array().of(Yup.object().shape({
        linkType: Yup.string(),
        socialMediaName: Yup.string().when("linkType", {
            is: (value:any) => value === "Website",
            then: commonStringField("Social Media"),
            otherwise: Yup.string()
        }),
        linkName: Yup.string().when("linkType", {
            is: (value:any) => value !== undefined,
            then: commonStringField("Link Name"),
            otherwise: Yup.string()
        }),
        websiteUrl: Yup.string().when('linkType', {
            is: (value:any) => value === "Website",
            then: commonStringField("Website URL").url(invalidMessage("website URL")),
            otherwise: Yup.string().nullable()
        }),
        emailAddress: Yup.string().when('linkType', {
            is: (value:any) => value === "Email",
            then: emailField("Email Address"),
            otherwise: Yup.string().nullable()
        }),
        phoneNumber: Yup.string().when('linkType', {
            is: (value:any) => value === "Phone",
            then: phoneNumberField,
            otherwise: Yup.string().nullable()
        }),
        externalLinkSelect: Yup.mixed()
    })),
}

// Schemas
export const LoginSchema = Yup.object().shape({
    email: emailField(),
    password: commonStringField("Password"),
    rememberMe: Yup.array(Yup.string()),
});

export const ForgotPasswordSchema = Yup.object().shape({
    email: emailField(),
})

export const ResetForgetPassword = Yup.object().shape({
    password: passwordField(),
    confirmPassword: passwordField("Confirm Password")
    .test("passwords-match", "Passwords must match.", function (value) {
        return this.parent.password === value;
    }),
});


export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: commonStringField("Current Password"),
  newPassword: passwordField("New Password"),
  confirmPassword: passwordField("Confirm Password")
  .test("passwords-match", "Passwords must match.", function (value) {
      return this.parent.newPassword === value;
  }),
})

export const CommentSchema = Yup.object().shape({
    comment: fieldNotBlank("Comment"),
    file: Yup.string(),
    doc: Yup.string()
})
export const HelpDeskSendSchema = Yup.object().shape({
    message: fieldNotBlank("Message"),
    file: Yup.mixed().required(),
    doc: Yup.mixed().required()
}) 
export const ManagerDetailsSchema = Yup.object().shape({
    name: nameFieldLetters(),
    email: emailField(),
    company: fieldNotBlank("Company"),
    position: commonStringField("Position"),
    skills: skillsField,
});

export const ViewProfileSchema = Yup.object().shape({
    firstName: nameFieldLetters("First Name"),
    lastName: nameFieldLetters("Last Name"),
    email: emailField(),
    phoneNumber: phoneNumberField,
    skills: skillsField,
    designation: commonStringField("Designation"),
});

export const AdminBannerSchema = Yup.object().shape({
    loginBanner: commonStringField("Image")
})

export const EventDetailsSchema = Yup.object().shape({
    name: fieldNotBlank("Event Name").max(50, "Event Name can not be more than 50 characters."),
    description: commonStringField('Event Description'),
    emailAddressToReachYou: emailField('Event E-mail'),
    numberOfattendees: maxAttendee(),
    eventType: commonStringField("Event Type")
});

export const EventLocSchema = Yup.object().shape({
    geometricLocation: commonStringField('Event Location'),
    venueName: fieldNotBlank("Venue Name"),
    venueAddress: fieldNotBlank('Event Address'),
});

export const DateTimeSchema = Yup.object().shape({
    startDate: Yup.date().required(requiredMessage('Start date')),
    endDate: Yup.date().min(
        Yup.ref('startDate'),
        "End date can't be before Start date"
    ).required(requiredMessage('End date')),
    startTime: commonStringField('Start time'),
    endTime: commonStringField('End time').test("set-time", "End time should be more than start time.", function (value) {
        const sDate: Moment = moment(`${dateConvert(this.parent.startDate)} ${this.parent.startTime}`, "YYYY-MM-DD h:mm a")
        const eDate: Moment = moment(`${dateConvert(this.parent.endDate)} ${value}`, "YYYY-MM-DD h:mm a")
        return eDate.isAfter(sDate) || false;
    }),
});

export const PrivacySettingsSchema = Yup.object().shape({
    code: Yup.string(),
    selectCard: Yup.string(),
    hideEvent: Yup.string(),
    poweredByName: Yup.string(),
    byCustom: Yup.string().when("poweredByName", {
        is: (value:any) => value === poweredByName[2],
        then: commonStringField("By Custom").max(20, "Company Name can not be more than 20 characters."),
        otherwise: Yup.string(),
    }),
    customUrl: Yup.string().when("poweredByName", {
        is: (value:any) => value === poweredByName[2],
        then: commonStringField("Custom URL").url(invalidMessage("Company URL")),
        otherwise: Yup.string(),
    }),
})
export const GeneralSettingsSchema = Yup.object().shape({
    selectCard: Yup.string(),
    color: Yup.mixed().required(),
})


export const Schedule1v1Schema = Yup.object().shape({
    // file: commonStringField("Image"),
    name: fieldNotBlank(),
    subTitle: commonStringField('Label'),
});

export const LiveStreamSchema = Yup.object().shape({
    name: fieldNotBlank(),
    provider: commonStringField("Live stream"),
    meetingUrl: commonStringField("Meeting Url").url("Url is not valid")
});

export const BreakoutSessionSchema = Yup.object().shape({
    breakoutSession: Yup.mixed().required(),
});

export const AppGuideSchema = Yup.object().shape({
    appGuides: Yup.array().of(Yup.object().shape({
        name: fieldNotBlank(),
        documentType: commonStringField("Document Type"),
        file: Yup.mixed().required()
    }))
});

export const VIPContentSchema = Yup.object().shape({
    vipContents: Yup.array().of(Yup.object().shape({
        name: fieldNotBlank("Title Name"),
        overview: Yup.mixed().required(),
        select: commonStringField("Select"),
        availableSession: Yup.array(Yup.mixed()).required(requiredMessage("Available Session")),
    })),
});

export const AnnouncementModalSchema = Yup.object().shape({
    from: commonStringField("From"),
    to: Yup.mixed().required(),
    message: commonStringField('Message'),
    send: commonStringField('Send'),
    externalLink: Yup.string(),
    searchLink: Yup.mixed(),
    date: commonStringField('Date'),
    time: Yup.string(),
    checkNowBtnLabel: Yup.string(),
    attendeeSelect: Yup.mixed().required(),
});

export const NetworkingModalSchema = Yup.object().shape({
    // file: commonStringField("Image"),
    name: fieldNotBlank(),
    email: emailField("E-mail"),
    subTitle: commonStringField('Label'),
    department: Yup.mixed(),
    overview: commonStringField("Overview").test("trimHtml", "Overview can not be blank spaces", function (value) {
        const toNodes = (html: string) => new DOMParser().parseFromString(html, 'text/html').body.childNodes[0]
        const el = toNodes(value || '')
        return el?.textContent?.trim() ? true : false
    }),
    geometricLocation: commonStringField('Location'),
});

export const PresentersModalSchema = Yup.object().shape({
    // file: commonStringField("Image"),
    name: fieldNotBlank(),
    designation: commonStringField('Designation'),
    companyName: Yup.string(),
    overview: commonStringField('Overview'),
    startDate: Yup.date().default(() => new Date()).required(requiredMessage('Start date')),
    endDate: Yup.date().min(
        Yup.ref('startDate'),
        "End date can't be before Start date"
    ).required(requiredMessage('End date')),
    startTime: commonStringField('Start time'),
    endTime: commonStringField('End time').test("set-time", "End time should be more than start time.", function (value) {
        const sDate: Moment = moment(`${dateConvert(this.parent.startDate)} ${this.parent.startTime}`, "YYYY-MM-DD h:mm a")
        const eDate: Moment = moment(`${dateConvert(this.parent.endDate)} ${value}`, "YYYY-MM-DD h:mm a")
        return eDate.isAfter(sDate) || false;
    }),
    ...externalLinksSchema,
});

export const RecordingModalSchema = Yup.object().shape({
    name: commonStringField("Name"),
    videoType: commonStringField('Video Type'),
    link: Yup.mixed().when('videoType', {
        is: (value:any) => value === "1",
        then: Yup.string().url(invalidMessage("link")),
        otherwise: Yup.mixed().required()
    }),        
    file: Yup.mixed().when('videoType', {
        is: (value:any) => value === '2',
        then: Yup.mixed().required(),
        otherwise: Yup.mixed().required()
    }),
});

export const ScheduleModalSchema = Yup.object().shape({
    name: fieldNotBlank(),
    recordingUrl: commonStringField("Recording Link").url(invalidMessage("Recording Link")),
    tracks: Yup.mixed().required(),
    date: Yup.string(),
    startTime: commonStringField('Start Time'),
    endTime: commonStringField('End time').test("set-time", "End time should be more than start time.", function (value) {
        const sDate: Moment = moment(`${dateConvert(this.parent.date)} ${this.parent.startTime}`, "YYYY-MM-DD h:mm a")
        const eDate: Moment = moment(`${dateConvert(this.parent.date)} ${value}`, "YYYY-MM-DD h:mm a")
        return eDate.isAfter(sDate) || false;
    }),
    desc: commonStringField("Description"),
    searchLink: Yup.mixed().required(),
    status: Yup.string(),
    maxAttendance: maxAttendee(0, 1000),
    denialMsg: Yup.string(),
    customizeDenialMsgSelect: Yup.mixed().required(),
    ...externalLinksSchema,
    attachments: Yup.mixed().required(),
    provider: commonStringField('Provider'),
    link: commonStringField("Link").url(invalidMessage("Link"))
});

export const AttendeeModalSchema = Yup.object().shape({
    // file: commonStringField("Image"),
    email: emailField("E-mail"),
    name: fieldNotBlank(),
    subTitle: commonStringField('Label'),
    designation: commonStringField('Designation'),
});

export const TrackModalSchema = Yup.object().shape({
    trackName: Yup.mixed().required(),
    shortName: Yup.string().max(5, "Short Name must be less than or equal to 5.").required(requiredMessage("Short Name")),
    color: commonStringField("Color"),
    visibleTo: Yup.string(),
});

export const SponsorsModalSchema = Yup.object().shape({
    file: Yup.mixed().required(),
    name: fieldNotBlank(),
    subTitle: commonStringField('Label'),
    geometricLocation: commonStringField('Location'),
    category: Yup.mixed().required(),
    desc: commonStringField("Description"),
    reqBtnLabel: Yup.string(),
    reqContactEmail: Yup.string(),
    scheduleMeetingBtnLabel: Yup.string(),
    scheduleMeetingURL: Yup.string(),
    searchLink: Yup.mixed().required(),
    attendeeSelect: Yup.mixed().required(),
    ...externalLinksSchema,
    attachments: Yup.mixed().required()
});

export const CategoryModalSchema = Yup.object().shape({
    category: Yup.mixed().required()
});

export const SlidesModalSchema = Yup.object().shape({
    name: commonStringField("Name"),
    documentType: commonStringField("Document Type"),
    file: Yup.mixed().required(),
});

export const StreamOptionsModalSchema = Yup.object().shape({
    name: fieldNotBlank(),
    subTitle: commonStringField('Label'),
    desc: commonStringField("Description"),
    ...externalLinksSchema,
});

export const AddTypesSchema = Yup.object().shape({
    alias: commonStringField("alias"),   
})

export const LivePollsModalSchema = Yup.object().shape({
    question: fieldNotBlank("Question"),
    answerOptions: Yup.array(Yup.string()).required(requiredMessage("Answer Options")),
    isPollClosed: Yup.boolean()
});

export const SurveyModalSchema = Yup.object().shape({
   surveys: Yup.array().of(Yup.object().shape({
    question: fieldNotBlank("Question"),
       answerType: Yup.number(),
    // need for future
    // file: Yup.string().when("answerType", {
    //     is: (value) => value === 1,
    //     then: commonStringField("File"),
    //     otherwise: Yup.string()
    // }),
    answerOptions: Yup.array(Yup.string()).when("answerType", {
        is: (value:any) => value === 3,
        then: Yup.array(Yup.string()).required(requiredMessage("Answer Option")),
        otherwise: Yup.array(Yup.string())
    }),
   })).required("At least add one question")
});

export const ManagerModalSchema = Yup.object().shape({
    name: nameFieldLetters(),
    email: emailField("E-mail"),
    skills: skillsField,
    company: commonStringField('Company'),
    designation: commonStringField('Designation'),
    overview: commonStringField('Overview'),
})

export const UserModalSchema = Yup.object().shape({
    name: nameFieldLetters(),
    email: emailField("E-mail"),
    skills: skillsField,
    company: commonStringField('Company'),
    designation: commonStringField('Designation'),
    overview: commonStringField('Overview'),
})

export const AMASessionSchema = Yup.object().shape({
    sponsor: commonStringField("Sponsor"),   
})

export const AcceptRejectModelSchema = Yup.object().shape({
    remark: htmlField("Message")
})

export const BannerSchema = Yup.object().shape({
    file: commonStringField("Image"),
    name: fieldNotBlank(),
    linkSelect: Yup.string(),
    searchLink: Yup.mixed().when("linkSelect", {
        is: (value:any) => value === Collapse_LinkType.INTERNAL,
        then: commonStringField("Internal Link"),
        otherwise: Yup.string()
    }).nullable(),
    externalLink: Yup.string().when("linkSelect", {
        is: (value:any) => value === Collapse_LinkType.EXTERNAL,
        then: commonStringField("External Link").url(invalidMessage("Link")),
        otherwise: Yup.string()
    }),
    duration: commonStringField("Duration"),
})

export const AppUrlSchema = Yup.object().shape({
    androidUrl: commonStringField("Android App URL").url(invalidMessage("Android App URL")),
    iosUrl: commonStringField("IOS App URL").url(invalidMessage("IOS App URL"))
})

export const TermsConditionSchema = Yup.object().shape({
    cms: htmlField('Terms Condition')
})

export const EmailTemplateSchema = Yup.object().shape({
    name: Yup.number()
        .required(requiredMessage("Email Name")),
    smtp: commonStringField("SMTP"),
    subject: commonStringField("Email Subject"),
    body: commonStringField("Email Body")
})