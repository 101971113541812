import gql from "graphql-tag";
import { ApplicationSchema } from "graphQl/CommonSchemas";
import { senderSchema } from "graphQl/Features/Subscription";

const GET_HOME_DATA = gql`
  query getDashboardAll($userId: String) {
    getDashboardAll(userId: $userId){ 
      totalEvents
      activeEvents
      systemManager
      totalUsers
      eventViewers
    }
  }
`;

const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($offset: Int, $limit: Int, $searchTerm: String){
    getAllNotifications(offset: $offset, limit: $limit, searchTerm: $searchTerm){
      nodes{
        _id
        createdAt
        updatedAt
        message
        isRead
        moduleType
        moduleId
        eventId{
          _id
        }
        ${senderSchema}
      }
      totalCount
    }
  }
`

const GET_SINGLE_NOTIFICATION = gql`
  query getSingleNotification($id: String!){
    getSingleNotification(id: $id){
      _id
      isRead
    }
  }
`;
const DELETE_NOTIFICATIONS = gql`
  mutation deleteNotifications($input: DeleteNotificationInput!){
    deleteNotifications(input: $input)
  }
`;
const MARK_AS_READ_NOTIFICATIONS = gql`
  mutation readNotifications($input: ReadNotificationInput!){
    readNotifications(input: $input)
  } 
`;

const CREATE_APPLICATION = gql`
  mutation createApplication($input: ApplicationInput!){
    createApplication(input: $input){
     ${ApplicationSchema}
    }
  }
`

const UPDATE_APPLICATION = gql`
  mutation updateApplication($input: ApplicationInput!){
    updateApplication(input: $input){
     ${ApplicationSchema}
    }
  }
`

const GET_APPLICATION = gql`
  query getSingleApplication{
    getSingleApplication{
      ${ApplicationSchema}
    }
  }
`

const CREATE_CMS = gql`
  mutation createCms($input: CmsInput!){
    createCms(input: $input){
      updatedAt
      _id
      content
    }
  }
`

const UPDATE_CMS = gql`
  mutation updateCms($input: CmsInput!){
    updateCms(input: $input){
      updatedAt
      _id
      content
    }
  }
`

const GET_CMS = gql`
  query getSingleCms{
    getSingleCms{
      updatedAt
      _id
      content
    }
  }
`

export { GET_HOME_DATA, GET_ALL_NOTIFICATIONS, MARK_AS_READ_NOTIFICATIONS, DELETE_NOTIFICATIONS, GET_SINGLE_NOTIFICATION, CREATE_APPLICATION, UPDATE_APPLICATION, GET_APPLICATION, CREATE_CMS, UPDATE_CMS, GET_CMS };
