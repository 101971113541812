import React, { useState } from "react";
import { withFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { APP_GUIDE_MUTATION } from "graphQl/Features/MutationSchema";
import { AppGuideForm, FeaturesModalForm } from "../FeaturesModal";
import {
  FEATURES_API,
  MEDIA_NAME,
  FEATURES_NAME,
  NOTIFICATION_STATUS,
} from "constants/Enums";
import { CallMutation } from "graphQl/graphqlHandler";
import { UPDATE_APP_GUIDE } from "graphQl/Features/UpdateMutation";
import { addDataInCache, removeDataInCache } from "utils/helper";
import AppGuide, { AppGuideProps } from "./AppGuide";
import { addMedia } from "constants/GlobalEventTypeScript";
import NotificationWithIcon from "constants/Toster";
import { NEW_ITEM_CREATED, NEW_ITEM_UPDATED } from "utils/NotificationMessages";
import { AppGuideSchema } from "utils/formikValidation";
import { GET_ALL_APP_GUIDE } from "graphQl/Features/QuerySchema";

const AppGuideFormikApp = withFormik<AppGuideProps, FeaturesModalForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ initData }) => {
    const data =
      JSON.stringify(initData) !== "{}"
        ? initData?.attachments
          ? { ...initData, file: initData?.attachments }
          : { name: "", documentType: "", file: [] }
        : { name: "", documentType: "", file: [] };
    return {
      appGuides: [data],
      removeMedia: [],
    };
  },
  validationSchema: AppGuideSchema,

  handleSubmit: (values, { props, resetForm }) => {
    const { ids, initData, appGuide } = props;
    const { eventId } = ids;
    const { removeMedia, appGuides } = values;
    const id = initData?._id ? { _id: initData?._id } : { eventId: eventId };
    let finalData =
      (appGuides?.length &&
        appGuides.map((item) => {
          let temp = [];
          item?.file?.length &&
            temp.push(
              addMedia(item?.file[0], MEDIA_NAME.APP_GUIDE_ATTACHMENTS)
            );
          const { file, attachments, _id, ...noFile } = item;
          return temp?.length
            ? { ...noFile, addMedia: temp, removeMedia: removeMedia }
            : { ...noFile, removeMedia: removeMedia };
        })) ||
      [];
    CallMutation(
      appGuide,
      {
        variables: {
          input: {
            ...id,
            documents: finalData,
          },
        },
      },
      resetForm
    );
  },
})(AppGuide);

interface AppGuideData {
  getAllFeatureAppGuides: AppGuideForm[];
}
const AppGuideFormikWrapper = (props: AppGuideProps) => {
  const [initData, setInitData] = useState<AppGuideForm>();
  const [id, setId] = useState("");
  const { ids, showModal } = props;
  const { eventId } = ids;
  const {
    getAllFeatureAppGuides,
    createFeatureAppGuide,
    updateFeatureAppGuide,
  } = FEATURES_API;
  const schema = initData?._id ? UPDATE_APP_GUIDE : APP_GUIDE_MUTATION;
  const [appGuide, { data, loading }] = useMutation(schema, {
    update(cache, { data: APIResponse }) {
      const cacheQuery = {
        query: GET_ALL_APP_GUIDE,
        variables: { eventId: eventId },
      };
      const existingData: AppGuideData | null = cache.readQuery({
        ...cacheQuery,
      });
      const currentData = existingData?.getAllFeatureAppGuides;
      const filteredData = initData?._id
        ? removeDataInCache(currentData, APIResponse[updateFeatureAppGuide]._id)
        : addDataInCache(APIResponse[createFeatureAppGuide], currentData);
      const updatedData = initData?._id
        ? addDataInCache(filteredData, APIResponse[updateFeatureAppGuide])
        : filteredData;
      cache.writeQuery({
        ...cacheQuery,
        data: {
          [getAllFeatureAppGuides]: {
            updatedData,
          },
        },
      });
      showModal && showModal();
      NotificationWithIcon(
        NOTIFICATION_STATUS.SUCCESS,
        initData?._id
          ? NEW_ITEM_UPDATED(FEATURES_NAME.APP_GUIDE)
          : NEW_ITEM_CREATED(FEATURES_NAME.APP_GUIDE)
      );
      setInitData({ name: "", documentType: "", file: [] });
    },
  });
  return (
    <AppGuideFormikApp
      appGuide={appGuide}
      data={data}
      loading={loading}
      initData={initData}
      id={id}
      setId={setId}
      setInitData={setInitData}
      {...props}
    />
  );
};
export default AppGuideFormikWrapper;
