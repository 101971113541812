import React from 'react'
import { GrayDesc } from 'components/common/WSTitle'
import { ImageUpload } from 'pages/features/HandleImage'
import { CommonFormikProps } from 'constants/GlobalEventTypeScript'
import { acceptAttachmentsFileFormats, BtnVariant } from 'constants/Enums'
import './Attachments.scss'
import { WSButton } from 'components/FormComponent'
import { DeleteFilled } from '@ant-design/icons'
interface AttachmentsProps extends CommonFormikProps {
  values?: any
}

const Attachments = ({ setFieldValue, values }: AttachmentsProps) => {
  return (
    <>
      <div className='attachment-upload'>
        <GrayDesc name='File types: pdf, jpeg, jpg, png, tiff, docx, doc, xlsx' />
        <ImageUpload
          values={values}
          showName={
            values?.attachments?.length
              ? values.attachments[0]?.name || values.attachments[0]?.key
              : ''
          }
          showImage={false}
          accept={acceptAttachmentsFileFormats}
          name='attachments'
          label='Upload Attachments'
          setFieldValue={setFieldValue}
          mainClassName='attachments-upload'
        />
      </div>
    </>
  )
}

export default Attachments
