import React from 'react';
import { ErrorMessage } from 'formik';
import { Typography } from 'antd';

interface Props {
    name: string;
    className?: string;
}

const { Text } = Typography;

const MyErrorMessage = (props: Props) => {
    const { name, className } = props;
    return (
        <ErrorMessage name={name} className="error-msg" component="span" />
    );
};
export default MyErrorMessage;