import React from 'react'
import { camelCaseToNormalCase } from 'utils/helper';
import WSToolTip from '../WSTooltip/WSTooltip';
import './labels.scss'
interface LabelProps {
  labelClass?: string;
  required?: boolean
  infoMessage?: string
  label?: string
}

const Labels = ({labelClass, required, infoMessage, label}: LabelProps) => {
  return (
    <div className={`labels ${labelClass}`}>
      <label>{camelCaseToNormalCase(label || "")}</label>
      {required && "*"}
      {infoMessage && (
        <WSToolTip title={infoMessage} placement="top">
          <span className="info-btn font-bold border-red-50">i</span>
        </WSToolTip>
      )}
    </div>
  );
};

export default Labels;