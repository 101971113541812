import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { clientServerLess } from 'App';
import NotificationWithIcon from 'constants/Toster';

const CREATE_SMTP_MUTATION = gql`
  mutation createSmtp($input: SmtpInput!) {
    createSmtp(input: $input) {
      id      
      email
      host
      ipAddress
      pass
      port
      provider
      type
      userName
    }
  }
`;

const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: TemplateInput!) {
    createTemplate(input: $input) {
      id      
      body
      name
      smtp {
        id
      }
      subject
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($input: TemplateInput!) {
    updateTemplate(input: $input) {
      id      
      body
      name
      smtp {
        id
      }
      subject
      type
    }
  }
`;

const UPDATE_SMTP = gql`
  mutation updateSmtp($input: SmtpInput!) {
    updateSmtp(input: $input) {
      id      
      email
      host
      ipAddress
      pass
      port
      provider
      type
      userName
    }
  }
`;

const GET_SMTP = gql`
  query getAllSmtp {
    getAllSmtp {
      id      
      email
      host
      ipAddress
      pass
      port
      provider
      type
      userName
    }
  }
`;

const GET_TEMPLATE = gql`
  query getAllTemplates {
    getAllTemplates {
      body
      id
      name
      updatedAt
      smtp {
        id
        email
      }
      subject
      type
    }
  }
`;

const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: String!) {
    deleteTemplate(id: $id) {
      id
      name
      smtp {
        id
        email
      }
      subject
    }
  }
`;

const DELETE_SMTP = gql`
  mutation deleteSmtp($id: String!) {
    deleteSmtp(id: $id) {
      id      
      host
      type
      userName
    }
  }
`;

const GET_TEMPLATE_URL = gql`
  query getTemplateimageUrl($key: String!){
    getTemplateimageUrl(key: $key)
  }
`

export {
  CREATE_SMTP_MUTATION,
  GET_SMTP,
  UPDATE_SMTP,
  DELETE_SMTP,
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE_URL
}