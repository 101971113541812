import React, { useState } from "react";
import { FieldArray } from "formik";
import { MutationFunction } from "@apollo/react-hooks";
// components
import { AppGuideForm, FeaturesModalProps } from "../FeaturesModal";
import { Loader, WSButton } from "components/FormComponent";
import { FeatureModal } from "pages/event/eventForms/CommonSubModals/FeaturesReuseableParts";
import {
  Name,
  WSDocument,
} from "pages/event/eventForms/CommonSubModals/ModalFields";
import { GrayDesc } from "components/common/WSTitle";
import { WSSelect } from "components/common";
// constants
import { FEATURES_API } from "constants/Enums";
import { documentOptionList } from "constants/DumyData";
import { ImageUpload } from "../HandleImage";
import { BtnVariant, compareFileType } from "constants/Enums";
// schema
import { GET_ALL_APP_GUIDE } from "graphQl/Features/QuerySchema";
import { GET_SINGLE_APP_GUIDE } from "graphQl/Features/SingleQuerySchema";

export interface AppGuideProps extends FeaturesModalProps {
  modalState?: number;
  showModal?: () => void;
  index?: number;
  appGuide?: MutationFunction;
  data?: Object;
  ids: { eventId: string; featureId: string };
  isEdit?: boolean;
  initData?: AppGuideForm;
  setInitData?: Function;
  id?: string;
  setId?: (e: string) => void;
}
const AppGuide = ({
  modalState,
  showModal,
  index,
  values,
  loading,
  isEdit,
  ids,
  initData,
  setInitData,
  setFieldValue,
}: AppGuideProps) => {
  // state
  const [editLoading, setEditLoading] = useState(false);
  // destructuring
  const { eventId } = ids;
  const { getAllFeatureAppGuides, getFeatureAppGuide } = FEATURES_API;

  return (
    <FeatureModal
      infoMessage="A brief paragraph to describe this item."
      showModal={modalState === index}
      onCancel={showModal}
      title="App Guide"
      loading={loading}
      modalClass="app-guide-modal modal-section"
    >
      <WSDocument
        schema={GET_ALL_APP_GUIDE}
        singleQuerySchema={GET_SINGLE_APP_GUIDE}
        queryName={getAllFeatureAppGuides}
        singleQueryName={getFeatureAppGuide}
        eventId={eventId}
        initData={initData}
        setInitData={setInitData}
        setLoading={setEditLoading}
      />
      {editLoading ? (
        <Loader />
      ) : (
        <>
          <FieldArray
            name="appGuides"
            render={(data) => (
              <>
                {values?.appGuides?.map((appGuide, index: number) => (
                  <>
                    <Name
                      name={`appGuides[${index}].name`}
                      label="Name"
                      divClass="mt-10-px"
                    />
                    <WSSelect
                      name={`appGuides[${index}].documentType`}
                      label="Document Type"
                      optionList={documentOptionList}
                      className="width-100 document-select"
                      listOptions="label"
                      passed="value"
                      disabled={isEdit}
                    />
                    {appGuide?.documentType && (
                      <div className="upload-file common-img-upload common-model-image-file-upload">
                        <GrayDesc name="Upload your file" />
                        <ImageUpload
                          name={`appGuides[${index}].file`}
                          showName={
                            appGuide?.file?.length
                              ? appGuide.file[0]?.name || appGuide.file[0]?.key
                              : ""
                          }
                          showImage={false}
                          accept={compareFileType[appGuide?.documentType]}
                          values={appGuide}
                          setFieldValue={setFieldValue}
                          label="Upload Attachments"
                          btnClass="common-model-upload-attachment-image-file"
                          mainClassName="model-file-upload"
                          imageUploadClassName="model-upload-attachment-btn"
                        />
                      </div>
                    )}
                    {index > 0 && (
                      <WSButton
                        variant={BtnVariant.BORDERED}
                        type="button"
                        className="custom-app-guide-model-remove-btn"
                        onClick={() => data.remove(index)}
                        label="Remove"
                      />
                    )}
                  </>
                ))}
                {!initData?._id && (
                  <WSButton
                    variant={BtnVariant.BORDERED}
                    type="button"
                    className="custom-app-guide-add-more-btn"
                    onClick={() =>
                      data.push({ name: "", documentType: "", file: [] })
                    }
                    label="Add More"
                  />
                )}
              </>
            )}
          />
        </>
      )}
    </FeatureModal>
  );
};

export default AppGuide;
