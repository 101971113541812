import React, { useState } from "react";
import WSPopover from "components/common/WSPopover";
import images from "constants/Images";
import {
  MainTitle,
  GrayDesc,
} from "components/common/WSTitle";
import { WSButton, WSImage } from "components/FormComponent";
import { BtnVariant, COMMON_NAME } from "constants/Enums";
import "./SortFilterBy.scss";
import { camelCaseToNormalCase } from "utils/helper";
interface SortFilterByProps {
  label?: string;
  className?: string;
  currentPage?: number;
  sortingFunction?: (e: object) => void;
  setSorting?: (e: boolean) => void;
  sortByArray?: { name: string }[];
  eventId?: string;
  filterBy?: boolean;
}

const SortFilterBy = ({
  label,
  className,
  currentPage,
  sortingFunction,
  setSorting,
  sortByArray,
  eventId,
  filterBy,
}: SortFilterByProps) => {
  const [sortBy, setSortBy] = useState(false);
  return (
    <WSPopover
      text={sortFilterByTitle(setSortBy, label)}
      content={
        <SortFilterByList
          setSortBy={setSortBy}
          current={currentPage}
          sortingFunction={sortingFunction}
          setSorting={setSorting}
          sortByArray={sortByArray}
          eventId={eventId}
          filterBy={filterBy}
        />
      }
      placement="bottomRight"
      trigger="click"
      overlayClassName="custom-popover-notification"
      open={sortBy}
      onOpenChange={() => setSortBy(!sortBy)}
    >
      <WSButton
        variant={BtnVariant.BORDERED}
        className={`border-radius-none gray-border cursor-pointer ${className}`}
        label={label}
        rightIcon={images.downArrow}
      />
    </WSPopover>
  );
};
export default SortFilterBy;

const sortFilterByTitle = (setSortBy: (e: boolean) => void, label?: string) => {
  return (
    <div className="d-flex align-item-center justify-content-between popover-title">
      <MainTitle name={label} />
      <div
        className="d-flex align-item-center"
        onClick={() => setSortBy(false)}
      >
        <WSImage
          imagesPath={images.blackClose}
          width={10}
          height={10}
          inlineClass="cursor-pointer"
        />
      </div>
    </div>
  );
};

interface SortByTypes {
  name: string;
}

interface sortFilterByListProps {
  setSortBy: Function;
  current?: number;
  sortingFunction?: (e: object) => void;
  setSorting?: (e: boolean) => void;
  sortByArray?: { name: string }[];
  eventId?: string;
  filterBy?: boolean;
}
const SortFilterByList = ({
  setSortBy,
  current,
  sortingFunction,
  setSorting,
  sortByArray,
  eventId,
  filterBy,
}: sortFilterByListProps) => {
  return (
    <div className="d-flex align-item-center justify-content-center flex-direction-column">
      {sortByArray?.map(({ name }: SortByTypes) => {
        const sortOrFilter = filterBy
          ? { filterBy: [COMMON_NAME[name.toUpperCase()]] }
          : { sortBy: { name: COMMON_NAME[name.toUpperCase()], ascending: true } };
        return (
          <div
            className="sort-by-des"
            key={name}
            onClick={() => {
              setSortBy(false);
              setSorting && setSorting(true);
              sortingFunction &&
                sortingFunction({
                  variables: eventId
                    ? {
                        offset: current,
                        limit: 10,
                        eventId: eventId,
                        sortBy: { name: COMMON_NAME[name.toUpperCase()], ascending: true },
                      }
                    : {
                        offset: current,
                        limit: 10,
                        ...sortOrFilter,
                      },
                });
            }}
          >
            <GrayDesc name={camelCaseToNormalCase(name)} />
          </div>
        );
      })}
    </div>
  );
};
