import React, { ReactNode } from "react";
import { Checkbox } from "antd";
import { Field, FieldProps } from "formik";
import MyErrorMessage from "./WSErrorMessage";

const CheckboxGroup = Checkbox.Group;

interface SelectProps {
  labelArr?: string[] | {label: ReactNode | string, value: string}[];
  className?: string;
  name?: string;
  onClick?: () => void;
  disabled?: boolean
  value?: any;
}

const WSCheckbox = (props: SelectProps) => {
  const {
    labelArr,
    className,
    name = "",
    value,
    disabled = false
  } = props;
  return (
    <>
        <Field>
          {({ form }: FieldProps) => (
          <CheckboxGroup
              disabled={disabled}
              name={name}
              value={value}
              onChange={(e) => form.setFieldValue(name, e)}
              options={labelArr}
              className={className}
            />
          )}
      </Field>
      <MyErrorMessage name={name} />
    </>
  );
};

export default WSCheckbox;
