import React, { ReactNode } from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

interface Props {
  iconPosition?: "left" | "right";
  panelData?: {
    panelHeader: string | ReactNode;
    insideForm: ReactNode;
    key: number | string;
  };
  className?: string;
  ghost?: boolean;
  activeKey?: number | string;
  showArrow?: boolean;
}
const WSCollapse = ({
  iconPosition,
  panelData,
  className,
  ghost,
  activeKey,
  showArrow,
}: Props) => {
  const activeKeyProp = activeKey ? { activeKey } : {};
  return (
    <Collapse
      className={className}
      ghost={ghost}
      expandIconPosition={iconPosition}
      {...activeKeyProp}
    >
      {panelData && (
        <Panel
          header={panelData.panelHeader}
          key={panelData.key}
          showArrow={showArrow}
        >
          {panelData.insideForm}
        </Panel>
      )}
    </Collapse>
  );
};

export default WSCollapse;