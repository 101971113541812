import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

interface Loaderprops {
  divClassName?: string
  loaderColor?: string
}
const Loader = ({ divClassName, loaderColor}: Loaderprops) => {
  return (
    <div className={`${divClassName}`}>
      <LoadingOutlined className={`${loaderColor} primary-color font-xx-large`} />
    </div>
  );
};

export default Loader;
