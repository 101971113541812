import React from "react";
import { withFormik } from "formik";
import { useMutation, MutationFunction } from "@apollo/react-hooks";
// components
import {
  FeaturesModalForm,
  FeaturesModalProps
} from "./FeaturesModal";
import { ImageUpload }  from "./HandleImage";
import { FeatureModal } from "../event/eventForms/CommonSubModals/FeaturesReuseableParts";
import { CallMutation } from "graphQl/graphqlHandler";
import { LabelSubtitle, Name } from "../event/eventForms/CommonSubModals/ModalFields";
import { FEATURES_NAME, MEDIA_NAME, NOTIFICATION_STATUS } from "constants/Enums";
import { addMedia } from "constants/GlobalEventTypeScript";
import NotificationWithIcon from "constants/Toster";
import { MODAL_ADDED } from "utils/NotificationMessages";
// schema
import { Schedule1v1Schema } from "utils/formikValidation";
import { SCHEDULE1V1_MUTATION } from "graphQl/Features/MutationSchema";

interface Schedule1V1Props extends FeaturesModalProps {
  modalState?: number;
  showModal?: () => void;
  index?: number;
  schedule1v1?: MutationFunction;
  data?: object;
  ids: { eventId: string; featureId: string };
}
const Schedule1V1 = (props: Schedule1V1Props) => {
  const {
    modalState,
    showModal,
    index,
    values,
    setFieldValue,
    loading,
  } = props;
  return (
    <FeatureModal
      infoMessage="A brief paragraph to describe this item."
      showModal={modalState === index}
      onCancel={showModal}
      title="Schedule 1V1"
      loading={loading}
    >
      <ImageUpload
        values={values}
        setFieldValue={setFieldValue}
      />
      <Name />
      <LabelSubtitle infoMessage="" />
    </FeatureModal>
  );
};

const Schedule1V1FormikApp = withFormik<Schedule1V1Props, FeaturesModalForm>({
  mapPropsToValues: () => {
    return {
      name: "",
      subTitle: "",
      file: [],
      removeMedia: [],
    };
  },
  validationSchema: Schedule1v1Schema,

  handleSubmit: ({name, subTitle, removeMedia, file}, { props, resetForm }) => {
    const { ids , schedule1v1} = props
    const { eventId } = ids;
    CallMutation(schedule1v1, {
      variables: {
        input: {
          eventId: eventId,
          name: name?.trim(),
          subTitle: subTitle,
          addMedia: addMedia(
            (file && file[0]) || { key: "", type: "" },
            MEDIA_NAME.FEATURE_SCHEDULE1V1
          ),
          removeMedia: removeMedia,
        },
      },
    });
    resetForm()
  },
})(Schedule1V1);

const Schedule1V1FormikWrapper = (props: Schedule1V1Props) => {
  const [schedule1v1, { data, loading }] = useMutation(SCHEDULE1V1_MUTATION, {
    update(cache, { data: APIResponse }) {
      props.showModal && props.showModal();
      NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS, MODAL_ADDED(FEATURES_NAME.SCHEDULE1V1));
    },
  });

  return (
    <Schedule1V1FormikApp
      schedule1v1={schedule1v1}
      data={data}
      loading={loading}
      {...props}
    />
  );
};
export default Schedule1V1FormikWrapper;
