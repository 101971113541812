import React from 'react'

interface ErrorProps {
    errorMsg?: string | boolean | string[] | object[]
    touched?: any
    errorClass?: string
}
const WSErrorMessage = ({ errorClass, errorMsg, touched }: ErrorProps) => {
    return (
        <>
            {errorMsg && touched &&
                <div className={`error-msg ${errorClass}`}>
                    {/* {errorMsg} */}
                </div>
            }
        </>
    )
}

export default WSErrorMessage;