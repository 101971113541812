import React from "react";
import { Form } from "formik";
// constants
import { BtnVariant } from "constants/Enums";
// components
import { WSModal } from "components/common";
import { WSButton, WSTextEditor } from "components/FormComponent";
import { RejectMessageModalProps } from "pages/event/eventForms/CommonSubModals/SubModals";
// style
import "./AcceptReject.scss";

export interface RejectMessageProps extends RejectMessageModalProps {
  id?: string;
  showModal?: boolean;
  onCancel?: () => void;
  loading?: boolean;
  rejectModel?:boolean;
  remark?:string
  changeStatus?:Function
  setValue?: Function;
  onClick?:() => void;
  setRejectModel?:Function 
  eventStatusChange?:Function
}

const RejectMessageModel = ({
  showModal,
  onCancel,
  loading,
}: RejectMessageProps) => {
  const { GRADIENT, LINK } = BtnVariant;
  return (
    <Form>
      <WSModal
        className="reject-message-model"
        title="Alert"
        open={showModal}
        onCancel={onCancel}
        confirmLoading={loading}
        footer={[
          <Form key="buttons">
            <WSButton
              variant={LINK}
              type="button"
              className="reject-message-close-btn"
              inlineClass="textbox-label"
              name="Close"
              label="Close"
              onClick={onCancel}
            />
            <WSButton
              name="send"
              label="Send"
              variant={GRADIENT}
              className="box-shadow-none"
            />
          </Form>
        ]}
      >
        <div className="reject-message-model">
          <WSTextEditor name="remark" label="Message" infoMessage="" />
        </div>
      </WSModal>
    </Form>
  );
};

export default RejectMessageModel;
