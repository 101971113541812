import React from "react";

interface TitleProps {
  name?: string;
  inlineClassName?: string;
  onClick?: () => void
}

export const MainTitle = ({ name, inlineClassName, onClick }: TitleProps) => {
  return <h4 className={`section-title ${inlineClassName}`} onClick={onClick}>{name}</h4>;
};

export const SubTitle = ({ name, inlineClassName }: TitleProps) => {
  return <span className={`primary-font ${inlineClassName}`}>{name}</span>;
};

export const H1Title = ({ name, inlineClassName }: TitleProps) => {
  return <h1 className={`bold-primary ${inlineClassName}`}>{name}</h1>;
};

export const GrayDesc = ({ name, inlineClassName, onClick }: TitleProps) => {
  return <p className={`small-gray-text ${inlineClassName}`} onClick={onClick}>{name}</p>;
};

export const SmallBrownText = ({ name, inlineClassName, onClick }: TitleProps) => {
  return <p className={`brown-text mb-none ${inlineClassName}`} onClick={onClick}>{name}</p>;
};

export const TextBoxLabel = ({ name, inlineClassName }: TitleProps) => {
  return <label className={`textbox-label ${inlineClassName}`}>{name}</label>;
};

export const MobileTitle = ({ name, inlineClassName }: TitleProps) => {
  return <label className={`mobile-title ${inlineClassName}`}>{name}</label>;
};

