import React, { useState } from 'react'
import images from 'constants/Images';

interface ImageProps {
    imagesPath?: string
    width?: number | string
    height?: number | string
    inlineClass?: string;
    onClick?: () => void;
}

const WSImage = ({
    imagesPath,
    width,
    height,
    inlineClass,
    onClick
}: ImageProps) => {
    const [defaultImage, setDefaultImage] = useState(false)
    return <img src={defaultImage ? images.defaultUser : imagesPath || images.defaultCover} className={inlineClass} alt="evecite-pic" width={width} height={height} onClick={onClick} onError={() => setDefaultImage(true)} />
}
export default WSImage;