import React, { useState } from "react";
import Geosuggest from "react-geosuggest";
import { FieldProps, Field } from "formik";

import "./geosuggetion.scss";
import { GoogleMaps } from "../common";
import MyErrorMessage from "../FormComponent/WSErrorMessage";

interface GeoSuggestionProps {
  name: string;
  errorMsg?: any;
  touched?: any;
  initialValue?: any;
  setValue: (e: any) => void;
  withMap: boolean;
}

const WSGeosuggetion = ({
  name,
  setValue,
  initialValue,
  withMap,
}: GeoSuggestionProps) => {
  const latLng =
    withMap && initialValue?.geometricLocation?.coordinates?.length
      ? {
          lat: initialValue.geometricLocation.coordinates[0],
          lng: initialValue.geometricLocation.coordinates[1],
        }
      : { lat: 37, lng: 122 };
  const [locationLatLng, setLocation] = useState(latLng);

  return (
    <>
      <div className="geo-suggest-input">
        <Field name={name}>
          {({ field }: FieldProps) => (
            <Geosuggest
            {...field}
            initialValue={
              initialValue?.address2?.description ||
              initialValue?.address2 ||
              initialValue ||
              ""
            }
            onSuggestSelect={(e) => {
              setValue(e);
              setLocation(e?.location);
            }}
          />
          )}
        </Field>
        <MyErrorMessage name={name} />
      </div>
      {withMap && (
        <div>
          <GoogleMaps
            center={locationLatLng}
            zoom={9}
            className="maps width-100"
          />
        </div>
      )}
    </>
  );
};

export default WSGeosuggetion;
