import React, { ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import { DeleteFilled } from '@ant-design/icons'
// constants
import images from 'constants/Images'
import { handleSingleFileUpload } from 'utils/S3FileUpload'
import { SmallBrownText, SubTitle } from 'components/common/WSTitle'
import { BtnVariant, NameType } from 'constants/Enums'
import NotificationWithIcon from 'constants/Toster'

// components
import UploadImage from 'components/FormComponent/UploadImage'
import { Loader, WSButton, WSImage } from 'components/FormComponent'
import MyErrorMessage from 'components/FormComponent/WSErrorMessage'
import { ImageProps } from 'constants/GlobalEventTypeScript'

// css
import './HandleImage.scss'
export const handleFileUpload = async (
  file: File,
  name: string,
  setFieldValue: any,
  setLoading: (e: boolean) => void,
  values?: any,
  fixedWidth?: number,
  fixedHeight?: number
) => {
  setLoading(true)
  values?.file?.length &&
    setFieldValue(
      'removeMedia',
      values.file[0]?._id ? [values.file[0]._id] : []
    )
  if (values?.attachments?.length) {
    const fileIds: string[] = values?.attachments?.map(({ _id }: ImageProps) => _id)
    setFieldValue(
      'removeMedia',
      fileIds.filter((id: string) => !!id) || []
    )
  }
  await handleSingleFileUpload(file, name, setFieldValue, values)
  setLoading(false)
}
interface ImageUploadProps {
  values:
    | {
        file?: Array<ImageProps>
        appLogo: Array<ImageProps>
        cover: Array<ImageProps>
        loginBanner: Array<ImageProps>
      }
    | any
  setFieldValue: any
  name?: string
  label?: string
  imageHeight?: string | number
  imageWidth?: string | number
  imgClass?: string
  btnClass?: string
  defaultImage?: string
  children?: ReactNode
  onChange?: () => void
  showImage?: boolean
  uploadBtn?: ReactNode
  accept?: string
  FileTypes?: string
  showName?: string
  imageUploadClassName?: string
  mainClassName?: string
  removeImageBtn?: boolean
  imageReviewRemoveBtn?: string
  fixedWidth?: number
  fixedHeight?: number
  initLoading?: boolean
}
export const ImageUpload = ({
  values,
  setFieldValue,
  name = 'file',
  label = 'Upload Image',
  imageHeight = '120px',
  imageWidth = '120px',
  imgClass,
  btnClass,
  children,
  onChange,
  FileTypes,
  defaultImage = images.emptyImage,
  uploadBtn,
  showImage = true,
  accept,
  showName = '',
  imageUploadClassName,
  mainClassName,
  removeImageBtn,
  imageReviewRemoveBtn,
  fixedHeight = 100,
  fixedWidth = 100,
  initLoading,
}: ImageUploadProps) => {
  const [imgValue, setImgValue] = useState<string | undefined | null>(
    defaultImage
  )
  const [coverValue, setCoverValue] = useState(images.cover1)
  const [bannerValue, setBannerValue] = useState(images.defaultCover)
  const [appLogoVal, setAppLogoValue] = useState(images.defaultCover)
  const [loading, setLoadingState] = useState(false)

  useEffect(() => {
    if (!values?.file?.length)
      setImgValue(
        defaultImage.split('/')[1] === 'static'
          ? defaultImage
          : images.defaultUser
      )
    else {
      setImgValue(
        values?.file && values?.file.map((item: ImageProps) => item?.url)[0]
      )
    }
  }, [values?.file])

  useEffect(() => {
    values?.appLogo &&
      setAppLogoValue(values?.appLogo.map((item: ImageProps) => item?.url)[0])
  }, [values?.appLogo])

  useEffect(() => {
    values?.cover &&
      setCoverValue(values?.cover.map((item: ImageProps) => item?.url)[0])
  }, [values?.cover])

  useEffect(() => {
    values?.loginBanner &&
      setBannerValue(
        values?.loginBanner.map((item: ImageProps) => item?.url)[0]
      )
  }, [values?.loginBanner])

  const setLoading = (value: boolean) => {
    setLoadingState(value)
  }

  const closeButton = (name: string) => {
    setFieldValue(name, [])
  }
  return (
    <div className='handle-change'>
      <div className={`${mainClassName} upload-image-container`}>
        <div className='image-upload-loader'>
          {(loading || initLoading) && <Loader divClassName='loader' />}
          {showImage && (
            <div className='image-uploader-frame'>
              <WSImage
                imagesPath={
                  name === NameType.cover
                    ? coverValue || images.cover1
                    : name === NameType.loginBanner
                    ? bannerValue || defaultImage
                    : name === NameType.appLogo
                    ? appLogoVal || images.defaultLogo
                    : imgValue || defaultImage
                }
                inlineClass={clsx(
                  `${imgClass}`,
                  { 'opacity-4': loading || initLoading },
                  { 'd-none': name === NameType.icon }
                )}
                width={imageWidth}
                height={imageHeight}
              />
            </div>
          )}
          {FileTypes && (
            <SubTitle
              name={`File types: ${FileTypes}`}
              inlineClassName='image-type'
            />
          )}
          {removeImageBtn && values[name]?.length ? (
            <WSButton
              type='button'
              label=''
              leftIcon={images.blackClose}
              name={name}
              className={`${imageReviewRemoveBtn} image-remove-btn`}
              width={8}
              onClick={() => closeButton(name)}
              leftImageClass='close-icon'
              variant={BtnVariant.LINK}
            />
          ) : null}
        </div>
        <div
          className={`d-flex justify-content-between ${imageUploadClassName}`}
        >
          <UploadImage
            fixedHeight={fixedHeight}
            fixedWidth={fixedWidth}
            accept={accept}
            onChange={onChange}
            name={name}
            uploadBtn={uploadBtn}
            className={clsx('upload', {
              'select-icon': name === NameType.icon,
            })}
            btnClass={`upload-image-btn ${btnClass}`}
            label={label}
            action={(file: File) => {
              if (
                file.type.split('/')[0] === 'application' ||
                file.type.split('/')[0] === 'video'
                ) {
                  handleFileUpload(file, name, setFieldValue, setLoading, values)
              } else {
                let img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.onload = () => {
                  if (img.width >= fixedWidth && img.height >= fixedHeight) {
                    handleFileUpload(
                      file,
                      name,
                      setFieldValue,
                      setLoading,
                      values,
                      fixedHeight,
                      fixedWidth
                    )
                  } else {
                    NotificationWithIcon(
                      'error',
                      `Image Resolution must be greater than ${fixedWidth}x${fixedHeight}`
                    )
                  }
                }
              }
            }}
          />
          {children}
        </div>
      </div>
      {showName && (
        <div className='upload-name-dev'>
          <SmallBrownText name={showName} />
          <WSButton
            variant={BtnVariant.LINK}
            icon={<DeleteFilled />}
            className='delete-upload'
            height={20}
            label=''
            width={20}
            onClick={() => {
              setFieldValue && setFieldValue(name, [])
              setFieldValue &&
                setFieldValue(
                  'removeMedia',
                  values?.[name]?.length ?
                    values?.[name]?.map(({_id}: ImageProps) => _id) :
                    values?.removeMedia?.length ? values?.removeMedia :
                    []
                )
            }}
          />
        </div>
      )}
      <MyErrorMessage name={name} />
    </div>
  )
}
