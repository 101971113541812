import React, { useState } from "react";
import { withFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { VIP_CONTENT_MUTATION } from "graphQl/Features/MutationSchema";
import { FeaturesModalForm, VIPContentForm, VIPContentInitData } from "../FeaturesModal";
import { VIPContentSchema } from "utils/formikValidation";
import VIPContent, { VIPContentProps } from "./VIPContent";
import { FEATURES_API, FEATURES_NAME, NOTIFICATION_STATUS } from "constants/Enums";
import { CallMutation } from "graphQl/graphqlHandler";
import { UPDATE_VIP_CONTENT } from "graphQl/Features/UpdateMutation";
import { addDataInCache, removeDataInCache } from "utils/helper";
import { GET_ALL_VIP_CONTENT } from "graphQl/Features/QuerySchema";
import NotificationWithIcon from "constants/Toster";
import { NEW_ITEM_CREATED, NEW_ITEM_UPDATED } from "utils/NotificationMessages";

const getInitData = (data?: VIPContentInitData) => {
  const availableSession = data?.availableSession?.length
    ? data?.availableSession?.map((item: { _id: string }) => item._id)
    : [];
  return data
    ? {
        ...data,
        select: data?.select?._id || "",
        availableSession: availableSession || [],
      }
    : { name: "", overview: "", select: "", availableSession: [] };
};

const VIPContentFormikApp = withFormik<VIPContentProps, FeaturesModalForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ initData }) => {
    const data = getInitData(initData);
    return {
      vipContents: data
        ? [data]
        : [{ name: "", overview: "", select: "", availableSession: [] }],
    };
  },
  validationSchema: VIPContentSchema,

  handleSubmit: async ({ vipContents }, { props, resetForm }) => {
    const { ids, vipContent, initData } = props;
    const { eventId } = ids;
    const id = initData?._id
      ? vipContents?.length ? { ...vipContents[0] } : {}
      : { eventId: eventId, documents: vipContents };
    await CallMutation(
      vipContent,
      {
        variables: {
          input: {
            ...id
          },
        },
      },
      resetForm
    );
  },
})(VIPContent);

interface VIPContentData{
  getAllVipContent: VIPContentForm[]
}
const VIPContentFormikWrapper = (props: VIPContentProps) => {
  const [initData, setInitData] = useState<VIPContentInitData>();
  const { ids, showModal } = props;
  const { eventId } = ids;
  const { getAllVipContent, createVipContent, updateVipContent } = FEATURES_API;

  const schema = initData?._id ? UPDATE_VIP_CONTENT : VIP_CONTENT_MUTATION;
  const [vipContent, { data, loading }] = useMutation(schema, {
    update(cache, { data: APIResponse }) {
      const cacheQuery = {
        query: GET_ALL_VIP_CONTENT,
        variables: { eventId: eventId },
      };
      const existingData: VIPContentData | null = cache.readQuery({
        ...cacheQuery,
      });
      const currentData = existingData?.getAllVipContent;
      const filteredData = initData?._id
        ? removeDataInCache(currentData, APIResponse[updateVipContent]._id)
        : addDataInCache(APIResponse[createVipContent], currentData);
      const updatedData = initData?._id
        ? addDataInCache(filteredData, APIResponse[updateVipContent])
        : filteredData;
      cache.writeQuery({
        ...cacheQuery,
        data: {
          [getAllVipContent]: {
            updatedData,
          },
        },
      });
      showModal && showModal()
      NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS,  initData?._id
      ? NEW_ITEM_UPDATED(FEATURES_NAME.VIP_CONTENT)
      : NEW_ITEM_CREATED(FEATURES_NAME.VIP_CONTENT))
      setInitData(undefined);
    },
  });
  return (
    <VIPContentFormikApp
      vipContent={vipContent}
      data={data}
      loading={loading}
      initData={initData}
      setInitData={setInitData}
      {...props}
    />
  );
};
export default VIPContentFormikWrapper;
