import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  split,
} from "@apollo/react-hooks";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
// component
import { Loader } from "components/FormComponent";
// constants
import NotificationWithIcon from "constants/Toster";
import { localStorageClear, getLocalStorageItem } from "utils/helper";
import { AppRouter, AuthRouter } from "routes/AppRouter";
import { LOCAL_STORAGE_KEY, StaticString } from "constants/Enums";
import { AppConfig } from "config/config";
// css
import "assets/css/app.scss";
import { createClient } from "graphql-ws";

const adminCache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: (object: any) => object._id
});
const adminTemplateCache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: (object: any) => object.id
});

const ErrorLink = onError((error:any) => {
  const { graphQLErrors } = error;

  if (graphQLErrors)
    graphQLErrors.map((errorGraphql: any) => {
      if (
        errorGraphql.extensions.code === StaticString.UNAUTHENTICATED_CAPS ||
        errorGraphql.message === StaticString.JWT ||
        errorGraphql.message === StaticString.UNAUTHENTICATED_SMALL
      ) {
        localStorageClear();
        window.location.reload();
      }
      return NotificationWithIcon("error", errorGraphql?.message);
    });
});

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);
  const tempToken = getLocalStorageItem('temp-auth-token')
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || tempToken}`,
    },
  };
});

const httpLink = createUploadLink({
  uri: AppConfig.REACT_APP_BASE_URL,
});

const httpLinkServerLess = createHttpLink({
  uri: AppConfig.REACT_APP_MICRO_SERVICE_URL,
});

const wsLinkSubscription = new WebSocketLink({
  uri: `${AppConfig.REACT_APP_SUBSCRIPTION_URL}`,
   options: {
    reconnect: true,
}});


// const wsLinkSubscription = new GraphQLWsLink(createClient({
//   webSocketImpl:WebSocket,
//   url: `${AppConfig.REACT_APP_SUBSCRIPTION_URL}`,
// }));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLinkSubscription,
  httpLink,
);

export const clientServerLess = new ApolloClient({
  cache: adminTemplateCache,
  link: ApolloLink.from([ErrorLink, httpLinkServerLess]),
});

export const client = new ApolloClient({
  cache: adminCache,
  link: ApolloLink.from([ErrorLink, authLink.concat(splitLink)]),
});

const App: React.FC = () => {
  const token = getLocalStorageItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);

  return (
    <ApolloProvider client={client}>
      <React.Suspense fallback={<Loader divClassName="loading" />}>
        <Router>{token ? <AppRouter /> : <AuthRouter />}</Router>
      </React.Suspense>
    </ApolloProvider>
  );
};

export default App;
