import { Media_Type_Variant } from "./Enums";
import { PrivacySetting , LocationProps} from 'pages/event/eventForms/eventForms'
export interface HistoryProps {
  push: any;
  history: { location: { state: {} } };
}

export interface ImageProps {
  _id: string;
  url: string;
  key: string;
  type: string;
  mediaType: string;
  mediaName: string;
}

export interface EventContextProps {
  fieldDataStorage: any;
  storeFieldData: (e: any) => void;
  addState: () => void;
  minusState: () => void;
  setFieldData: (e: any) => void;
  currentStep: { state: number; current: number };
}

export interface EventList {
  createdAt: string;
  updatedAt: string;
  _id: string;
  name: string;
  description: string;
  eventType: {
    createdAt: string;
    updatedAt: string;
    _id: string;
    name: string;
    description: string;
  };
  startDateTime: string;
  endDateTime: string;
  eventStatus: boolean;
  slug: string;
  isPublished: boolean;
  remark: string;
  isCompleted: boolean;
  isLive: boolean;
  publishedOn: string;
  coverImage: {
    _id: string;
    type: string;
    mediaType: number;
    mediaName: number;
    key: string;
    order: number;
    user: string;
    moduleType: number;
    isDeleted: boolean;
    url: string;
    smallUrl: string;
    largeUrl: string;
  };
  logo: {
    _id: string;
    type: string;
    mediaType: number;
    mediaName: number;
    key: string;
    order: number;
    user: string;
    moduleType: number;
    isDeleted: boolean;
    url: string;
    smallUrl: string;
    largeUrl: string;
  };
  createdBy?: UserProps
}

export interface LookupProps{
  _id: string
  name: string
  description?: string
}
export interface UserProps {
  name: string;
  companyName: string;
  image: ImageProps;
  designation: LookupProps
  skills: LookupProps[]
  role: LookupProps
  status: boolean
  email: string
  phoneNumber: string
  overview: string
  _id: string
  isBlocked?: boolean
  twoFactorSwitch: boolean
  twoFactorFromEmail: boolean 
  twoFactorSetup: boolean
}

interface EventSettingColor {
  primary: string
  secondary: string
  ternary: string
}

interface EventBanner{
  images: {
    duration: string
    _id: string
    name: string
    image: ImageProps
    externalLink: string
    link: string
    internalLinkUrl: {
      _id: string
      name: string
    }
  }[]
}

interface EventSetting{
  colorCodes: EventSettingColor
  cover: ImageProps
  logo: ImageProps
  loginBanner: ImageProps
  banner: EventBanner
  appLogo: ImageProps
  layout: LookupProps
}

export interface EventFieldProps{
  createdAt: string
  updatedAt: string
  _id: string
  name: string
  description: string
  emailAddressToReachYou: string
  numberOfattendees: number
  eventType: LookupProps
  location: LocationProps
  startDateTime: string
  endDateTime: string
  privacySetting: PrivacySetting
  eventSetting: EventSetting
  eventStatus: boolean
  features: LookupProps[]
  slug: string
  isPublished: boolean
  remark: string
  isPreview: boolean
  isCompleted: boolean
  isLive: boolean
  isSettingUpdated: boolean
  publishedOn: string
  createdBy: UserProps
  updatedBy: UserProps
}

export interface PaginationProps {
  pageSize: number;
  current: number;
  total: number;
}

export interface UserWithThumbnailProps {
  _id?: string;
  name?: string;
  companyName?: string;
  designation?: { name: string; _id: string };
  image?: ImageProps;
  status?: boolean;
  email?: string;
  role?: { _id: string; name: string };
  desc?: string;
  logo?: ImageProps;
  thumbnail?: string;
}

export interface CommentListingProps {
  comment: string;
  file: ImageProps;
  image: ImageProps;
  userId: UserProps;
}

export interface QuestionDataProps {
  _id: string;
  createdAt: string;
  message: string;
  answer: string;
  userId: UserProps;
  image: ImageProps;
  isLiked: boolean;
  likes: number;
  file: ImageProps;
  attachments: ImageProps[];
  comments: CommentListingProps[];
}

export interface TooltipProps {
  message: string;
  path?: string;
}

export interface FeaturesVariables{
  offset: number
  limit: number
  eventId: string
  searchTerm?: string
} 
export interface CommonFormikProps {
  loading?: boolean;
  handleChange?: () => void;
  handleBlur?: () => void;
  handleSubmit?: () => void;
  setFieldValue?: (name: string, event: any) => void;
  initValue?: any;
}

export const locationSchema = `location`;

export interface ExternalLinkProps {
  linkType: string;
  linkName?: string;
  websiteUrl?: string;
  emailAddress?: string;
  socialMediaName?: string;
  phoneNumber?: string;
  passdataToUrl?: boolean
  externalLinkSelect?: string[];
}

export const addMedia = (
  file: {
    key: string;
    type: string;
    mediaName?: string;
    mediaType?: string | number | undefined;
  },
  mediaName?: string
) => {
  return (
    (file && {
      key: file.key,
      type: file.type,
      mediaName: mediaName || file?.mediaName,
      mediaType:
        (file?.mediaType && Media_Type_Variant[file.mediaType]) ||
        file.mediaType,
    })
  );
};
