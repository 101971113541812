import { DocumentNode } from 'graphql';
import {
  GET_ALL_APP_GUIDE,
  GET_ALL_NETWORKING,
  GET_ALL_PRESENTERS,
  GET_ALL_RECORDINGS,
  GET_ALL_SCHEDULE,
  GET_ALL_SPONSORS,
} from '../graphQl/Features/QuerySchema';
import RouterPath from './RouterPath';

export const StaticString = {
  UNAUTHENTICATED_CAPS: 'UNAUTHENTICATED',
  JWT: 'jwt expired',
  UNAUTHENTICATED_SMALL: 'Unauthorized',
};

export const LOCAL_STORAGE_KEY = {
  AUTH_TOKEN: 'auth_token',
  USER_ROLE: 'user_role',
  USER_INFO: 'user_info',
};

export const TagName = [
  'Overview',
  'Networking',
  'Sponsors',
  'Schedule',
  'Announcements',
  'Schedule 1v1',
  'Slides',
  'Virtual Expo',
  'Help Desk',
  'Presenters',
  'VIP Content',
  'Recordings',
  'Survey',
  'App Guide',
  'Stream Options',
  'AMA Sessions',
  'Book Meetings',
];

export const TopNavMenu = [
  { name: 'Home', link: RouterPath.homeScreen, exact: true },
  { name: 'Events', link: RouterPath.eventsList, exact: false },
  { name: 'System Managers', link: RouterPath.systemManagers, exact: false },
  { name: 'Total Users', link: RouterPath.totalUsers, exact: false },
];

export const ManagerTopNavMenu = [
  { name: 'Home', link: RouterPath.homeScreen, exact: true },
  { name: 'Events', link: RouterPath.eventsList, exact: false },
  { name: 'Total Users', link: RouterPath.totalUsers, exact: false },
];

interface CommonEnumProps {
  [number: string]: string;
}

export const smtpType: CommonEnumProps = {
  1: 'None',
  2: 'Enabled SSL',
  3: 'TLS',
};

export const EVENTDURATION = {
  FUTURE: 'FUTURE',
  PAST: 'PAST',
  CURRENT: 'CURRENT',
};

export const lookupTypes: CommonEnumProps = {
  eventType: 'event_type',
  featureType: 'event_features',
  privacySetting: 'privacy_setting',
  layout: 'layout',
  userSkills: 'user_skills',
  userDesignation: 'user_designation',
  departments: 'departments',
  liveStreamList: 'feature_live_stream',
};

export const PROVIDER_NAME = {
  VIMEO: 'Vimeo',
};
export const poweredByName = {
  1: 'eve',
  2: 'byCustom',
};
export const privacySettings = {
  1: 'Invite Only',
  2: 'Event Code',
  3: 'Public',
};

export const GeneralSettingsLookUps: CommonEnumProps = {
  'Smart Feed (Default)': 'Smart Feed (Default)',
  'Features Only': 'Features Only',
  Banners: 'Banners',
};

export const Collapse_LinkType = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  NONE: 'NONE',
};

export const SEND_TIME = {
  SEND_NOW: 'Send Now',
  SCHEDULE_TIME: 'Schedule Time',
};

export const AnnouncementLink = [
  { name: Collapse_LinkType.NONE },
  { name: Collapse_LinkType.INTERNAL },
  { name: Collapse_LinkType.EXTERNAL },
];
export const SendTime = [
  { name: SEND_TIME.SEND_NOW },
  { name: SEND_TIME.SCHEDULE_TIME },
];

export const EventTypeEnum = {
  1: 'In person',
  2: 'Virtual',
};

export const sortBy = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const imageExtensions =
  '.jpg,.png,.gif,.webp,.jpeg,.tif,.tiff,.bmp,.JPG,.GIF,.PNG,.GIF,.WEBP,.JPEG,.TIF,.TIFF,.BMP,.jfif';

export const fileTypesImage = ['image', 'video', 'application'];

export const fileTypesDocs = ['image', 'application'];

export const MEDIA_TYPE: CommonEnumProps = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  image: 'IMAGE',
  application: 'DOCUMENT',
};

export const USER_TYPE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  SYSTEM_MANGER: 'SYSTEM_MANAGER',
};
export const MEDIA_NAME = {
  ADMIN_RECORDING_ATTACHMENT: 'ADMIN_RECORDING_ATTACHMENT',
  USERIMAGE: 'USERIMAGE',
  FEATURE_AMASESSION: 'FEATURE_AMA_SESSION_IMAGE',
  FEATURE_NETWORKING: 'FEATURE_NETWORKING_IMAGE',
  FEATURE_PRESENTERS: 'PRESENTER_IMAGE',
  FEATURE_VIRTUAL_EXPO: 'VIRTUAL_EXPO',
  FEATURE_SCHEDULE1V1: 'SCHEDULEv1_IMAGE',
  QUESTION_ICON: 'QUESTION_ICON',
  FEATURE_STREAM_OPTION: 'STREAM_OPTION_IMAGE',
  FEATURE_SPONSORS_IMAGE: 'FEATURE_SPONSORS_ITEM_IMAGE',
  FEATURE_SPONSORS_ICON: 'FEATURE_SPONSORS_ITEM_ICON',
  FEATURE_SPONSORS_ITEM_ATTACHMENTS: 'FEATURE_SPONSORS_ITEM_ATTACHMENTS',
  FEATURE_BOOK_MEETING_ICON: 'BOOK_METTING_ICON',
  FEATURE_BOOK_MEETING_IMAGE: 'BOOK_METTING_IMAGE',
  BOOK_MEETING_ATTACHMENTS: 'BOOK_METTING_ATTACHMENTS',
  SCHEDULE_ATTACHMENTS: 'SCHEDULE_ATTACHMENTS',
  ATTENDEE_IMAGE: 'ATTENDEE_IMAGE',
  AMA_SESSION_IMAGE: 'AMA_SESSION_IMAGE',
  EVENT_LOGO: 'EVENT_LOGO',
  EVENT_COVER: 'EVENT_COVER',
  BANNER_IMAGE: 'BANNER_IMAGE',
  HELP_DESK_IMAGE: 'HELP_DESK_IMAGE',
  HELP_DESK_ATTACHMENTS: 'HELP_DESK_ATTACHMENTS',
  APP_GUIDE_ATTACHMENTS: 'APP_GUIDE_ATTACHMENTS',
  SLIDE_ATTACHMENT: 'SLIDE_ATTACHMENT',
  COMMENT_IMAGE: 'COMMENT_IMAGE',
  COMMENT_FILE: 'COMMENT_FILE',
  QUESTION_IMAGE: 'QUESTION_IMAGE',
  QUESTION_FILE: 'QUESTION_FILE',
  APP_LOGO: 'APP_LOGO',
  EVENT_SPONSOR_BANNER: 'EVENT_SPONSOR_BANNER',
  EVENT_LOGIN_BANNER: 'EVENT_LOGIN_BANNER',
  LIVE_STREAM_ATTACHMENTS: 'LIVE_STREAM_ATTACHMENTS',
};

export const StatusOfEvent: CommonEnumProps = {
  true: 'APPROVED',
  false: 'REJECTED',
  null: 'PENDING',
};

export const PublishEvent: CommonEnumProps = {
  true: 'PUBLISHED',
  false: 'NOT PUBLISHED',
};

export const EventStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  LATEST: 'LATEST',
};

export const BtnVariant = {
  GRADIENT: 'gradient',
  ROUND_GRADIENT: 'round-gradient',
  LINK: 'link',
  BORDERED: 'bordered',
};

export const Media_Type_Variant: any = {
  1: 'IMAGE',
  2: 'VIDEO',
  3: 'DOCUMENT',
};

export const compareFileType: CommonEnumProps = {
  DOC: 'application/msword',
  Word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF: 'application/pdf',
  'Power Point':
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

export const acceptImageFileFormats =
  'image/jpg,image/png,image/jpeg,image/tiff';
export const acceptVideoFileFormats = 'video/mp4,video/x-ms-wmv';
export const acceptAttachmentsFileFormats =
  'image/jpg,image/jpeg,image/png,image/tiff,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';

export const Document_Type = {
  pdf: 'pdf',
  msword: 'msword',
};

export const DATE_FORMATS = {
  MMM_D_YYYY: 'MMM D, YYYY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  h_mm_a: 'h:mm a',
  D_YYYY: 'D, YYYY',
  ll: 'll',
  MMM_D: 'MMM D',
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MMM_YYYY_hh_mm_a: 'DD MMM, YYYY, hh:mm a',
  h_mm_A: 'h:mm A',
  dddd_MMM_DD_YYYY: 'dddd, MMM-DD, YYYY',
  MMM_DD_YYYY_hh_mm_A: 'MMM DD,YYYY  hh:mm A',
};

export enum NameType {
  cover = 'cover',
  loginBanner = 'loginBanner',
  appLogo = 'appLogo',
  icon = 'icon',
  map = 'map',
}

export const DOCUMENT_TYPES = {
  DOC: 'DOC',
  PDF: 'PDF',
  Word: 'Word',
};

export const ButtonVariant: { [name: string]: { className: string } } = {
  gradient: { className: 'primary-gradient' },
  link: { className: 'link-button' },
  'round-gradient': { className: 'primary-gradient round-button' },
  bordered: { className: 'bordered-button' },
};

export const CheckboxTitle = {
  EXTERNAL_BROWSER: 'Open link in external browser',
  DATA_TO_URL: 'Pass Data to URL',
  DENIAL_MESSAGE: 'Customize the denial message.',
};

export const FEATURES_NAME: CommonEnumProps = {
  OVERVIEW: 'Overview',
  NETWORKING: 'Networking',
  SPONSORS: 'Sponsors',
  EVENT_SESSIONS: 'Breakout Sessions',
  ANNOUNCEMENTS: 'Announcements',
  SCHEDULE1_ON_1: 'Schedule 1-On-1',
  SLIDES: 'Slides',
  VIRTUAL_EXPO: 'Virtual Expo',
  HELP_DESK: 'Help Desk',
  PRESENTERS: 'Presenters',
  VIP_CONTENT: 'VIP Content',
  RECORDINGS: 'Recordings',
  SURVEY: 'Survey',
  APP_GUIDE: 'App Guide',
  MAIN_SESSION: 'Main Session',
  STREAM_OPTIONS: 'Stream Options',
  ASK_US_ANYTHING: 'Q&A with Sponsor',
  BOOK_MEETINGS: 'Book Meetings',
  LIVE_POLLS: 'Live Polls',
  CATEGORY: 'Category',
  ATTENDEE: 'Attendee',
};

export const AdminNotificationRedirectArray = [
  'ASK_US_ANYTHING',
  'NEW_REQUEST_EVENT',
];

export const ManagerNotificationRedirectArray = ['ASK_US_ANYTHING'];

export const NotificationPathRedirect: CommonEnumProps = {
  ASK_US_ANYTHING: RouterPath.AMASession,
  NEW_REQUEST_EVENT: RouterPath.newRequest,
};

export const USER_ROLE = {
  SYSTEM_MANAGER: 'system-manager',
  ADMIN: 'admin',
};

export const PROFILES: CommonEnumProps = {
  SYSTEM_MANAGER: 'System Manager',
  USER: 'User',
};
export const SWITCH = {
  ON: 'On',
  OFF: 'Off',
};

export const HEADINGS = {
  USER_DETAIL: 'User Details',
  MANAGERS_DETAIL: 'Managers Details',
  ADD_SMTP: 'Add SMTP',
  EDIT_SMTP: 'Edit SMTP',
  MANAGE_SMTP: 'Manage SMTP',
  ADD_TEMPLATE: 'Add Template',
  EDIT_TEMPLATE: 'Edit Template',
  MANAGE_TEMPLATE: 'Manage Template',
  MANAGE_TYPES: 'Manage Types',
};

export const NOTIFICATION_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const LINK_TYPE = {
  WEBSITE: 'Website',
  PHONE: 'Phone',
  EMAIL: 'Email',
};

interface WebUrlInterface {
  [REACT_APP_BASE_URL: string]: string;
}

export const WebUrl: WebUrlInterface = {
  'https://evecite-api.codezeros.com/admin/graphql':
    'https://evecite.codezeros.com/',
  'https://evecite.api.webelight.co.in/admin/graphql':
    'https://evecite.frontend.webelight.co.in/',
  'https://api-staging.evecite.com/admin/graphql':
    'https://staging.evecite.com/',
  'https://api.evecite.com/admin/graphql': 'https://platform.evecite.com/' ,
   "http://localhost:3014/admin/graphql":"http://localhost:3014/admin/graphql"
};

export const settingsName = {
  EVENT_CODE: 'Event Code',
  ENV_CUSTOM: 'byCustom',
  INVITE_ONLY: 'Invite Only',
  UNIQUE_EVENT_CODE: 'Unique Event Code',
};

export const ANSWER_TYPE: CommonEnumProps = {
  1: 'Rating',
  2: 'Textbox',
  3: 'Checkbox',
  4: 'Progress',
};

export const API_NAME = {
  createEvent: 'createEvent',
  updateEvent: 'updateEvent',
  createOrUpdateEventFeature: 'createOrUpdateEventFeature',
  saveEventBanner: 'saveEventBanner',
  updateEventBanner: 'updateEventBanner',
  getAllNotifications: 'getAllNotifications',
  getSingleNotification: 'getSingleNotification',
  createApplication: 'createApplication',
  updateApplication: 'updateApplication',
  getAdminBanner: 'getAdminBanner',
  createCms: 'createCms',
  updateCms: 'updateCms',
  getSingleCms: 'getSingleCms',
};

export const TYPE_NAME = {
  TYPES: 'Types',
};
export const GET_ALL_TYPES_LOOKUPS = {
  getAllLookupsList: 'getAllLookupsList',
  createLookup: 'createLookup',
  getAllTypes: 'getAllTypes',
  getSingleLookup: 'setSingleLookup',
  updateLookup: 'updateLookup',
  deleteLookup: 'deleteLookup',
};

export const USER_API = {
  getTotalUser: 'getTotalUser',
  getAllSystemManager: 'getAllSystemManager',
  deleteUser: 'deleteUser',
};

export const FEATURES_API = {
  // Create APIs
  createVipContent: 'createVipContent',
  createFeatureAppGuide: 'createFeatureAppGuide',
  createStreamOption: 'createStreamOption',
  createFeatureLiveStream: 'createFeatureLiveStream',
  createFeatureAnnouncement: 'createFeatureAnnouncement',
  createFeatureAmaSessions: 'createFeatureAmaSessions',
  createFeatureNetworking: 'createFeatureNetworking',
  createPresenter: 'createPresenter',
  createFeatureRecording: 'createFeatureRecording',
  createSchedule: 'createSchedule',
  createFeatureAttendees: 'createFeatureAttendees',
  createFeatureSlide: 'createFeatureSlide',
  createFeatureSponsor: 'createFeatureSponsor',
  createSurvey: 'createSurvey',
  // Get all APIs
  getAllFeatureNetworking: 'getAllFeatureNetworking',
  getAllSchedule: 'getAllSchedule',
  getAllFeatureAttendees: 'getAllFeatureAttendees',
  getAllFeatureRecordings: 'getAllFeatureRecordings',
  getAllFeatureAppGuides: 'getAllFeatureAppGuides',
  getAllPresenters: 'getAllPresenters',
  getAllFeatureSponsors: 'getAllFeatureSponsors',
  getAllVipContent: 'getAllVipContent',
  getAllHelpDesk: 'getAllHelpDesk',
  getAllQuestions: 'getAllQuestions',
  getAllStreamOptions: 'getAllStreamOptions',
  getAllFeatureLiveStream: 'getAllFeatureLiveStream',
  getAllFeatureAmaSessions: 'getAllFeatureAmaSessions',
  getAllFeatureSlides: 'getAllFeatureSlides',
  getAllFeatureAnnouncements: 'getAllFeatureAnnouncements',
  getAllSurveys: 'getAllSurveys',
  getAllBanner: 'getAllBanner',
  getAllPolls: 'getAllPolls',
  // single APIs
  getSingleVipContent: 'getSingleVipContent',
  getFeatureAppGuide: 'getFeatureAppGuide',
  getStreamOption: 'getStreamOption',
  getSingleFeatureAttendees: 'getSingleFeatureAttendees',
  getSingleSchedule: 'getSingleSchedule',
  // Update APIs
  updateVipContent: 'updateVipContent',
  updateFeatureAppGuide: 'updateFeatureAppGuide',
  updateStreamOption: 'updateStreamOption',
  updateFeatureLiveStream: 'updateFeatureLiveStream',
  updateFeatureAnnouncement: 'updateFeatureAnnouncement',
  updateFeatureAmaSessions: 'updateFeatureAmaSessions',
  updateFeatureNetworking: 'updateFeatureNetworking',
  updatePresenter: 'updatePresenter',
  updateFeatureRecording: 'updateFeatureRecording',
  updateSchedule: 'updateSchedule',
  updateFeatureAttendees: 'updateFeatureAttendees',
  updateFeatureSlide: 'updateFeatureSlide',
  updateFeatureSponsor: 'updateFeatureSponsor',
  updateSurvey: 'updateSurvey',
  //Look Ups
  getProviderList: 'getProviderList',
  // delete APIs
  deleteStreamOption: 'deleteStreamOption',
  deleteFeatureLiveStream: 'deleteFeatureLiveStream',
  deleteFeatureAmaSessions: 'deleteFeatureAmaSessions',
  deleteFeatureAnnouncement: 'deleteFeatureAnnouncement',
  deleteFeatureNetworking: 'deleteFeatureNetworking',
  deletePresenter: 'deletePresenter',
  deleteFeatureRecording: 'deleteFeatureRecording',
  deleteSchedule: 'deleteSchedule',
  deleteFeatureAttendees: 'deleteFeatureAttendees',
  deletefeatureSlide: 'deletefeatureSlide',
  deleteFeatureSponsor: 'deleteFeatureSponsor',
  deleteSurvey: 'deleteSurvey',
  deletePoll: 'deletePoll',
};

export const GET_API_NAME: CommonEnumProps = {
  'Breakout Sessions': 'getAllSchedule',
  Recordings: 'getAllFeatureRecordings',
  'App Guide': 'getAllFeatureAppGuides',
  Presenters: 'getAllPresenters',
  Networking: 'getAllFeatureNetworking',
  Sponsors: 'getAllFeatureSponsors',
};

export const API_SCHEMA: { [name: string]: DocumentNode } = {
  'Breakout Sessions': GET_ALL_SCHEDULE,
  Recordings: GET_ALL_RECORDINGS,
  'App Guide': GET_ALL_APP_GUIDE,
  Presenters: GET_ALL_PRESENTERS,
  Networking: GET_ALL_NETWORKING,
  Sponsors: GET_ALL_SPONSORS,
};

export const COMMON_NAME: CommonEnumProps = {
  NAME: 'name',
  COMPANYNAME: 'companyName',
  DESCRIPTION: 'description',
  SCHEDULEDATETIME: 'scheduleDateTime',
  SUBTITLE: 'subTitle',
  SESSIONDATE: 'sessionDate',
  SESSIONTIME: 'sessionTime',
  LOCATION: 'location',
  TITLE: 'title',
  CATEGORY: 'category',
  OVERVIEW: 'overview',
  DESIGNATION: 'designation',
  PROVIDER: 'provider',
  STARTDATETIME: 'startDateTime',
  DOCTYPE: 'docType',
  EVENT: 'Event',
  DEFAULT: 'default',
  SKILLS: 'skills',
  OTP: 'OTP',
};

export const SortByArry = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.COMPANYNAME,
  },
];
export const AnnouncementsSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.DESCRIPTION,
  },
  {
    name: COMMON_NAME.SCHEDULEDATETIME,
  },
];

export const NetworkingSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.SUBTITLE,
  },
];

export const ScheduleSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.TITLE,
  },
  {
    name: COMMON_NAME.SESSIONDATE,
  },
  {
    name: COMMON_NAME.SESSIONTIME,
  },
  {
    name: COMMON_NAME.LOCATION,
  },
];

export const PresentersSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.DESIGNATION,
  },
];

export const RecordingSortBy = [
  {
    name: COMMON_NAME.PROVIDER,
  },
];

export const SlidesSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.DOCTYPE,
  },
];

export const AMASessionSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.DESIGNATION,
  },
  {
    name: COMMON_NAME.COMPANYNAME,
  },
  {
    name: COMMON_NAME.DESCRIPTION,
  },
];

// same for streamOptions, sponsors, book meetings
export const StreamOptionsSortBy = [
  {
    name: COMMON_NAME.NAME,
  },
  {
    name: COMMON_NAME.SUBTITLE,
  },
  {
    name: COMMON_NAME.CATEGORY,
  },
];

// filterBy Enums
export const FilterByArray = [
  {
    name: COMMON_NAME.DEFAULT,
  },
  {
    name: COMMON_NAME.COMPANYNAME,
  },
  {
    name: COMMON_NAME.SKILLS,
  },
  {
    name: COMMON_NAME.DESIGNATION,
  },
];

// import headers array
const Link_Headers = [
  'Internal Link',
  'Link Type',
  'Social Media',
  'Link Name',
  'Website URL',
  'Email Address',
  'Phone Number',
  'Pass Data to URL',
  'Open Link External Browser',
];
const Attendee_Headers = [
  'Follow Up Label',
  'Follow Up Contact Email',
  'Schedule Button Label',
  'Schedule URL',
  'Open On Link External Browser',
];
export const USER_HEADERS = [
  'Name',
  'Email',
  'Company Name',
  'Designation',
  'Skills',
  'Overview',
];
export const NETWORKING_HEADERS = [
  'Name',
  'Email',
  'Sub Title',
  'Designation',
  'Department',
  'Location',
];
export const ATTENDEE_HEADERS = ['Name', 'Email'];
export const AMA_SESSION_HEADERS = ['Sponsor'];
export const PRESENTERS_HEADERS = [
  'Name',
  'Designation',
  'Company Name',
  'Overview',
  'Start Date Time',
  'End Date Time',
  ...Link_Headers,
  ...Attendee_Headers,
];
export const SLIDES_HEADERS = ['Name', 'Document Type'];
export const STREAM_OPTION_HEADERS = [
  'Name',
  'Sub Title',
  'Description',
  ...Link_Headers,
];
export const ANNOUNCEMENTS_HEADERS = [
  'From',
  'To',
  'Message',
  'Link',
  'Internal Link',
  'External Link',
  'Pass Data to URL',
  'Open Link External Browser',
  'Send',
  'Schedule Date Time',
];
export const RECORDINGS_HEADERS = ['Name', 'Video Type', 'Link'];
export const SPONSORS_HEADERS = [
  'Name',
  'Sub Title',
  'Location',
  'Category',
  'Description',
  ...Link_Headers,
  ...Attendee_Headers,
];
export const SCHEDULE_HEADERS = [
  'Name',
  'Location',
  'Provider',
  'Link',
  'Category',
  'Session Date',
  'Session Time',
  'Description',
  ...Link_Headers,
  'Status',
  'Maximum Attendance',
  'Denial Message',
  'Customize Denial Message',
];

export const generalEnums = {
  REMOVE: 'removed',
  THREE: 3,
  ONE: 1,
};

export const SearchEnter = {
  ENTER: 'Enter',
};

export const LimitEnum = {
  20: 20,
};

export const Image_size = {
  Logo_width: 100,
  Logo_height: 100,
  Overview_CoverImage_width: 1920,
  Overview_CoverImage_height: 1080,
  WebHomeBanner_width: 960,
  WebHomeBanner_height: 980,
  AdminLogin_width: 960,
  AdminLogin_height: 980,
  SponsorAdvertiseBanner_width: 300,
  SponsorAdvertiseBanner_height: 120,
};

export const RIGHT_BG_COLOR = [
  '#fff',
  '#F8F8F8',
  '#F2F4F5',
  '#FEF9EF',
  '#FAF3EE',
];
