import React from "react";
import { withFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import Comments, { CommentProps } from "./Comments";
import { HelpDeskCommentForm } from "../CommonSubModals/SubModals";
import { CREATE_COMMENT } from "graphQl/Features/MutationSchema";
import { CallMutation } from "graphQl/graphqlHandler";
import { CommentSchema } from "utils/formikValidation";
import { GET_ALL_HELP_DESK, GET_ALL_QUESTIONS } from "graphQl/Features/QuerySchema";
import { addMedia } from 'constants/GlobalEventTypeScript';
import { MEDIA_NAME, FEATURES_API } from 'constants/Enums';

const CommentFormikApp = withFormik<CommentProps, HelpDeskCommentForm>({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {
      comment: "",
      file: [],
      doc: [],
      removeMedia: [],
    };
  },
  validationSchema: CommentSchema,

  handleSubmit: ({ comment, file, removeMedia, doc }, { props, resetForm }) => {
    const { createComment, id, eventId } = props;
    const { COMMENT_IMAGE, COMMENT_FILE } = MEDIA_NAME
    let media = []
    file?.length && media.push(addMedia(file[0], COMMENT_IMAGE))
    doc?.length && media.push(addMedia(doc[0], COMMENT_FILE))
    CallMutation(createComment, {
      variables: {
        id: id || "",
        eventId: eventId || "",
        comment: comment,
        addMedia: media || [],
        removeMedia: removeMedia,
      },
    });
    resetForm()
  },
})(Comments);

const CommentFormikWrapper = (props: CommentProps) => {
  const { eventId, livePolls, commentVariables } = props;
  const { getAllQuestions, getAllHelpDesk } = FEATURES_API
  const apiName = livePolls ? getAllQuestions : getAllHelpDesk
  const querySchema = livePolls ? GET_ALL_QUESTIONS : GET_ALL_HELP_DESK
  const [createComment, { data, loading }] = useMutation(CREATE_COMMENT, {
    update(cache, { data: APIResponse }) {
      const cacheObj = {
        query: querySchema,
        variables: commentVariables,
      };
      const existingData: any = cache.readQuery({
        ...cacheObj,
      });
      if (existingData) {
        const finalData = existingData[apiName].nodes.map((item: any) => {
          if (item?._id === APIResponse?.createComment._id) {
            return {
              ...item,
              comments: APIResponse?.createComment.comments,
            };
          } else return item;
        });
        cache.writeQuery({
          ...cacheObj,
          data: {
            [apiName]: {
              nodes: finalData,
            },
          },
        });
      }
    },
  });
  return (
    <CommentFormikApp
      createComment={createComment}
      data={data}
      loading={loading}
      {...props}
    />
  );
};
export default CommentFormikWrapper;
