import React, { ReactNode } from 'react'
import { Select } from 'antd'
import { Field, FieldProps } from 'formik'
import { objectToSingleDataArray } from 'utils/helper'
import MyErrorMessage from "components/FormComponent/WSErrorMessage";
import Labels from './Labels/Labels'
const { Option } = Select

interface Props {
  defaultValue?: string | number | Array<string>
  placeHolder?: string
  optionList?: any
  className?: string
  mode?: 'multiple' | 'tags' | undefined
  showArrow?: boolean
  disabled?: boolean
  name: string
  listOptions?: string
  passed?: string
  infoMessage?: string
  showSearch?: boolean
  required?: boolean
  labelClass?: string
  label?: string
  onSelectItem?: (e: any) => void
  loading?: boolean
  optionFilterProp?: string
  notFoundContent?: ReactNode
}
const WSSelect = (props: Props) => {
  const {
    defaultValue,
    optionList,
    placeHolder,
    className,
    mode,
    showArrow,
    disabled,
    showSearch = true,
    name,
    onSelectItem,
    listOptions = 'name',
    passed = '_id',
    infoMessage,
    required,
    labelClass,
    label,
    loading,                                                   
    notFoundContent
  } = props

  const renderOptions = objectToSingleDataArray(optionList, listOptions, passed)
  return (
    <>
      {name && (
        <Labels
          required={required}
          infoMessage={infoMessage}
          label={label || name}
          labelClass={labelClass}
        />
      )}
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <>
            <Select
              notFoundContent={notFoundContent}
              loading={loading}
              optionFilterProp='children'
              showSearch={showSearch}
              mode={mode}
              showArrow={showArrow}
              value={field.value || defaultValue}
              placeholder={placeHolder}
              className={className}
              disabled={disabled}
              onChange={(e) => {
                onSelectItem && onSelectItem(e)
                form.setFieldValue(name, e)
              }}
              onBlur={() => form.setFieldTouched(name, true)}
            >
              {renderOptions &&
                renderOptions.map((item) => {
                  return (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  )
                })}
            </Select>
            <MyErrorMessage name={name} />
          </>
        )}
      </Field>
    </>
  )
}

export default WSSelect
