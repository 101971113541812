import React from 'react'
import moment from 'moment'
import { CSVLink } from 'react-csv'

import WSToolTip from 'components/common/WSTooltip/WSTooltip'
import { WSButton, WSImage } from 'components/FormComponent'
import { WSModal } from 'components/common'
import WSTable from 'components/common/WSTable'

import { LiveStreamLogsTip } from 'utils/ToolTips'
import images from 'constants/Images'
import { BtnVariant, DATE_FORMATS } from 'constants/Enums'
import { EventFieldProps, UserProps } from 'constants/GlobalEventTypeScript'
import { isoToFormat } from 'utils/helper'

interface StreamingLogsProps {
  user: UserProps
  eventId: EventFieldProps
  createdAt: string
  _id: string
}

interface LogsProps {
  showModal?: boolean
  apiData?: StreamingLogsProps[]
  apiLoading?: boolean
  onClick?: () => void
  onModalClose?: () => void
  name?: string
}

const StreamLogsModal = ({
  apiData,
  apiLoading,
  onClick,
  onModalClose,
  showModal,
  name,
}: LogsProps) => {
  const { DD_MMM_YYYY_hh_mm_a } = DATE_FORMATS
  const { logsIcon, download } = images
  const userLogsDownloadData = apiData?.map((data: StreamingLogsProps) => {
    return {
      ...data,
      name,
      createdAt: isoToFormat(data.createdAt, DD_MMM_YYYY_hh_mm_a),
    }
  })

  const LogsColumn = [
    {
      title: 'Sr No',
      render: (
        text: StreamingLogsProps,
        data: StreamingLogsProps,
        index: number
      ) => index + 1,
    },
    {
      title: 'Session Name',
      render: () => name,
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      render: (text: UserProps) => text.name,
    },
    {
      title: 'User Email',
      dataIndex: 'user',
      render: (text: UserProps) => text.email,
    },
    {
      title: 'Login Time',
      render: (text: StreamingLogsProps) =>
        isoToFormat(text.createdAt, DD_MMM_YYYY_hh_mm_a),
    },
  ]

  return (
    <div className='d-flex'>
      <WSToolTip title={LiveStreamLogsTip}>
        <div>
          <WSButton
            label=''
            leftIcon={logsIcon}
            width='20px'
            leftImageClass='m-0'
            inlineClass='add-attendee'
            className='attendee'
            variant={BtnVariant.BORDERED}
            onClick={onClick}
          />
        </div>
      </WSToolTip>
      <WSModal
        width='800px'
        className='features-add'
        title='Streaming Logs'
        open={showModal}
        onCancel={onModalClose}
        footer={[]}
      >
        <div className='d-flex flex-column'>
          <CSVLink
            filename={`${name}_Schedule_Logs.csv`}
            data={userLogsDownloadData || []}
            headers={[
              { label: 'Session Name', key: 'name' },
              { label: 'User Name', key: 'user.name' },
              { label: 'User Email', key: 'user.email' },
              { label: 'Login Time', key: 'createdAt' },
            ]}
            className='download-btn import-modal-download-btn mb-1'
          >
            Download Logs
            <WSImage
              imagesPath={download}
              height={40}
              width={40}
              inlineClass='download-image'
            />
          </CSVLink>
          <WSTable
            pagination={false}
            loading={apiLoading}
            columns={LogsColumn}
            dataSource={apiData || []}
          />
        </div>
      </WSModal>
    </div>
  )
}

export default StreamLogsModal
