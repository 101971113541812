import React, { useEffect, ReactNode } from "react";
import { DocumentNode, useLazyQuery, useQuery } from "@apollo/react-hooks";
import { WSSelect } from "components/common";
import { Loader, WSInput } from "components/FormComponent";
import { GET_ALL_LOOKUPS } from "graphQl/Events/QuerySchema";
import { addExternalLinksTip, addInternalLinksTip, labelSubtitleTip } from "utils/ToolTips";
import { lookupTypes } from "constants/Enums";
import {
  GET_ALL_CATEGORIES,
  GET_ALL_STREAM_OPTIONS,
} from "graphQl/Features/QuerySchema";
import WSGeosuggetion from "components/Geosuggetion";
import Labels from "components/common/Labels/Labels";
import { ExternalLinkProps, ImageProps } from "constants/GlobalEventTypeScript";
import WSToolTip from "components/common/WSTooltip/WSTooltip";
import InternalLink, { InternalLinkProps } from "./AddInternalLinks";
import { AnnouncementExternalLink, ExternalLink } from "./AddExternalLinks";

interface Props {
  className?: string;
  inputClass?: string;
  required?: boolean;
  disabled?: boolean;
  infoMessage?: string;
  fields?: string[];
  name?: string;
  label?: string;
  eventId?: string;
  documentTitle?: string;
  documentName?: Array<{ name: string }>;
  documentClass?: string;
  divClass?: string
  notFoundContent?: ReactNode
}

const Department = ({ className, required, disabled }: Props) => {
  const { data: department } = useQuery(GET_ALL_LOOKUPS, {
    variables: { type: lookupTypes.departments, limit: 20 },
  });
  return (
    <WSSelect
      mode="multiple"
      disabled={disabled}
      name="department"
      required={required}
      optionList={department?.getAllLookups?.nodes}
      className={`${className} width-100`}
    />
  );
};

const Designation = ({ className, required, disabled }: Props) => {
  const { data: designations } = useQuery(GET_ALL_LOOKUPS, {
    variables: { type: lookupTypes.userDesignation, limit: 20 },
  });
  return (
    <WSSelect
      disabled={disabled}
      name="designation"
      required={required}
      optionList={designations?.getAllLookups?.nodes}
      className={`${className} width-100`}
    />
  );
};

const Category = ({ eventId }: Props) => {
  const { data: category } = useQuery(GET_ALL_CATEGORIES, {
    variables: { offset: 1, limit: 10, eventId: eventId },
  });
  return (
    <WSSelect
      name="category"
      required
      optionList={category?.getAllCategories?.nodes}
      className="width-100"
    />
  );
};

const Skills = ({ className, required, disabled, infoMessage }: Props) => {
  const { data: skills } = useQuery(GET_ALL_LOOKUPS, {
    variables: { type: lookupTypes.userSkills, limit: 20 },
  });
  return (
    <WSSelect
      disabled={disabled}
      mode="multiple"
      name="skills"
      required={required}
      infoMessage={infoMessage}
      optionList={skills?.getAllLookups?.nodes}
      className={`${className} width-100`}
    />
  );
};

const ChooseProvider = ({ name, eventId, disabled, notFoundContent }: Props) => {
  const { data: streamOptionData, loading } = useQuery(GET_ALL_STREAM_OPTIONS, {
    fetchPolicy: "network-only",
    variables: { offset: 1, limit: 20, eventId: eventId },
  });
  return (
    <WSSelect
      notFoundContent={notFoundContent}
      loading={loading}
      name={name || ""}
      disabled={disabled}
      placeHolder="Choose Provider"
      optionList={streamOptionData?.getAllStreamOptions?.nodes.map((item: any) => ({ name: item.name.name, _id: item.name._id }))}
      listOptions="name"
      passed="_id"
      labelClass="mt-0"
      className="width-100"
    />
  );
};

const ModalInputs = ({ fields, required }: Props) => {
  return (
    <>
      {fields &&
        fields.map((name: string) => (
          <WSInput
            key={name}
            name={name}
            required={required}
            inputClass="feature-input"
          />
        ))}
    </>
  );
};

const Name = ({ disabled, label, name = "name", divClass }: Props) => {
  return (
    <WSInput
      name={name}
      label={label}
      disabled={disabled || false}
      required
      inputClass="feature-input"
      labelClass="mt-0"
      divClass={divClass}
    />
  );
};

const Location = (props: any) => {
  const { setFieldValue, initialValue } = props;
  const setValue = (loc: any) => {
    setFieldValue("geometricLocation", loc?.label || initialValue || "");
  };

  return (
    <>
      <Labels required label="Location" />
      <WSGeosuggetion
        withMap={false}
        setValue={setValue}
        name="geometricLocation"
        initialValue={initialValue || ""}
      />
    </>
  );
};

const LabelSubtitle = ({
  infoMessage = labelSubtitleTip,
  name = "subTitle",
  disabled,
}: Props) => {
  return (
    <WSInput
      name={name}
      disabled={disabled}
      label="Label/Subtitle"
      required
      infoMessage={infoMessage}
      inputClass="feature-input"
    />
  );
};

interface DocProps {
  eventId?: string;
  schema: DocumentNode;
  queryName: string;
  initData?: any;
  setInitData?: Function;
  setLoading?: Function;
  singleQuerySchema: DocumentNode;
  singleQueryName: string;
}
const WSDocument = ({
  eventId,
  schema,
  queryName,
  setInitData,
  setLoading,
  singleQueryName,
  singleQuerySchema,
}: DocProps) => {
  const { data, loading } = useQuery(schema, {
    variables: { eventId: eventId },
  });
  const [
    editVIPContent,
    { data: editData, loading: singleLoading },
  ] = useLazyQuery(singleQuerySchema, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setInitData && setInitData(data && data[singleQueryName]);
    },
  });

  useEffect(() => {
    setLoading && setLoading(singleLoading);
  }, [singleLoading]);
  const queryData = data?.[queryName] || [];
  return (
    <div className="document-main-div">
      {loading && <Loader />}
      {queryData?.length
        ? queryData?.map((item: any) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center sub-div"
                key={item?._id}
              >
                    <div className="d-flex flex-column" key={item?.name}>
                      <span className="document-title-text">
                        {item?.name}
                      </span>
                      <div className="d-flex flex-column">
                        <span className="document-name">
                          {item?.overview || item?.documentType}
                        </span>
                        {item?.select && (
                          <span className="document-name">
                            {item?.select?.name}
                          </span>
                        )}
                        {item?.attachments?.length ?
                          item?.attachments?.map((img: ImageProps) => {
                            return (
                              <span className="document-name" key={img.key}>
                                {img?.key}
                              </span>
                            );
                          }) : <></>}
                      </div>
                    </div>
                <div
                  className="document-edit-text cursor-pointer"
                  onClick={() => {
                    editVIPContent({ variables: { id: item._id } });
                    setInitData &&
                      setInitData(
                        item._id ===
                          (editData && editData[singleQueryName]?._id)
                          ? editData[singleQueryName]
                          : {}
                      );
                  }}
                >
                  EDIT
                </div>
              </div>
            );
          })
        : !loading && <span> No Records Are Added </span>}
    </div>
  );
};

// collapses of features
interface LinkProps {
  key: number
}
function InternalLinkData({ apiName, featureSchema, key }: InternalLinkProps & LinkProps) {
  return {
    panelHeader: (
      <>
        <span className='collapse-title-info'>Add Internal Links </span>
        <WSToolTip title={addInternalLinksTip} placement="top">
        <span className='info-title-btn'>i</span>
        </WSToolTip>
      </>
    ),
    insideForm: <InternalLink apiName={apiName} featureSchema={featureSchema} />,
    key,
  }
}

function ExternalLinkData(key: number, values?: { externalLinks: ExternalLinkProps[] }, isEdit?: boolean) {
  return {
    panelHeader: (
      <>
        <span className='collapse-title-info'>Add External Links </span>
        <WSToolTip title={addExternalLinksTip} placement="top">
        <span className='info-title-btn'>i</span>
        </WSToolTip>
      </>
    ),
    insideForm: <ExternalLink values={values} disabled={isEdit} />,
    key,
  }
}

function ExternalLinkWithoutLinkType(key: number, checkBox: boolean, attendeeSelect?: string[]){
  return {
  panelHeader: (
    <>
      <span className="collapse-title-info">Add External Links </span>
      <WSToolTip title={addExternalLinksTip} placement="top">
      <span className='info-title-btn'>i</span>
      </WSToolTip>
    </>
  ),
  insideForm: <AnnouncementExternalLink attendeeSelect={attendeeSelect} checkBox={checkBox} />,
  key,
}
}

export {
  Category,
  Designation,
  Department,
  Skills,
  ChooseProvider,
  ModalInputs,
  Location,
  Name,
  LabelSubtitle,
  WSDocument,
  InternalLinkData,
  ExternalLinkData,
  ExternalLinkWithoutLinkType,
};
