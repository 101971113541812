import React, {ReactNode} from "react";
import { Upload } from "antd";
import { Field } from "formik";
import { UploadProps } from "antd/lib/upload";
import WSButton from "./WSButton/WSButton";
import { BtnVariant } from "constants/Enums";
import { beforeUpload } from "utils/CommonFunctions";

interface UploadImageProps extends UploadProps {
  className?: string;
  name: string;
  btnClass?: string;
  label?: string;
  action?: any;
  loading?: boolean
  uploadBtn?: ReactNode
  fixedWidth?:number
  fixedHeight?:number
}
const ImgUpload = ({
  className,
  name,
  btnClass,
  label,
  action,
  loading,
  onChange = () => {},
  uploadBtn,
  accept,
}: UploadImageProps) => {
  return (
    <>
      <Field name={name}>
        {() => (
          <Upload
            onChange={onChange}
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess && onSuccess('ok', {} as any)
              }, 0)
            }}
            className={className}
            fileList={[]}
            action={action}
            beforeUpload={(file: File) => beforeUpload(file, 5, accept)}
          >
            {uploadBtn ? (
              uploadBtn
            ) : (
              <WSButton
                variant={BtnVariant.BORDERED}
                type='button'
                label={label}
                className={`cursor-pointer ${btnClass}`}
              />
            )}
          </Upload>
        )}
      </Field>
    </>
  )
};

export default ImgUpload;
