import React from "react";
import { withFormik } from "formik";
// import { useHistory } from "react-router-dom";
import { useMutation, MutationFunction } from '@apollo/react-hooks';
// components
import { FeaturesModalForm, FeaturesModalProps } from "./FeaturesModal";
import { FeatureModal } from "../event/eventForms/CommonSubModals/FeaturesReuseableParts";
import { ChooseProvider } from "../event/eventForms/CommonSubModals/ModalFields";
// constants
import { CallMutation } from "graphQl/graphqlHandler";
import NotificationWithIcon from "constants/Toster";
import { FEATURES_NAME, NOTIFICATION_STATUS } from "constants/Enums";
import { MODAL_ADDED } from "utils/NotificationMessages";
import { BreakoutSessionSchema } from "utils/formikValidation";
import { BREAKOUT_SESSION_MUTATION } from "graphQl/Features/MutationSchema";
import { useLocation } from "react-router-dom";

interface BreakoutSessionProps extends FeaturesModalProps {
  modalState?: number;
  showModal?: () => void;
  index?: number;
  createBreakoutSession?: MutationFunction;
  data?: Object;
  ids: { eventId: string; featureId: string };
}
const BreakoutSession = (props: BreakoutSessionProps) => {
  const { modalState, showModal, index, loading } = props;
  // const history = useHistory()
  const location = useLocation()

  return (
    <FeatureModal
      infoMessage="A brief paragraph to describe this item."
      showModal={modalState === index}
      onCancel={showModal}
      title="Edit Breakout Session"
      loading={loading}
    >
      <ChooseProvider name="breakoutSession" eventId={location?.state?.eventId} />
    </FeatureModal>
  );
};

const BreakoutSessionFormikApp = withFormik<BreakoutSessionProps, FeaturesModalForm>(
  {
    mapPropsToValues: () => {
      return {
        breakoutSession: "",
      };
    },
    validationSchema: BreakoutSessionSchema,

    handleSubmit: ({ breakoutSession }, { props, resetForm }) => {
      const { ids, createBreakoutSession } = props;
      const { eventId } = ids;
      CallMutation(createBreakoutSession, {
        variables: {
          input: {
            eventId: eventId,
            provider: breakoutSession,
          },
        },
      });
      resetForm()
    },
  }
)(BreakoutSession);
const BreakoutSessionFormikWrapper = (props: BreakoutSessionProps) => {
  const [createBreakoutSession, { data, loading }] = useMutation(
    BREAKOUT_SESSION_MUTATION,
    {
      update(cache, { data: APIResponse }) {
        props.showModal && props.showModal();
        NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS, MODAL_ADDED(FEATURES_NAME.BREAKOUT_SESSION))
    }}
  );
  return (
    <BreakoutSessionFormikApp
      createBreakoutSession={createBreakoutSession}
      data={data}
      loading={loading}
      {...props}
    />
  );
};

export default BreakoutSessionFormikWrapper;
