import React from 'react'
import { Table } from 'antd';
import { PaginationProps } from 'constants/GlobalEventTypeScript';

interface WSTableProps {
  columns?: any
  className?:string;
  locale?:Object
  rowSelection?:Object
  dataSource?:any
  onChange?: (e?:any)=> void
  rowClassName?: string
  loading?: boolean
  pagination?: PaginationProps | false
  rowKey?: string;
}
    
const WSTable = (props:WSTableProps) => {
    const { columns, className, locale, rowSelection, dataSource, onChange, rowClassName, loading, pagination, rowKey = "_id" } = props;
    
    return (
      <Table
        loading={loading}
        columns={columns}
        className={`${className} event-list-pagination`}
        locale={locale}
        rowKey={rowKey}
        rowSelection={rowSelection}
        onChange={onChange}
        dataSource={dataSource}
        rowClassName={rowClassName}
        pagination={pagination}
      />
    );
}

export default WSTable;

