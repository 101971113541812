import gql from 'graphql-tag';
import { externalLinksSchema } from '../CommonSchemas';

const GET_SINGLE_NETWORKING = gql`
  query getSingleFeatureNetworking($id: String!) {
    getSingleFeatureNetworking(id: $id) {
      _id
      name
      subTitle
      email
      overview
      designation {
        _id
        name
      }
      department {
        _id
        name
      }
      image {
        _id
        url
        key
        type
        mediaType
        mediaName
      }
      location
    }
  }
`;

const GET_SINGLE_SPONSOR = gql`
  query getSingleFeatureSponsor($id: String!) {
    getSingleFeatureSponsor(id: $id) {
      _id
      name
      subTitle
      description
      location
      attendeeCalls {
        followUpLabel
        followUpContactEmail
        scheduleButtonLabel
        scheduleUrl
        openLinkOnExternalBrowser
        passDataToUrl
      }
internalLinkUrl  {
        _id
      }
            ${externalLinksSchema}
      attachments {
        _id
         key
        mediaType
        mediaName
        url
        type
      }
      image {
        _id
        key
        url
        type
        mediaType
      }
      category {
        _id
        name
      }
    }
  }
`;

const GET_SINGLE_SCHEDULE = gql`
  query getSingleSchedule($id: String!) {
    getSingleSchedule(id: $id) {
      _id
      tracks {
        _id
        name
      }
      name
      description
      sessionDate
      sessionTime
      location
      isAllowQa
      internalLinkUrl  {
        _id
      }
      link
      recordingUrl
      provider{
        _id
        name
      }
      ${externalLinksSchema}
      attendanceManagement{
        status
        maximumAttendance
        denialMessage
        customizeDenialMessage
        attendees {
          _id
          email
          name
          subTitle
        }
      }
      attachments{
        _id
        key
        url
        mediaType
        mediaName
        type
      }
    }
  }
`;

const GET_SINGLE_ATTENDEE = gql`
  query getSingleFeatureAttendees($id: String!) {
    getSingleFeatureAttendees(id: $id) {
      _id
      name
      email
      subTitle
      image {
        url
        mediaType
        mediaName
        key
        type
      }
      designation {
        _id
        name
      }
    }
  }
`;

const GET_SINGLE_ANNOUNCEMENTS = gql`
  query getSingleFeatureAnnouncement($id: String!) {
    getSingleFeatureAnnouncement(id: $id) {
      _id
      from
      to {
        _id
        name
      }
      message
      link
      internalLinkUrl {
        _id
      }
      externalLink
      openLinkExternalBrowser
      passdataToUrl
      send
      scheduleDateTime
    }
  }
`;

const GET_SINGLE_PRESENTER = gql`
  query getPresenter($id: String!) {
    getPresenter(id: $id) {
      _id
      name
      startDateTime
      endDateTime
            attendeeCalls {
        followUpLabel
        followUpContactEmail
        scheduleButtonLabel
        scheduleUrl
        openLinkOnExternalBrowser
        passDataToUrl
      }
      image{
        _id
        key
        url
        type
        mediaName
        mediaType
      }
      internalLinkUrl {
        _id
      }
      overview
      companyName
      designation {
        _id
        name
      }
      coaching
      ${externalLinksSchema}
    }
  }
`;

const GET_SINGLE_POLL = gql`
  query getSinglePoll($PollId: String!) {
    getSinglePoll(PollId: $PollId) {
      _id
      questions {
        _id
        question
        answerOptions
      }
      isPollClosed
    }
  }
`;

const GET_SINGLE_RECORDING = gql`
  query getFeatureRecording($id: String!) {
    getFeatureRecording(id: $id) {
      _id
      name
      link
      attachments {
        _id
        key
        type
        mediaType
        mediaName
        url
      }
    }
  }
`;

const GET_SINGLE_SLIDE = gql`
  query getFeatureSlide($FeatureSlideId: String!) {
    getFeatureSlide(FeatureSlideId: $FeatureSlideId) {
      _id
      name
      documentType
      attachments {
        _id
        key
        type
        mediaType
        mediaName
        url
      }
    }
  }
`;

const GET_SINGLE_STREAM_OPTION = gql`
  query getStreamOption($id: String!) {
    getStreamOption(id: $id) {
      _id
      name {
        _id
        name
      }
      image {
        _id
        key
        type
        mediaType
        mediaName
        url
      }
      description
     ${externalLinksSchema}
      costing
      subTitle
    }
  }
`;

const GET_SINGLE_BOOK_MEETINGS = gql`
  query getSingleBookingMeeting($id: String!) {
    getSingleBookingMeeting(id: $id) {
      _id
      name
      subTitle
      category {
        _id
        name
      }
    }
  }
`;

const GET_SINGLE_AMA_SESSION = gql`
  query getSingleFeatureAmaSession($id: String!) {
    getSingleFeatureAmaSession(id: $id) {
      _id
      document {
        _id
        name
      }
    }
  }
`;

const GET_SINGLE_VIP_CONTENT = gql`
  query getSingleVipContent($id: String!) {
    getSingleVipContent(id: $id) {
      _id
      name
      overview
      select {
        _id
        name
      }
      availableSession
    }
  }
`;

const GET_SINGLE_APP_GUIDE = gql`
  query getFeatureAppGuide($id: String!) {
    getFeatureAppGuide(id: $id) {
      _id
      name
      documentType
      attachments {
        url
        mediaType
        mediaName
        type
        key
        _id
      }
    }
  }
`;

const GET_SINGLE_LIVE_STREAM = gql`
  query getSingleFeatureLiveStream($id: String!) {
    getSingleFeatureLiveStream(id: $id) {
      _id
      title
      description
      provider {
        _id
        name
      }
      meetingUrl
      isAllowQa
      attachments {
        url
        key
        mediaName
        mediaType
        type
        _id
      }
    }
  }
`;

const GET_SINGLE_SURVEY = gql`
  query getSingleSurvey($SurveyId: String!) {
    getSingleSurvey(SurveyId: $SurveyId) {
      _id
      questions {
        _id
        answerType
        answerOptions
        question
        icon {
          _id
          key
          mediaName
          mediaType
          type
          url
        }
      }
    }
  }
`;
export {
  GET_SINGLE_NETWORKING,
  GET_SINGLE_POLL,
  GET_SINGLE_SPONSOR,
  GET_SINGLE_SCHEDULE,
  GET_SINGLE_ANNOUNCEMENTS,
  GET_SINGLE_PRESENTER,
  GET_SINGLE_RECORDING,
  GET_SINGLE_SLIDE,
  GET_SINGLE_STREAM_OPTION,
  GET_SINGLE_BOOK_MEETINGS,
  GET_SINGLE_AMA_SESSION,
  GET_SINGLE_VIP_CONTENT,
  GET_SINGLE_APP_GUIDE,
  GET_SINGLE_LIVE_STREAM,
  GET_SINGLE_SURVEY,
  GET_SINGLE_ATTENDEE,
};
