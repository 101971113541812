import { message } from 'antd';
import { v1 as uuidv1 } from 'uuid';

import { NOTIFICATION_STATUS, acceptImageFileFormats } from 'constants/Enums';
import NotificationWithIcon from 'constants/Toster';
import { UPLOAD_CSV } from './NotificationMessages';

export function isEmptyObj(obj: any) {
  return !obj || Object.keys(obj).length === 0
}

export const generateUniqueNumber = () => {
  return uuidv1()
};

export const beforeUpload = (file: File, fileSize = 2, accept: string = acceptImageFileFormats) => {
  const acceptFiles = accept.split(',')

  const isFormatOk = acceptFiles.includes(file?.type)
  const isSizeOk = file.size / 1024 / 1024 < fileSize;
  if (!isFormatOk) {
    message.error("Unsupported file format");
  }
  else if (!isSizeOk) {
    message.error(`File must smaller than ${fileSize}MB!`);
  }
  return isFormatOk && isSizeOk;
}

export const uploadCSVFile = (file: File, setFileUpload: Function) => {
  let extension = file.name.split('.')[1].toLowerCase()
  if (extension === 'csv'){
    setFileUpload(file || undefined)
    return true
  }
  else {
    setFileUpload(undefined)
    NotificationWithIcon(NOTIFICATION_STATUS.ERROR, UPLOAD_CSV)
    return false
  }
}