import React, { useState } from "react";
import { ApolloCache, useMutation } from "@apollo/react-hooks";
// constants
import {
  BtnVariant,
  EventStatus,
  NOTIFICATION_STATUS,
} from "constants/Enums";
import { getLocalInfo } from "utils/helper";
import images from "constants/Images";
import { CallMutation } from "graphQl/graphqlHandler";
import NotificationWithIcon from "constants/Toster";
// components
import Confirmation from "containers/ConfirmAction/ConfirmAction";
import { GET_ALL_EVENTS } from "graphQl/Events/QuerySchema";
import { WSButton } from "components/FormComponent";
import RejectMessageModel from "./RejectMessageModel";
import { RejectMessageModalProps } from "pages/event/eventForms/CommonSubModals/SubModals";
import { CHANGE_EVENT_STATUS } from "graphQl/Events/MutationSchema";
// style
import "./AcceptReject.scss";

interface Props extends RejectMessageModalProps {
  id?: string;
  handleSubmit?: () => void;
  rejectModel?:boolean;
  setRejectModel?:Function 
}
export const AcceptRejectBtns = ({ id,handleSubmit,rejectModel, setRejectModel, values}: Props) => {
  const [messageModel, SetMessageModel ] = useState(false)
  const [eventState, setEventState] = useState(false)
  const [statusMutation, {loading}] = useMutation(CHANGE_EVENT_STATUS);
  const { greenTick, redClose } = images;
  const eventStatusChange = (remark?:string) => {
    const addVariables = {
            offset: 1,
            limit: 5,
            eventStatus: EventStatus.PENDING,
            userId: getLocalInfo()?._id,
          }
    const addInput = eventState ? {} : {remark: remark}
    CallMutation(statusMutation, {
      variables: {
        ...addInput,
        value: eventState,
        eventId: id,
      },
      update(cache: ApolloCache<Function>, { data: APIResponse }: any) {
        const existingData: any = cache.readQuery({
          query: GET_ALL_EVENTS,
          variables: addVariables,
        });
        const filteredData = existingData?.getAllEvents?.nodes.filter(
          (item: any) => item._id !== APIResponse.changeEventStatus._id
        );
        cache.writeQuery({
          query: GET_ALL_EVENTS,
          variables: addVariables,
          data: { getAllEvents: { nodes: filteredData } },
        });
        NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS, `One Event is ${eventState ? "Accepted" : "Rejected"} `)
      },
    }, () => {
      setEventState(false)
      setRejectModel && setRejectModel(false)
      SetMessageModel(false)
    })
  };
  return (
    <>
      <WSButton
        label="Accept"
        variant={BtnVariant.BORDERED}
        className="list-button"
        leftIcon={greenTick}
        leftImageClass="mr-1"
        onClick={() => {
          setEventState(true)
          setRejectModel && setRejectModel(true)}}
      />
      <WSButton
        label=""
        variant={BtnVariant.BORDERED}
        className="list-cancel-button"
        leftIcon={redClose}
        width={11}
        height={11}
        leftImageClass="m-0"
        onClick={() => {
          setEventState(false)
          SetMessageModel(true)}}
      />
      <RejectMessageModel
        id={id} 
        showModal={messageModel}
        onCancel={()=> SetMessageModel(false)}
        handleSubmit={handleSubmit && handleSubmit}
        loading={loading}
        eventStatusChange={eventStatusChange}

      />
      <Confirmation
        showModal={rejectModel}
        onCancel={() => setRejectModel && setRejectModel(false)}
        onConfirm={() => {eventStatusChange && eventStatusChange(values?.remark)}}
        loading={loading}
      />
    </>
  );
};