import React, { useState } from "react";
import { withFormik } from "formik";
import { RejectMessageForm } from "pages/event/eventForms/CommonSubModals/SubModals";
import { AcceptRejectModelSchema } from "utils/formikValidation";
import { RejectMessageProps } from "./RejectMessageModel";
import { AcceptRejectBtns } from "./AcceptReject";

const RejectMessageFormikApp = withFormik<RejectMessageProps, RejectMessageForm>({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {
      remark:""
    };
  },
  validationSchema: AcceptRejectModelSchema,

  handleSubmit: (values, { props, }) => {
    const { setRejectModel } = props;
    setRejectModel && setRejectModel(true)
     },
})(AcceptRejectBtns);

const AddRejectMessageFormikWrapper = (props: RejectMessageProps) => {
  const [rejectModel, setRejectModel] = useState(false);
  return (
    <RejectMessageFormikApp
      setRejectModel={setRejectModel}
      {...props}
      rejectModel={rejectModel}
    />
  );
};

export default AddRejectMessageFormikWrapper