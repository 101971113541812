import React, { useState } from "react";
import { FieldArray } from "formik";
import { MutationFunction } from "@apollo/react-hooks";
// components
import { Loader, WSButton, WSInput } from "components/FormComponent";
import { FeaturesModalProps, VIPContentInitData } from "../FeaturesModal";
import { FeatureModal } from "pages/event/eventForms/CommonSubModals/FeaturesReuseableParts";
import {
  Name,
  WSDocument,
} from "pages/event/eventForms/CommonSubModals/ModalFields";
import { FEATURES_API, BtnVariant } from "constants/Enums";
import { WSSelect } from "components/common";
import Labels from "components/common/Labels/Labels";
import AvailSession from "./AvailSession";
// schema
import { GET_ALL_VIP_CONTENT } from "graphQl/Features/QuerySchema";
import { GET_SINGLE_VIP_CONTENT } from "graphQl/Features/SingleQuerySchema";

export interface VIPContentProps extends FeaturesModalProps {
  modalState?: number;
  showModal?: () => void;
  index?: number;
  vipContent?: MutationFunction;
  data?: Object;
  ids: { eventId: string; featureId: string };
  isEdit?: boolean;
  initData?: VIPContentInitData;
  setInitData?: Function;
  editData?: { getAllVipContentFeatures: { _id: string; name: string }[] };
}
const VIPContent = (props: VIPContentProps) => {
  const {
    modalState,
    showModal,
    index,
    loading,
    isEdit,
    values,
    ids,
    initData,
    setInitData,
    editData,
  } = props;

  // state
  const [editLoading, setEditLoading] = useState(false);
  // destructuring
  const { eventId } = ids;
  const { getAllVipContent, getSingleVipContent } = FEATURES_API;

  // modal close handling
  const onCancel = () => {
    showModal && showModal();
    setInitData &&
      setInitData(undefined);
  };
  return (
    <FeatureModal
      infoMessage="A brief paragraph to describe this item."
      showModal={modalState === index}
      onCancel={onCancel}
      title="VIP Content"
      loading={loading}
      modalClass="app-guide-modal modal-section"
    >
      <WSDocument
        schema={GET_ALL_VIP_CONTENT}
        singleQuerySchema={GET_SINGLE_VIP_CONTENT}
        queryName={getAllVipContent}
        singleQueryName={getSingleVipContent}
        eventId={eventId}
        initData={initData}
        setInitData={setInitData}
        setLoading={setEditLoading}
      />
      {editLoading ? (
        <Loader />
      ) : (
        <>
          <FieldArray
            name="vipContents"
            render={(data) => (
              <>
                {values?.vipContents?.map((vipContent, index: number) => {
                  console.log("vipContent?.availableSession",vipContent)
                  const queryName =
                    editData?.getAllVipContentFeatures?.find(
                      (item: { _id: string }) => item._id === vipContent?.select
                    )?.name || "";
                  return (
                    <>
                      <Name
                        name={`vipContents[${index}].name`}
                        label="Title Name"
                      />
                      <WSInput
                        name={`vipContents[${index}].overview`}
                        label="Overview"
                        inputClass="feature-input"
                      />
                      <WSSelect
                        name={`vipContents[${index}].select`}
                        label="Select"
                        optionList={editData?.getAllVipContentFeatures}
                        className="width-100 document-select"
                        disabled={isEdit}
                        onSelectItem={() =>
                          data.replace(index, {
                            ...vipContent,
                            availableSession: [],
                          })
                        }
                      />
                      {queryName && (
                        <>
                          <Labels
                            required={true}
                            label="Available Session"
                            labelClass=""
                          />
                          <AvailSession
                            values={vipContent?.availableSession}
                            name={`vipContents[${index}].availableSession`}
                            query={queryName}
                            eventId={eventId}
                          />
                        </>
                      )}
                      {index > 0 && (
                        <WSButton
                          variant={BtnVariant.BORDERED}
                          type="button"
                          className="custom-app-guide-model-remove-btn"
                          onClick={() => data.remove(index)}
                          label="Remove"
                        />
                      )}
                    </>
                  );
                })}
                {!initData?._id && (
                  <WSButton
                    variant={BtnVariant.BORDERED}
                    type="button"
                    className="custom-app-guide-add-more-btn"
                    onClick={() =>
                      data.push({
                        name: "",
                        overview: "",
                        select: "",
                        availableSession: [],
                      })
                    }
                    label="Add More"
                  />
                )}
              </>
            )}
          />
        </>
      )}
    </FeatureModal>
  );
};

export default VIPContent;
