import React, { FC, ReactNode } from "react";
import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip/"
import "./WStooltip.scss";

interface WSTollTipProps {
  title?: string | ReactNode;
}
const WSToolTip: FC<WSTollTipProps & TooltipProps > = ({ title, children, ...props }) => {
  return (
    <Tooltip {...props} title={title}>
      {children}
    </Tooltip>
  );
};

export default WSToolTip;
