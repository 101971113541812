import React, { ReactNode } from "react";
import { Popover } from "antd";
interface PopoverProps {
  text?: string | ReactNode;
  content?: string | ReactNode;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  trigger: any;
  className?: string;
  overlayClassName?:string
  icon?:string
  shape?: "circle" | "circle-outline" | "round" | undefined;
  label?:string
  rightIcon?:string
  leftIcon?: string
  btnClassName?: string
  rightImageClasses?:string
  leftImageClass?: string
  open?: boolean
  children?: React.ReactElement
  onOpenChange?: (e:any) => void
}
const WSPopover = ({
    text,
    content,
    placement,
    trigger,
    overlayClassName,
    open,
    children,
    onOpenChange,
  }: PopoverProps) => {
  return (
    <Popover
      content={content}
      placement={placement}
      title={text}
      trigger={trigger}
      overlayClassName={overlayClassName}
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
};
export default WSPopover;
