import React from 'react'
import { FieldArray } from 'formik'
// components
import { WSInput, WSButton } from 'components/FormComponent'
import { WSSelect } from 'components/common'
import { FeatureModalTwoCheckBox } from './FeaturesReuseableParts'
import { Divider } from 'antd'
// constants
import { optionList1, socialMediaList } from 'constants/DumyData'
import { BtnVariant, LINK_TYPE } from 'constants/Enums'
import { ExternalLinkProps } from 'constants/GlobalEventTypeScript'
// Style
import './AddExternalLinks.scss'

interface AddExternalLinkProps {
  values?: { externalLinks: ExternalLinkProps[] }
  attendeeSelect?: string[]
  checkBox?: boolean
  disabled?: boolean
}
const ExternalLink = ({ values, disabled }: AddExternalLinkProps) => {
  const { EMAIL, PHONE, WEBSITE } = LINK_TYPE

  return (
    <div className='modal-form'>
      <FieldArray
        name='externalLinks'
        render={(data) => (
          <>
            {values?.externalLinks?.map(
              (externalLink: ExternalLinkProps, index: number) => (
                <div key={`${externalLink?.linkType}${index}`}>
                  <div className='d-flex justify-content-between flex-wrap add-external-link-content'>
                    {index > 0 && <Divider plain>Add new link</Divider>}
                    <div className='selectBar link-type'>
                      <WSSelect
                        defaultValue={''}
                        optionList={optionList1}
                        listOptions='label'
                        passed='value'
                        name={`externalLinks[${index}].linkType`}
                        label='Link Type'
                        className=''
                        labelClass='mt-0'
                        disabled={disabled}
                      />
                    </div>
                    {externalLink?.linkType === WEBSITE && (
                      <div className='selectBar link-type social-media'>
                        <WSSelect
                          optionList={socialMediaList}
                          listOptions='label'
                          passed='value'
                          label='Social Media'
                          name={`externalLinks[${index}].socialMediaName`}
                          className=''
                          labelClass='mt-0'
                          disabled={disabled}
                        />
                      </div>
                    )}
                    {externalLink?.linkType && (
                      <WSInput
                        name={`externalLinks[${index}].linkName`}
                        label={
                          externalLink?.linkType !== WEBSITE
                            ? 'Name'
                            : 'Link Name'
                        }
                        inputClass='feature-input'
                        divClass='linkName'
                        labelClass='mt-0'
                      />
                    )}
                    {externalLink?.linkType === EMAIL ? (
                      <WSInput
                        name={`externalLinks[${index}].emailAddress`}
                        label='Email Address'
                        inputClass='feature-input'
                        divClass='email'
                        labelClass='mt-0'
                      />
                    ) : externalLink?.linkType === PHONE ? (
                      <WSInput
                        name={`externalLinks[${index}].phoneNumber`}
                        label='Phone Number'
                        inputClass='feature-input'
                        divClass='email'
                        labelClass='mt-0'
                      />
                    ) : externalLink?.linkType === WEBSITE ? (
                      <WSInput
                        type='url'
                        name={`externalLinks[${index}].websiteUrl`}
                        label='Website URL'
                        inputClass='feature-input website-url'
                        divClass='email'
                        labelClass='mt-0'
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {externalLink?.linkType === WEBSITE && (
                    <FeatureModalTwoCheckBox
                      name={`externalLinks[${index}].externalLinkSelect`}
                      values={externalLink?.externalLinkSelect || []}
                    />
                  )}
                  {index > 0 && (
                    <WSButton
                      type='button'
                      variant={BtnVariant.BORDERED}
                      label='Remove'
                      className='import-button cursor-pointer remove-button'
                      onClick={() => data.remove(index)}
                    />
                  )}
                </div>
              )
            )}
            <div className='add-new-btn'>
              <WSButton
                type='button'
                variant={BtnVariant.BORDERED}
                label='Add New Item'
                className='import-button cursor-pointer'
                onClick={() => data.push({ linkType: '' })}
              />
            </div>
          </>
        )}
      />
    </div>
  )
}

const AnnouncementExternalLink = ({ attendeeSelect, checkBox }: AddExternalLinkProps) => {
  return (
    <div className='modal-form'>
      <div className='d-flex justify-content-between flex-wrap'>
        <WSInput
          type='url'
          name='externalLink'
          label='External Link'
          inputClass='feature-input'
          labelClass='mt-0'
        />
      </div>
      {checkBox && <FeatureModalTwoCheckBox values={attendeeSelect} name='attendeeSelect' />}
    </div>
  )
}

export { ExternalLink, AnnouncementExternalLink }
