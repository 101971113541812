import gql from "graphql-tag";

const imgSchema = `image{
  url
}`
const fileSchema = `file{
  url
}`

const userSchemaFields = `
  name
  companyName
  designation{
    name
  }
  ${imgSchema}`

export const userSchema = `userId{
  ${userSchemaFields}
}`

export const senderSchema = `senderId{
  ${userSchemaFields}
}`

const REAL_TIME_QUESTIONS = gql`
  subscription fetchRealTimeQuestions($moduleId: String!){
    fetchRealTimeQuestions(moduleId: $moduleId){
      _id
      message
      likes
      comments {
        comment
        ${fileSchema}
        ${imgSchema}
        userId {
          name
          ${imgSchema}
        }
      }
      ${fileSchema}
      attachments{
        url
      }
      ${userSchema}
    }
  }
`;
const REAL_TIME_QUESTIONS_COMMENT = gql`
 subscription fetchQuestionComments($moduleId: String!) {
  fetchQuestionComments(moduleId: $moduleId) {
    _id
      message
      likes
      createdAt
      comments {
        comment
        ${fileSchema}
        ${imgSchema}
        userId {
          name
          ${imgSchema}
        }
      }
      ${fileSchema}
      attachments{
        url
      }
      ${userSchema}
    }
}
`;

const REAL_TIME_NOTIFICATION = gql`
  subscription fetchRealTimeNotification($userType: String!, $userId: String!){
    fetchRealTimeNotification(userType: $userType, userId: $userId){
      _id
      ${senderSchema}
      message
    }
  }
`;

export {
  REAL_TIME_QUESTIONS,
  REAL_TIME_NOTIFICATION,
  REAL_TIME_QUESTIONS_COMMENT
}