import React from 'react'
import clsx from 'clsx'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
// component
import { WSButton } from "components/FormComponent";
import WSToolTip from './WSTooltip/WSTooltip'
import WSCheckbox from "components/FormComponent/WSCheckbox";
// constant
import images from 'constants/Images'
import RouterPath from 'constants/RouterPath'
import { BtnVariant } from 'constants/Enums'

interface Props {
  hoverable?: boolean
  bordered?: boolean
  style?: any
  cover?: any
  title: string
  description?: string
  className?: string
  children?: React.ReactNode
  onClick?: () => void
  checkBox?: boolean
  inlineClassName?: string
  select?: boolean
  setCard?: string[]
  message?: string
  bannerButton?: string
  actions?: React.ReactNode[]
}

const CardComponent = (props: Props) => {
  const {
    onClick,
    hoverable = false,
    bordered = true,
    style,
    cover,
    actions,
    title,
    bannerButton,
    description,
    className,
    children,
    checkBox,
    inlineClassName,
    select,
    setCard,
    message,
  } = props

  return (
    <div className={className}>
      <Card
        actions={actions}
        className={clsx('card', { 'card-change': select })}
        hoverable={hoverable}
        bordered={bordered}
        style={style}
        onClick={onClick}
      >
        {message && (
          <WSToolTip title={message}>
            <span className='info-btn font-bold border-red-50'>i</span>
          </WSToolTip>
        )}
        {cover && (
          <div
            className={clsx(
              `d-flex justify-content-center align-item-center ${inlineClassName}`,
              { 'event-type': select }
            )}
          >
            <span className='d-flex justify-content-center align-item-center border-red-50'>
              {cover}
            </span>
          </div>
        )}
        {checkBox && (
          <div className={clsx('check-box', { select: select })}>
            <WSCheckbox
              labelArr={[title]}
              value={setCard}
              className='privacy-checkbox'
              name='selectCard'
            />
          </div>
        )}
        <Card.Meta
          className={clsx('meta font-bold', { checked: select })}
          title={title}
        ></Card.Meta>
        <p> {description} </p>

        {children}
        {/* for future use */}
        {/* {checkBox && (
          <Link to={RouterPath.homeScreen}>
            <WSButton
              onClick={() => window.open('https://google.com')}
              variant={BtnVariant.LINK}
              className={clsx('learn-more', { link: select })}
              label='Learn More'
              rightIcon={
                select ? images.nextArrowWhite : images.nextArrowOrange
              }
              width='13px'
              height='15px'
            />
          </Link>
        )} */}
      </Card>
    </div>
  )
}

export default CardComponent
