import React, { ReactNode, useEffect, useState } from "react";
import { List } from "antd";
import { LikeOutlined } from "@ant-design/icons";
// constants
import {
  ImageProps,
  PaginationProps,
  QuestionDataProps,
} from "constants/GlobalEventTypeScript";
import { DATE_FORMATS } from "constants/Enums";
import images from "constants/Images";
import { isoToFormat } from "utils/helper";
// components
import { WSImage } from "../FormComponent";
import { UserWithThumbnail } from "./UserWithThumbnail";
import AcceptRejectBtns from 'components/common/AcceptRejectbtns/RejectMessageHandler';
import CommentHandler from "pages/event/eventForms/HelpDesk/CommentHandler";
import { GrayDesc } from "./WSTitle";
interface ListItemProps {
  image?: ImageProps;
  name?: string;
  description?: string;
  descJSX?: ReactNode;
  postContent?: QuestionDataProps;
  _id?: string;
}
interface ListProps {
  rightContent?: boolean;
  dataSource: Array<ListItemProps>;
  className?: string;
  footer?: ReactNode;
  itemLayout?: "horizontal" | "vertical";
  actions?: ReactNode[];
  extra?: ReactNode;
  likeComment?: boolean;
  viewDetails?: boolean;
  loading?: boolean;
  noDescription?: boolean;
  livePolls?: boolean;
  likeMutation?: Function;
  eventId?: string;
  onPageChange?: (e: number) => void;
  pagination?: PaginationProps;
  onClick?: () => void
  setQuestionIdHandle?: (_id: string) => void
  commentVariables?: { offset: number, limit: number, moduleId: string }
}

const WSList = (props: ListProps) => {
  const {
    dataSource,
    footer,
    rightContent,
    className,
    itemLayout = "horizontal",
    actions,
    extra,
    loading,
    viewDetails,
    noDescription,
    livePolls = false,
    likeComment,
    likeMutation,
    setQuestionIdHandle,
    eventId,
    onPageChange,
    pagination,
    onClick,
    commentVariables,
  } = props;
  // state
  const [commentId, setCommentId] = useState<string[]>([])
  // comment toggle
  useEffect(() => {
    if (commentId?.length === 2) {
      commentId[0] === commentId[1] && setCommentId([])
      commentId[0] !== commentId[1] && setCommentId([commentId[1]])
    }
  }, [commentId])
  const { MMM_D_YYYY } = DATE_FORMATS
  return (
    <List
      className={`list-ul ${className}`}
      itemLayout={itemLayout}
      dataSource={dataSource}
      footer={footer}
      pagination={pagination && {
        onChange: onPageChange,
        ...pagination,
      }}
      loading={loading}
      renderItem={(item) => {
        const { postContent, _id } = item;
        const { comment } = images;
        return (
          <List.Item className="list-li" actions={actions} extra={extra}>
            <div className="d-flex justify-content-between width-100">
              <UserWithThumbnail
                noDescription={noDescription}
                data={item}
                onClick={onClick}
                spanClass="table-notification-list d-flex align-item-center help-desk-list"
                divClass="notification-title-desc"
                viewDetails={viewDetails}
              />
              {postContent?.createdAt && (
                <p className="helpDesk-date">
                  {isoToFormat(postContent?.createdAt || "", MMM_D_YYYY)}
                </p>
              )}
            </div>
            {postContent && (
              <div className="item-content">
                <p className="post-message">{postContent.message}</p>
                {postContent?.image && (
                  <WSImage
                    imagesPath={postContent?.image?.url}
                    inlineClass="post-image"
                    width="100%"
                    height={500}
                  />
                )}
                {postContent?.attachments?.length ? (
                  <GrayDesc
                    inlineClassName="cursor-pointer"
                    name="Click here to check file"
                    onClick={() =>
                      window.open(
                        postContent?.attachments &&
                        postContent?.attachments[0]?.url
                      )
                    }
                  />
                ) : null}
                {postContent?.file && (
                  <GrayDesc
                    inlineClassName="cursor-pointer"
                    name="Click here to check file"
                    onClick={() => window.open(postContent?.file?.url)}
                  />
                )}
                {Number(postContent?.likes) > 0 && (
                  <p className="likes small-gray-text">
                    {postContent.likes} Likes
                  </p>
                )}
                {likeComment && (
                  <div className="like-comment">
                    <span
                      className={`${postContent.isLiked && "liked"} helpDesk-action-like cursor-pointer`}
                      onClick={() => likeMutation && likeMutation(_id)}
                    >
                      <LikeOutlined />
                      {postContent.isLiked ? "Liked" : "Like"}
                    </span>
                    <span
                      className="helpDesk-action-comment cursor-pointer"
                      onClick={() => { if (_id) { setQuestionIdHandle && setQuestionIdHandle(_id); setCommentId([...commentId, _id]) } }}
                    >
                      <WSImage imagesPath={comment} />
                      Comment
                    </span>
                  </div>
                )}
                {_id && commentId.includes(_id) && (
                  <CommentHandler
                    commentVariables={commentVariables}
                    comments={postContent?.comments}
                    livePolls={livePolls}
                    id={_id}
                    eventId={eventId}
                  />
                )}
              </div>
            )
            }
            {rightContent && (
              <div className="d-flex align-item-center justify-content-between pr-20">
                <AcceptRejectBtns id={_id || ""} />
              </div>
            )}
          </List.Item >
        );
      }}
    />
  );
};

export default WSList;
