import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
// import { useHistory } from 'react-router-dom';
import { useMutation, MutationFunction, useQuery } from '@apollo/react-hooks';
import { CSVLink } from 'react-csv';
import moment from 'moment';
// components
import {
  FeaturesModalForm,
  FeaturesModalProps,
} from 'pages/features/FeaturesModal';
import { FeatureModal } from '../CommonSubModals/FeaturesReuseableParts';
import { WSImage, WSInput, WSTextEditor } from 'components/FormComponent';
import WSSwitch from 'components/FormComponent/WSSwitch';
import Labels from 'components/common/Labels/Labels';
import {
  Name,
  ChooseProvider,
} from 'pages/event/eventForms/CommonSubModals/ModalFields';

import Attachments from '../CommonSubModals/Attachments';
import { WSCollapse } from 'components/common';
import { CallMutation } from 'graphQl/graphqlHandler';
// constants
import { addMedia } from 'constants/GlobalEventTypeScript';
import { liveStreamTip } from 'utils/ToolTips';
import {
  DATE_FORMATS,
  FEATURES_NAME,
  MEDIA_NAME,
  NOTIFICATION_STATUS,
} from 'constants/Enums';
import NotificationWithIcon from 'constants/Toster';
import { NEW_ITEM_CREATED, NEW_ITEM_UPDATED } from 'utils/NotificationMessages';
import images from 'constants/Images';
import { checkIsAllowQaUndefined } from 'utils/helper';
import { LiveStreamSchema } from 'utils/formikValidation';
// schema
import { GET_ALL_LIVE_STREAM } from 'graphQl/Features/QuerySchema';
import { LIVE_STREAM_MUTATION } from 'graphQl/Features/MutationSchema';
import { UPDATE_LIVE_STREAM } from 'graphQl/Features/UpdateMutation';
import { GET_QUESTION_FOR_DOWNLOAD } from 'graphQl/Events/QuerySchema';
import {
  getQuestionsForDownloadDataProps,
  replyProps,
} from 'pages/event/eventForms/LivePolls/AddLivePolls';
import { useLocation } from 'react-router-dom';

interface LiveStreamProps extends FeaturesModalProps {
  showModal?: boolean;
  onCancel?: () => void;
  liveStreamMutation?: MutationFunction;
  data?: Object;
  eventIDFromHistory: any;
  ids: { eventId: string; featureId: string };
  history: {  state: { eventId: string }  };
  initData?: any;
  isEdit?: boolean;
  isLoading?: boolean;
}

const LiveStream = (props: LiveStreamProps) => {
  const {
    showModal,
    onCancel,
    loading,
    isLoading,
    isEdit,
    values,
    setFieldValue,
  } = props;
  // const history = useHistory();
  const location = useLocation()
  const [pollDownloadData, setPollDownloadData] = useState<any>();
  const moduleId = props?.initData?._id || '';
  // answers
  const { data: pollAnswer, loading: pollAnswerLoading } = useQuery(
    GET_QUESTION_FOR_DOWNLOAD,
    {
      fetchPolicy: 'network-only',
      variables: { moduleId: moduleId },
      skip: !moduleId,
    }
  );
  useEffect(() => {
    const values: any = [];
    pollAnswer?.getQuestionsForDownload?.map(
      (data: getQuestionsForDownloadDataProps, dataIndex: number) => {
        values.push({
          no: dataIndex + 1,
          question: data.question,
          reply: null,
          userName: data.userName,
          time: moment(data.questionDate).format(
            DATE_FORMATS.MMM_DD_YYYY_hh_mm_A
          ),
          likes: data.likesCount,
        });
        data?.replies?.map((replyData: replyProps) => {
          values.push({
            no: null,
            question: null,
            reply: replyData.reply,
            userName: replyData.userName,
            time: moment(replyData.replyDate).format(
              DATE_FORMATS.MMM_DD_YYYY_hh_mm_A
            ),
            likes: null,
          });
        });
        values.push({
          no: null,
          question: null,
          reply: null,
          userName: null,
          time: null,
          likes: null,
        });
      }
    );

    setPollDownloadData(values);
  }, [pollAnswer?.getQuestionsForDownload]);

  const AttachmentData = {
    panelHeader: <span className='collapse-title-info'>Attachments </span>,
    insideForm: <Attachments values={values} setFieldValue={setFieldValue} />,
    key: 4,
  };

  return (
    <FeatureModal
      infoMessage={liveStreamTip}
      showModal={showModal}
      onCancel={onCancel}
      title={FEATURES_NAME.MAIN_SESSION}
      loading={loading}
      isLoading={isLoading}
      isEdit={isEdit}
    >
      <Name />
      <br />
      <ChooseProvider
        notFoundContent={<div>Add stream from stream option feature.</div>}
        name='provider'
        eventId={location?.state?.eventId}
        disabled={isEdit}
      />
      <WSInput
        type='url'
        name='meetingUrl'
        label='Meeting URL'
        required
        inputClass='feature-input'
        divClass='email'
      />
      <WSTextEditor name='description' disabled={isEdit} />
      <div>
        <Labels label={'Show Q&A:'} />
        <WSSwitch
          size='small'
          checked={checkIsAllowQaUndefined(values?.isAllowQa)}
          onChange={(e) => setFieldValue && setFieldValue('isAllowQa', e)}
        />
      </div>
      <div className='d-flex justify-content-end'>
        <CSVLink
          filename={`mainsession_qa.csv`}
          data={pollDownloadData || []}
          headers={[
            { label: 'Number', key: 'no' },
            { label: 'Question', key: 'question' },
            { label: 'Reply', key: 'reply' },
            { label: 'User Name', key: 'userName' },
            { label: 'Time', key: 'time' },
            { label: 'Likes', key: 'likes' },
          ]}
          className='download-btn'
          hidden={!moduleId || pollAnswerLoading}
        >
          <div className='mt-5'>
            Download QA{' '}
            <WSImage imagesPath={images.download} height={40} width={40} />
          </div>
        </CSVLink>
      </div>
      <div className='collapse-container'>
        <WSCollapse
          iconPosition='right'
          panelData={AttachmentData}
          className='upload-file'
        />
      </div>
    </FeatureModal>
  );
};

const LiveStreamFormikApp = withFormik<LiveStreamProps, FeaturesModalForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ initData }) => {
    return {
      name: initData?.title || '',
      meetingUrl: initData?.meetingUrl || '',
      provider: initData?.provider?._id || '',
      description: initData?.description || '',
      isAllowQa: initData?.isAllowQa,
      attachments: (initData && initData?.attachments) || [],
    };
  },
  validationSchema: LiveStreamSchema,

  handleSubmit: async (
    {
      provider,
      meetingUrl,
      name,
      description,
      isAllowQa,
      attachments,
      removeMedia,
    },
    { props, resetForm }
  ) => {
    const { history, liveStreamMutation, initData } = props;
    const { eventId } = history.state;
    const id = initData?._id ? { _id: initData?._id } : { eventId: eventId };
    await CallMutation(liveStreamMutation, {
      variables: {
        input: {
          ...id,
          title: name,
          provider: provider,
          meetingUrl: meetingUrl,
          description: description,
          isAllowQa: isAllowQa,
          addMedia: attachments?.map((data) =>
            addMedia(data, MEDIA_NAME.LIVE_STREAM_ATTACHMENTS)
          ),
          removeMedia,
        },
      },
      resetForm,
    });
  },
})(LiveStream);

const LiveStreamFormikWrapper = (props: LiveStreamProps) => {
  const { initData, history, onCancel } = props;
  const { eventId } = history.state;
  const schema = initData?._id ? UPDATE_LIVE_STREAM : LIVE_STREAM_MUTATION;
  const [liveStreamMutation, { data, loading }] = useMutation(schema, {
    refetchQueries: [
      {
        query: GET_ALL_LIVE_STREAM,
        variables: { offset: 1, limit: 10, eventId },
      },
    ],
    onCompleted: () => {
      NotificationWithIcon(
        NOTIFICATION_STATUS.SUCCESS,
        initData?._id
          ? NEW_ITEM_UPDATED(FEATURES_NAME.MAIN_SESSION)
          : NEW_ITEM_CREATED(FEATURES_NAME.MAIN_SESSION)
      );
      onCancel && onCancel();
    },
  });

  return (
    <LiveStreamFormikApp
      liveStreamMutation={liveStreamMutation}
      data={data}
      loading={loading}
      {...props}
    />
  );
};

export default LiveStreamFormikWrapper;
