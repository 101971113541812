import React, { useEffect, useState } from "react";
// library
import { SearchOutlined } from "@ant-design/icons";
import { Badge, Row, Col } from "antd";
import { NavLink, 
   useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
// component
import { WSImage, WSButton } from "components/FormComponent";
import {
  SmallBrownText,
  MainTitle,
  GrayDesc,
} from "components/common/WSTitle";
import WSPopover from "components/common/WSPopover";
import WSList from "components/common/WSList";
import WSInputSearch from "components/FormComponent/WSInputSearch";
import { NotificationsData } from "pages/notifications";
// constant
import images from "constants/Images";
import {
  localStorageClear,
  getLocalInfo,
  getLocalStorageItem,
} from "utils/helper";
import {
  TopNavMenu,
  BtnVariant,
  USER_ROLE,
  ManagerTopNavMenu,
  LOCAL_STORAGE_KEY,
  API_NAME,
} from "constants/Enums";
import RouterPath from "constants/RouterPath";
// import { History } from "history";
// schema
import { GET_ALL_NOTIFICATIONS } from "graphQl/Home/Schema";
import { REAL_TIME_NOTIFICATION } from "graphQl/Features/Subscription";
//style
import "./TopNav.scss";
interface NavTypes {
  name: string;
  link: string;
  exact: boolean;
}

const TopNav = () => {
  // const history = useHistory();
  const navigate = useNavigate()
  const primary_color = "#00253D"
  const [notificationState, setNotificationState] = useState<boolean>(false);
  const [userLogoState, setUserLogoState] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationsData[]>([]);

  const { getAllNotifications } = API_NAME;
  const { USER_INFO, USER_ROLE:local_role } = LOCAL_STORAGE_KEY
  const userInfo = getLocalInfo(USER_INFO)
  const userRole = getLocalStorageItem(local_role)
  const { notifications: NotificationPath } = RouterPath
  const { data: subscriptionData } = useSubscription(REAL_TIME_NOTIFICATION, {variables: {userType: userRole || "", userId: userInfo?._id || ""}});
  const {
    subscribeToMore,
    data: getNotifications,
    loading,
  } = useQuery(GET_ALL_NOTIFICATIONS, { variables: { offset: 1, limit: 10 } });
  const selectMenu =
    getLocalStorageItem(local_role) === USER_ROLE.ADMIN
      ? TopNavMenu
      : ManagerTopNavMenu
  useEffect(() => {
    const finalData = getNotifications?.[getAllNotifications]?.nodes?.filter(
      ({ isRead }: NotificationsData) => !isRead
    );
    setNotifications(finalData || []);
  }, [getNotifications]);
  useEffect(() => {
    subscribeToMore({
      document: REAL_TIME_NOTIFICATION,
      variables: {userType: userRole || "", userId: userInfo?._id || ""},
      updateQuery: (preData, { subscriptionData }) => {
        if (!subscriptionData.data) return preData;
        const newDataToAdd = subscriptionData.data.fetchRealTimeNotification;
        return Object.assign({}, preData, {
          [getAllNotifications]: {
            nodes: [newDataToAdd, ...preData?.[getAllNotifications]?.nodes],
          },
        });
      },
    });
  }, [subscriptionData?.fetchRealTimeNotification]);
  const finalData = notifications?.map(({ message, senderId, _id }) => {
    return { name: senderId?.name, image: senderId?.image, desc: message, _id };
  });
  const notificationsTitle = () => {
    return (
      <div className="d-flex align-item-center justify-content-between noti-hover">
        <MainTitle name="Notifications" />
        <div className="d-flex align-item-center">
          <WSButton
            variant={BtnVariant.LINK}
            label="View All"
            onClick={() => navigate(NotificationPath)}
            inlineClass="text-transform-upper"
          />
          <WSImage imagesPath={images.nextArrowOrange} width={13} height={10} />
        </div>
      </div>
    );
  };
  const NotificationPageRedirect = (id?: string) => {
    navigate(NotificationPath, {state:{id}});
  }
  const popoverNotificationsList = () => {
    return (
      <div
        className="notification-list cursor-pointer"
      >
        <WSList
          onClick={NotificationPageRedirect}
          loading={loading}
          dataSource={finalData.slice(0, 2)}
          className="list-ul-notification p-0 pl-0"
        />
      </div>
    );
  };

  return (
    <Row className="header-logo header-bg" align="middle">
      <Col xs={3} sm={4} md={4} lg={4} xl={4} xxl={3}>
        <div
          className="d-flex align-item-center cursor-pointer"
          onClick={() => navigate(RouterPath.homeScreen)}
        >
          <WSImage
            imagesPath={images.headerLogo}
            width={60}
            height={63}
            inlineClass="company-logo"
          />
          <SmallBrownText name="Platform" inlineClassName="pl-09" />
        </div>
      </Col>
      <Col
        xs={11}
        sm={8}
        md={8}
        lg={9}
        xl={8}
        xxl={10}
        className="center-content"
      >
        <div className="d-flex align-item-center header-links">
          {selectMenu.map((nav: NavTypes, index: number) => {
            return (
              <div key={index}>
                <NavLink
                  // exact={nav.exact}
                  to={nav.link}
                  style={{
                    fontWeight: "bold",
                    color: primary_color,
                    opacity: 10,
                  }}
                  className="pr-20"
                >
                  {nav.name}
                </NavLink>
              </div>
            );
          })}
        </div>
      </Col>
      <Col xs={3} sm={7} md={6} lg={6} xl={7} xxl={7}>
        <div className="width-100">
          <WSInputSearch
            name="search"
            icon={<SearchOutlined />}
            inputClass="nav-textbox"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(
                  `${RouterPath.searchScreen}?searchTerm=${e.currentTarget.value}`
                );
              }
            }}
          />
        </div>
      </Col>
      <Col xs={3} sm={4} md={6} lg={5} xl={5} xxl={4}>
        <div className="d-flex align-item-center top-nav-btn">
          <Badge dot={finalData?.length ? true : false}>
            <WSPopover
              trigger="click"
              content={popoverNotificationsList()}
              overlayClassName="topnav-popover"
              placement="bottomRight"
              open={notificationState}
              text={notificationsTitle()}
              onOpenChange={() => setNotificationState(!notificationState)}
            >
              <div className="notification-round">
                <WSImage imagesPath={images.buttonNotification} width={53} height={53} />
              </div>
            </WSPopover>
          </Badge>
          <Badge>
            <div
              className="setting-icon"
              onClick={() => navigate(RouterPath.settings)}
            >
              <WSImage imagesPath={images.settingIcon} />
            </div>
          </Badge>
          <div className="button-profile-icon">
            <WSPopover
              trigger="click"
              content={popoverProfile(navigate)}
              open={userLogoState}
              placement="bottomRight"
              overlayClassName="topnav-popover"
              onOpenChange={() => setUserLogoState(!userLogoState)}
            >
              <WSImage
                imagesPath={getLocalInfo()?.image?.url || images.defaultUser}
                width={53}
                height={53}
                inlineClass="border-red-50"
              />
            </WSPopover>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const popoverProfile = (navigate:any) => {
  const userInfo = getLocalInfo();
  return (
    <div className="d-flex align-item-center flex-direction-column pt-24">
      <WSImage
        imagesPath={userInfo?.image?.url || images.defaultUser}
        width={100}
        height={100}
      />
      <MainTitle name={userInfo.name || ""} inlineClassName="profile-title" />
      <GrayDesc
        name={userInfo.email || ""}
        inlineClassName="profile-sub-title"
      />
      <WSButton
        variant={BtnVariant.ROUND_GRADIENT}
        onClick={() => navigate(RouterPath.viewProfile)}
        label="View Profile"
        className="mb-1"
        inlineClass="white-text"
      />
      <WSButton
        onClick={() => {
          localStorageClear();
          window.location.reload();
        }}
        label="Logout"
        variant={BtnVariant.LINK}
        className="width-100 gray-bg"
        inlineClass="primary-color font-size-16"
      />
    </div>
  );
};

export default TopNav;
