import gql from "graphql-tag";
import { locationSchema } from "constants/GlobalEventTypeScript";
import { imageSchema } from "../CommonSchemas";

const NETWORKING_MUTATION = gql`
  mutation createFeatureNetworking($input: CreateFeatureNetworkingInput!) {
    createFeatureNetworking(input: $input) {
      _id
      name
      subTitle
      designation {
        _id
        name
      }
      department {
        _id
        name
      }
      location
    }
  }
`;


const LIVE_STREAM_MUTATION = gql`
  mutation createFeatureLiveStream($input: FeatureLiveStreamInput!) {
    createFeatureLiveStream(input: $input) {
      _id
    }
  }
`;



const BREAKOUT_SESSION_MUTATION = gql`
  mutation createBreakoutSession($input: CreateBreakoutSessionInput!) {
    createBreakoutSession(input: $input) {
      _id
    }
  }
`;

const SPONSORS_MUTATION = gql`
  mutation createFeatureSponsor($input: CreateFeatureSponsorsInput!) {
    createFeatureSponsor(input: $input) {
      _id
      name
      description
      attendeeCalls {
        followUpLabel
        followUpContactEmail
        scheduleButtonLabel
        scheduleUrl
        openLinkOnExternalBrowser
        passDataToUrl
      }
      image {
        key
        url
      }
    }
  }
`;

const TRACKS_MUTATION = gql`
  mutation createTrack($input: CreateTrackInput!) {
    createTrack(input: $input) {
      name
      shortName
      color
    }
  }
`;

const CATEGORY_MUTATION = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      createdAt
      updatedAt
      _id
      name
    }
  }
`;

const SCHEDULE_MUTATION = gql`
  mutation createSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      _id
      name
      description
      sessionDate
      sessionTime
      location
    }
  }
`;

const CREATE_UPDATE_BANNER = gql`
  mutation manageAdminBanner($input: BannerInput!) {
    manageAdminBanner(input: $input) {
      createdAt
      updatedAt
      _id
       ${imageSchema}
    }
  }
`;

const CREATE_ATTENDEE = gql`
  mutation createFeatureAttendees($input: CreateAttendeesInput!) {
    createFeatureAttendees(input: $input) {
      _id
      name
      email
      subTitle
      designation{
        _id
        name
      }
      ${imageSchema}
    }
  }
`;

const ANNOUNCEMENTS_MUTATION = gql`
  mutation createFeatureAnnouncement($input: CreateFeatureAnnouncementInput!) {
    createFeatureAnnouncement(input: $input) {
      _id
      from
      to {
        _id
        name
      }
      message
      link
      send
      scheduleDateTime
    }
  }
`;

const PRESENTER_MUTATION = gql`
  mutation createPresenter($input: CreatePresenterInput!) {
    createPresenter(input: $input) {
      _id
      name
      designation {
        _id
        name
      }
    }
  }
`;
const RECORDING_MUTATION = gql`
  mutation createFeatureRecording($input: CreateFeatureRecordingInput!) {
    createFeatureRecording(input: $input) {
      _id
      name
      link
      attachments {
        key
        mediaType
        mediaName
        type
        url
      }
    }
  }
`;

const SLIDES_MUTATION = gql`
  mutation createFeatureSlide($input: CreateFeatureSlideInput!) {
    createFeatureSlide(input: $input) {
      _id
      name
      documentType
      attachments {
        key
        mediaType
        mediaName
        type
        url
      }
    }
  }
`;

const VIRTUAL_EXPO_MUTATION = gql`
  mutation createVirtualExpo($input: VirtualExpoInput!) {
    createVirtualExpo(input: $input) {
      _id
    }
  }
`;

const CREATE_POLL_MUTATION = gql`
  mutation createPoll($input: CreatePollInput!) {
    createPoll(input: $input) {
      _id
      questions {
        _id
        question
        answerOptions
      }
    }
  }
`;

const CREATE_SURVEY_MUTATION = gql`
  mutation createSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      _id
      questions {
        _id
        question
        answerType
      }
    }
  }
`;

const ADD_POLLS_MUTATION = gql`
  mutation createPoll($input: CreatePollInput!) {
    createPoll(input: $input) {
      _id
      questions {
        _id
        question
        answerType
      }
    }
  }
`;

const APP_GUIDE_MUTATION = gql`
  mutation createFeatureAppGuide($input: CreateAppguideInput!) {
    createFeatureAppGuide(input: $input) {
      _id
      name
    }
  }
`;
const VIP_CONTENT_MUTATION = gql`
  mutation createVipContent($input: CreateVipContentInput!) {
    createVipContent(input: $input) {
      _id
      name
    }
  }
`;

const STREAM_OPTIONS_MUTATION = gql`
  mutation createStreamOption($input: CreateStreamOptionInput!) {
    createStreamOption(input: $input) {
      _id
      name {
        _id
        name
      }
      description
      costing
      subTitle
      externalLinks {
        linkType
        linkName
        websiteUrl
        openlinkExternalBrowser
        passdataToUrl
      }
    }
  }
`;

const BOOK_MEETINGS_MUTATION = gql`
  mutation createBookingMeeting($input: CreateBookingMeetingInput!) {
    createBookingMeeting(input: $input) {
      _id
      name
      subTitle
      category {
        _id
        name
      }
      description
              ${locationSchema}
      image {
        _id
        key
        url
      }
    }
  }
`;

const SCHEDULE1V1_MUTATION = gql`
  mutation createScheduleV1($input: CreateScheduleV1Input!) {
    createScheduleV1(input: $input) {
      _id
      name
      subTitle
      image {
        _id
        mediaType
        key
        isDeleted
        url
      }
    }
  }
`;

const CREATE_AMA_SESSION = gql`
  mutation createFeatureAmaSessions($input: FeatureAmaSessionInput!) {
    createFeatureAmaSessions(input: $input) {
      _id
    }
  }
`;

const CREATE_HELP_DESK = gql`
  mutation createHelpDesk($input: HelpDeskInput!) {
    createHelpDesk(input: $input) {
      _id
      message
      userId {
        name
        designation {
          _id
          name
        }
        companyName
        image{
          url
        }
      }
      image {
        url
      }
      file {
        url
      }
    }
  }
`;

const CREATE_COMMENT = gql`
  mutation createComment(
    $removeMedia: [String!]
    $addMedia: [MediaInput!]
    $comment: String!
    $eventId: String!
    $id: String!
  ) {
    createComment(
      removeMedia: $removeMedia
      addMedia: $addMedia
      comment: $comment
      eventId: $eventId
      id: $id
    ) {
      _id
      comments {
        comment
        userId {
          name
          image {
            url
          }
        }
        image{
          url
        }
        file{
          url
        }
      }
    }
  }
`;
const ADD_OR_REMOVE_QUESTION_LIKE = gql`
  mutation addOrRemoveQuestionLike($id: String!) {
    addOrRemoveQuestionLike(id: $id) {
      _id
      likes
      isLiked
    }
  }
`;

export {
  CREATE_UPDATE_BANNER,
  TRACKS_MUTATION,
  CATEGORY_MUTATION,
  NETWORKING_MUTATION,
  LIVE_STREAM_MUTATION,
  BREAKOUT_SESSION_MUTATION,
  SPONSORS_MUTATION,
  SCHEDULE_MUTATION,
  CREATE_ATTENDEE,
  ANNOUNCEMENTS_MUTATION,
  PRESENTER_MUTATION,
  RECORDING_MUTATION,
  SLIDES_MUTATION,
  VIRTUAL_EXPO_MUTATION,
  CREATE_SURVEY_MUTATION,
  CREATE_POLL_MUTATION,
  ADD_POLLS_MUTATION,
  APP_GUIDE_MUTATION,
  VIP_CONTENT_MUTATION,
  STREAM_OPTIONS_MUTATION,
  BOOK_MEETINGS_MUTATION,
  SCHEDULE1V1_MUTATION,
  CREATE_AMA_SESSION,
  CREATE_HELP_DESK,
  CREATE_COMMENT,
  ADD_OR_REMOVE_QUESTION_LIKE,
};
