import gql from "graphql-tag";
import { eventSettingSchema } from "../CommonSchemas";

const UPDATE_NETWORKING = gql`
  mutation updateFeatureNetworking($input: UpdateFeatureNetworkingInput!) {
    updateFeatureNetworking(input: $input) {
       _id
      name
      subTitle
      overview
      designation {
        _id
        name
      }
      department {
        _id
        name
      }
      image{
        _id
        url
        key
        type
        mediaType
        mediaName
      }
      location
    }
  }
`;

const UPDATE_ANNOUNCEMENT = gql`
  mutation updateFeatureAnnouncement($input: UpdateFeatureAnnouncementInput!) {
    updateFeatureAnnouncement(input: $input) {
      _id
      from
      to {
        _id
        name
      }
      message
      link
      send
      scheduleDateTime
    }
  }
`;

const UPDATE_BOOK_MEETING = gql`
  mutation updateBookingMeeting($input: UpdateBookingMeetingInput!) {
    updateBookingMeeting(input: $input) {
      _id
      name
      subTitle
      category {
        _id
        name
      }
      description
      location
      image {
        _id
        key
        url
      }
    }
  }
`;

const UPDATE_PRESENTER = gql`
  mutation updatePresenter($input: UpdatePresenterInput!) {
    updatePresenter(input: $input) {
      _id
      name
      overview
      designation {
        _id
        name
      }
    }
  }
`;

const UPDATE_SCHEDULE = gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      _id
      name
      description
      sessionDate
      sessionTime
      isAllowQa
      internalLinkUrl  {
        _id
      }
      attachments {
        _id
        key
        mediaType
        isDeleted
        url
      }
    }
  }
`;

const UPDATE_ATTENDEE = gql`
  mutation updateFeatureAttendees($input: UpdateAttendeesInput!){
    updateFeatureAttendees(input: $input){
      _id
      name
      email
      subTitle
      designation{
        _id
        name
      }
      image{
        url
        mediaType
        mediaName
        type
        key
      }
    }
  }
`

const UPDATE_RECORDING = gql`
  mutation updateFeatureRecording($input: UpdateFeatureRecordingInput!) {
    updateFeatureRecording(input: $input) {
      _id
      name
      link
    }
  }
`;

const UPDATE_SLIDE = gql`
  mutation updateFeatureSlide($input: UpdateFeatureSlideInput!) {
    updateFeatureSlide(input: $input) {
       _id
      name
      documentType
    }
  }
`;

const UPDATE_SPONSOR = gql`
  mutation updateFeatureSponsor($input: UpdateFeatureSponsorsInput!) {
    updateFeatureSponsor(input: $input) {
      _id
      name
    }
  }
`;

const UPDATE_STREAM_OPTION = gql`
  mutation updateStreamOption($input: UpdateStreamOptionInput!) {
    updateStreamOption(input: $input) {
      _id
      name {
          _id
          name
        }
    }
  }
`;

const UPDATE_AMA_SESSION = gql`
  mutation updateFeatureAmaSessions($input: UpdateFeatureAmaSessionInput!) {
    updateFeatureAmaSessions(input: $input) {
      _id
    }
  }
`;

const UPDATE_SURVEY = gql`
  mutation updateSurvey($input: UpdateSurveyInput!) {
    updateSurvey(input: $input){
      _id
      questions {
      _id
      question
      answerType
      icon {
        url
      }
      }
    }
  }`

const UPDATE_POLLS = gql`
  mutation updatePoll($input: UpdatePollInput!) {
    updatePoll(input: $input){
      _id
      isPollClosed
      questions {
        _id
        question
        answerOptions
      }
    }
  }`


const UPDATE_VIP_CONTENT = gql`
  mutation updateVipContent($input: VipContentInput!){
    updateVipContent(input: $input){
      _id
    }
  }
`

const UPDATE_APP_GUIDE = gql`
  mutation updateFeatureAppGuide($input: UpdateAppguideInput!){
    updateFeatureAppGuide(input: $input){
      _id
    }
  }
`

const UPDATE_LIVE_STREAM = gql`
  mutation updateFeatureLiveStream($input: UpdateFeatureLiveStreamInput!) {
    updateFeatureLiveStream(input: $input) {
      _id
    }
  }
`;

const UPDATE_BANNER = gql`
  mutation updateEventBanner($input: UpdateBannerInput!){
    updateEventBanner(input: $input){
      _id
      startDateTime
      endDateTime
      name
      slug
      isPublished
      description
      emailAddressToReachYou
      numberOfattendees
      createdBy {
        name
        companyName
        email
      }
      privacySetting {
        code
        setting {
          _id
          name
          description
        }
        poweredBy {
          eve
          byCustom
        }
      }
      eventType {
        _id
        name
      }
      features {
        _id
        name
      }
      isPreview
      publishedOn
      isLive
      isCompleted
     ${eventSettingSchema}
      location {
        venueName
        address1
        address2
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
    }
  }
`

export {
  UPDATE_NETWORKING,
  UPDATE_ANNOUNCEMENT,
  UPDATE_BOOK_MEETING,
  UPDATE_PRESENTER,
  UPDATE_SCHEDULE,
  UPDATE_RECORDING,
  UPDATE_SLIDE,
  UPDATE_POLLS,
  UPDATE_SPONSOR,
  UPDATE_STREAM_OPTION,
  UPDATE_AMA_SESSION,
  UPDATE_SURVEY,
  UPDATE_VIP_CONTENT,
  UPDATE_APP_GUIDE,
  UPDATE_LIVE_STREAM,
  UPDATE_ATTENDEE,
  UPDATE_BANNER
}