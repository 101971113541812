import images from "./Images";
import Features from 'pages/features'
import RouterPath from "./RouterPath";
import { VIP_CONTENT_SELECT } from 'graphQl/Features/QuerySchema';

export const homeList = [
  {
    name: "Managername",
    desc: "Product Manager • Google Inc",
    thumbnail: images.profile1,
  },
  {
    name: "Managername",
    desc: "Product Manager • Google Inc",
    thumbnail: images.profile2,
  },
  {
    name: "Managername",
    desc: "Product Manager • Google Inc",
    thumbnail: images.profile3,
  },
  {
    name: "Managername",
    desc: "Product Manager • Google Inc",
    thumbnail: images.profile4,
  },
  {
    name: "Managername",
    desc: "Product Manager • Google Inc",
    thumbnail: images.profile5,
  },
];

export const headerPopoverList = [
  {
    name: "Jerry Hill",
    description: "Nullam pulvinar felis malesuada...",
    image: images.headerNotification1,
  },
  {
    name: "Beatrice McDonald",
    description: "Duis blandit neque puru...",
    image: images.headerNotification2,
  },
  {
    name: "Nancy Lawson",
    description: "Nullam pulvinar felis malesuada",
    image: images.headerNotification3,
  },
  {
    name: "Debra Weber",
    description: "Nullam pulvinar felis malesuada",
    image: images.headerNotification4,
  },
  {
    name: "Dan Miller",
    description: "Duis blandit neque puru...",
    image: images.profile7,
  },
  {
    name: "Pamela Wagner",
    description: "Nullam pulvinar felis malesuada",
    image: images.profile1,
  },
];

export const EventListData = [
  {
    type: "Virtual",
    image: images.card1,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail1,
  },
  {
    type: "Virtual",
    image: images.card2,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail2,
  },
  {
    type: "Virtual",
    image: images.card3,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail3,
  },
  {
    type: "Virtual",
    image: images.card1,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail1,
  },
  {
    type: "Virtual",
    image: images.card2,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail2,
  },
  {
    type: "Virtual",
    image: images.card3,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail3,
  },
  {
    type: "Virtual",
    image: images.card1,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail1,
  },
  {
    type: "Virtual",
    image: images.card2,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail2,
  },
  {
    type: "Virtual",
    image: images.card3,
    description:
      "Neque porro quisquam est dolorem ipsum quia dolor lorem ipsum",
    date: "July 24-26, 2020",
    thumbnail: images.cardThumbnail3,
  },
];

// need commented code for future
export const FeaturesModal = [
  { name: 'Overview', img1: images.overview, img2: images.overviewWhite },
  { name: 'Networking', img1: images.networking, img2: images.networkingWhite },
  { name: 'Main Session', img1: images.liveStream, img2: images.liveStreamWhite },
  { name: 'Sponsors', img1: images.sponsors, img2: images.sponsorsWhite },
  { name: 'Breakout Sessions', img1: images.schedule, img2: images.scheduleWhite },
  { name: 'Announcements', img1: images.announcements, img2: images.announcementWhite },
  { name: 'Presenters', img1: images.presenters, img2: images.presentersWhite },
  { name: 'Live Polls', img1: images.livePollsQA, img2: images.livePollsQAWhite },
  { name: 'Recordings', img1: images.recordings, img2: images.recordingsWhite },
  { name: 'Slides', img1: images.slides, img2: images.slidesWhite },
  { name: 'Help Desk', img1: images.helpDesk, img2: images.helpDeskWhite },
  { name: 'Survey', img1: images.survey, img2: images.surveyWhite },
  { name: 'App Guide', img1: images.appGuide, img2: images.appGuideWhite },
  { name: 'VIP Content', img1: images.vipContent, img2: images.vipContentWhite },
  { name: 'Stream Options', img1: images.streamOptions, img2: images.streamOptionsWhite },
  { name: 'Q&A with Sponsor', img1: images.livePollsQA, img2: images.livePollsQAWhite },
  // { name: 'Virtual Expo', img1: images.virtualExpo, img2: images.virtualExpoWhite },
  // { name: 'Breakout Session', img1: images.breakoutSession, img2: images.breakoutSessionWhite },
  // { name: 'Book Meetings', img1: images.bookMeetings, img2: images.bookMeetingsWhite },
  // { name: 'Schedule 1-On-1', img1: images.schedule1V1, img2: images.schedule1V1White },
]

// need for future (commented code of this file) 

export const FeaturesData = [
  {
    name: 'Overview',
    link: RouterPath.addEvents
  },
  {
    name: 'Networking',
    link: RouterPath.networking
  },
  {
    name: 'Main Session',
    link: RouterPath.liveStream
  },
  {
    name: 'Sponsors',
    link: RouterPath.sponsors
  },
  {
    name: 'Breakout Sessions',
    link: RouterPath.schedule
  },
  {
    name: 'Announcements',
    link: RouterPath.announcements
  },
  {
    name: 'Presenters',
    link: RouterPath.presenters
  },
  {
    name: 'Live Polls',
    link: RouterPath.livePolls
  },
  {
    name: 'Recordings',
    link: RouterPath.recordings
  },
  {
    name: 'Slides',
    link: RouterPath.slides
  },
  // need for future (commented code of this file) 

  // {
  //   name: 'Book Meetings',
  //   link: RouterPath.bookMeetings
  // },
  // {
  //   name: 'Schedule 1-On-1',
  //   type: 'modal',
  //   component: Features.Schedule1V1,
  // },
  // {
  //   name: 'Breakout Session',
  //   type: 'modal',
  //   component: Features.BreakoutSession,
  // },
  // {
  //   name: 'Virtual Expo',
  //   type: 'modal',
  //   component: Features.VirtualExpo,
  // },
  {
    name: 'Help Desk',
    link: RouterPath.helpDesk
  },
  {
    name: 'Survey',
    link: RouterPath.survey
  },
  {
    name: 'App Guide',
    type: 'modal',
    component: Features.AppGuide,
  },
  {
    name: 'VIP Content',
    type: 'modal',
    component: Features.VIPContent,
    editSchema: VIP_CONTENT_SELECT
  },
  {
    name: 'Stream Options',
    link: RouterPath.streamOptions
  },
  {
    name: 'Q&A with Sponsor',
    link: RouterPath.AMASession
  },

]

export const notificationsData = [
  {
    _id: '1',
    data: {
      name: "Jerry Hill",
      description: "Nullam pulvinar felis malesuada, convallis lacus commodo.",
      thumbnail: images.headerNotification1
    },
    date: "26 Jan, 2020 12:00 am"


  },
  {
    _id: '2',
    data: {
      name: "Beatrice McDonald",
      description: "Duis blandit neque purus, nec auctor mi sollicitudin nec.",
      thumbnail: images.headerNotification2,
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '3',
    data: {
      name: "Nancy Lawson",
      description: "Duis urna ipsum, tincidunt at euismod placerat.",
      thumbnail: images.headerNotification3,
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '4',
    data: {
      name: "Debra Weber",
      description: "Nullam pulvinar felis malesuada, convallis lacus commodo.",
      thumbnail: images.headerNotification4,
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '5',
    data: {
      name: "Dan Miller",
      description: "Duis blandit neque purus, nec auctor mi sollicitudin nec.",
      thumbnail: images.profile7,
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '6',
    data: {
      name: "Jerry Hill",
      description: "Nullam pulvinar felis malesuada, convallis lacus commodo.",
      thumbnail: images.headerNotification1
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '7',
    data: {
      name: "Pamela Wagner",
      description: "Duis blandit neque purus, nec auctor mi sollicitudin nec.",
      thumbnail: images.profile1,
    },
    date: "26 Jan, 2020 12:00 am"
  },
  {
    _id: '8',
    data: {
      name: "Charles Roberts",
      description: "Nullam pulvinar felis malesuada, convallis lacus commodo.",
      thumbnail: images.profile1,
    },
    date: "26 Jan, 2020 12:00 am"
  },
];

export const ManagersList = [
  {
    key: '1',
    data: {
      name: 'Ethan Banks',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile11,
    }
  },
  {
    key: '2',
    data: {
      name: 'Bianca Lucas',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile6,
    }
  },
  {
    key: '3',
    data: {
      name: 'Patrick Foster',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile8,
    }
  },
  {
    key: '4',
    data: {
      name: 'Martha Schneider',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile9,
    }
  },
  {
    key: '5',
    data: {
      name: 'Will Barnett',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile10,
    }
  },
]

export const TotalUserList = [
  {
    key: '1',
    data: {
      name: 'Joan Boyd',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile11,
    }
  },
  {
    key: '2',
    data: {
      name: 'Nick Cooper',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile12,
    }
  },
  {
    key: '3',
    data: {
      name: 'Joe Hawkins',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile13,
    }
  },
  {
    key: '4',
    data: {
      name: 'Eliza Gray',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile14,
    }
  },
  {
    key: '5',
    data: {
      name: 'Victoria Holland',
      description: 'Product Manager • Google Inc',
      thumbnail: images.profile15,
    }
  }

]
export const NewRequestList = [
  {
    key: '1',
    data: {
      name: "Bruce Hawkins",
      description: "Product Manager • Google Inc",
      thumbnail: images.profile11,
    }

  },
  {
    key: '2',
    data: {
      name: "Michelle Fuller",
      description: "Product Manager • Google Inc",
      thumbnail: images.profile6,
    }
  },
  {
    key: '3',
    data: {
      name: "Nick Porter",
      description: "Product Manager • Google Inc",
      thumbnail: images.profile8,
    }
  },
  {
    key: '4',
    data: {
      name: "Victoria Hoffman",
      description: "Product Manager • Google Inc",
      thumbnail: images.profile9,
    }
  },
  {
    key: '5',
    data: {
      name: "Anthony Oliver",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile8,
    }
  },
  {
    key: '6',
    data: {
      name: "Patrick Richards",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile11,
    }
  },
  {
    key: '7',
    data: {
      name: "Janice Banks",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile6,
    }
  },
];

export const systemManagersDataList = [
  {
    key: '1',
    data: {
      title: "Philip Andrews",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile11,
    }

  },
  {
    key: '2',
    data: {
      title: "Kathryn Andrews",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile6,
    }
  },
  {
    key: '3',
    data: {
      title: "Walter Fox",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile8,
    }
  },
  {
    key: '5',
    data: {
      title: "Rose Hudson",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile9,
    }
  },
  {
    key: '6',
    data: {
      title: "Tom Rivera",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile13,
    }
  },
  {
    key: '7',
    data: {
      title: "Vincent Grand",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile14,
    }
  },
  {
    key: '8',
    data: {
      title: "Beverly Cunningham",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile15,
    }
  },
];

export const totalUserDataList = [
  {
    key: '1',
    data: {
      title: "Megan Watkins",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile16,
    }

  },
  {
    key: '2',
    data: {
      title: "Will Holland",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile17,
    }
  },
  {
    key: '3',
    data: {
      title: "Janet Marshall",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile18,
    }
  },
  {
    key: '4',
    data: {
      title: "Beatrice Hill",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile19,
    }
  },
  {
    key: '5',
    data: {
      title: "Sara Hart",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile20,
    }
  },
  {
    key: '6',
    data: {
      title: "Natasha Ramirez",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile21,
    }
  },
  {
    key: '7',
    data: {
      title: "Doris Fuller",
      desc: "Product Manager • Google Inc",
      thumbnail: images.profile22,
    }
  },
];

export const EventTypeCard = [
  {
    name: "In Person",
    normalImg: images.inPersonType,
    whiteImg: images.inPersonTypeWhite,
    imgWidth: 100,
  },
  {
    name: "Virtual",
    normalImg: images.virtualEventType,
    whiteImg: images.virtualEventTypeWhite,
    imgWidth: 48
  }
]

export const generalSettingsCard = [
  {
    name: "Smart Feed (Default)",
    thumbnail: images.mobile1,
  },
  {
    name: "Features Only",
    thumbnail: images.mobile2,

  },
  {
    name: "Banners",
    thumbnail: images.mobile3,
  }

];

export const PoweredBy: { id: string, name: string, custom: boolean }[] = [
  { id: "eve", name: 'Powered By EVE', custom: false },
  { id: "byCustom", name: 'Powered By Custom', custom: true }
]

export const LiveStreamList = [
  { id: 1, value: 'Zoom', label: 'Zoom' },
  { id: 2, value: 'RMTP Player', label: 'RMTP Player' },
  { id: 3, value: 'YouTube', label: 'YouTube' },
  { id: 4, value: 'Vimeo', label: 'Vimeo' },
  { id: 5, value: 'Wistia', label: 'Wistia' },
  { id: 6, value: 'Facebook', label: 'Facebook' },
  { id: 7, value: 'DailyMotaion', label: 'DailyMotaion' },
  { id: 8, value: 'Streamable', label: 'Streamable' },
  { id: 9, value: 'SoundCloud', label: 'SoundCloud' },
  { id: 10, value: 'Other', label: 'Other' },
]
export const optionList1 = [
  { id: 0, value: "", label: 'NONE' },
  { id: 1, value: "Website", label: 'Website' },
  { id: 2, value: "Email", label: 'Email' },
  { id: 3, value: "Phone", label: 'Phone' },
]

export const TimeList = [
  { id: 0, value: '3', label: '3 seconds' },
  { id: 1, value: '5', label: '5 seconds' },
  { id: 2, value: '7', label: '7 seconds' },
  { id: 3, value: '10', label: '10 seconds' },
]

export const socialMediaList = [
  { id: 1, value: 'LinkedIn', label: 'LinkedIn' },
  { id: 2, value: 'Twitter', label: 'Twitter' },
  { id: 3, value: 'Facebook', label: 'Facebook' },
  { id: 4, value: 'Other', label: 'Other' },
]

export const optionList2 = [
  { id: 1, value: 'free', label: 'Free', disable: false },
  { id: 2, value: 'paid', label: 'Paid', disable: false },
  { id: 3, value: 'premium', label: 'Premium', disable: false }
]

export const optionList3 = [
  { id: 1, value: "Open", label: 'Open', disable: false },
  { id: 2, value: "Close", label: 'Close', disable: false },
];

export const optionList4 = [
  { id: 1, value: "DOC", label: 'DOC', disable: false },
  { id: 2, value: "PDF", label: 'PDF', disable: false },
  { id: 3, value: "Word", label: 'Word', disable: false },
  { id: 4, value: "Power Point", label: 'Power Point', disable: false },
];

export const optionList5 = [
  { id: 1, value: "rating", label: 'Rating', disable: false },
  { id: 2, value: "textbox", label: 'Textbox', disable: false },
  { id: 3, value: "checkbox", label: 'Checkbox', disable: false },
];

export const DOMAINTYPE: any = {
  1: "AWS SES",
  2: "GMAIL",
}

export const emailProvider = [
  { id: 1, label: 'AWS SES', value: 1, disable: false },
  { id: 2, label: 'Gmail', value: 2, disable: false }
]

export const selectType = [
  { id: 1, label: 'Link', value: 'link', disable: true },
  { id: 2, label: 'Attachment', value: 'attachment', disable: false }
]

interface templateNameInterface {
  [number: number]: string
}

export const TEMPLATENAME: templateNameInterface = {
  1: "EMAIL_USER_REGISTRATION",
  2: "EMAIL_USER_ACTIVATE",
  3: "EMAIL_USER_FORGOT_PASSWORD",
  4: "EMAIL_SYSTEM_MANAGER_SIGNUP",
  5: "EMAIL_USER_REGISTRATION_FROM_WEB",
  6: "EMAIL_NETWORK_REGISTER",
  7: "EMAIL_ADMIN_USER_REGISTER",
  8: "EMAIL_ATTENDEE_INVITE",
  9: "EMAIL_SYSTEM_MANAGER_REJECT_EVENT",
  10: "EMAIL_EVENT_INVITE_USERS",
  11: "EMAIL_EVENT_INVITE_USERS_UNIQUE_CODE",
  12: "EMAIL_APP_USER_FORGOT_PASSWORD",
}

interface templateInterface {
  [number: number]: string
}

export const templateTableName: templateInterface = {
  1: 'User Registration',
  2: 'User Activate',
  3: 'Forgot password',
  4: 'System manager registration',
  5: 'Web user registration',
  6: 'Network registration',
  7: 'Admin user registration',
  8: 'Attendee invite',
  9: 'System manager reject event',
  10: 'Event user invitation',
  11: 'Event user invitation unique code',
  12: 'App user forgot password',
  13: 'Password Change',
  14: 'Two Factor Authentication',
}

export const templateName = [
  { id: 1, label: 'User Registration', value: 1, disable: false },
  { id: 2, label: 'User Activate', value: 2, disable: false },
  { id: 3, label: 'Forgot password', value: 3, disable: false },
  { id: 4, label: 'System manager registration', value: 4, disable: false },
  { id: 5, label: 'Web user registration', value: 5, disable: false },
  { id: 6, label: 'Network registration', value: 6, disable: false },
  { id: 7, label: 'Admin user registration', value: 7, disable: false },
  { id: 8, label: 'Attendee invite', value: 8, disable: false },
  { id: 9, label: 'System manager reject event', value: 9, disable: false },
  { id: 10, label: 'Event user invitation', value: 10, disable: false },
  { id: 11, label: 'Event user invitation unique code', value: 11, disable: false },
  { id: 12, label: 'App user forgot password', value: 12, disable: false },
  { id: 13, label: 'Password Change', value: 13, disable: false },
  { id: 14, label: 'Two Factor Authentication', value: 14, disable: false },
]

export const documentOptionList = [
  { id: 1, value: "DOC", label: 'DOC', disable: false },
  { id: 2, value: "PDF", label: 'PDF', disable: false },
  { id: 3, value: "Word", label: 'Word', disable: false },
];
export const vipContentOptions = [
  { id: 1, value: "Schedule", label: 'Schedule' },
  { id: 2, value: "Recordings", label: 'Recordings' },
  { id: 3, value: "App Guide", label: 'App Guide' },
  { id: 4, value: "Presenters", label: 'Presenters' },
  { id: 5, value: "Networking", label: 'Networking' },
  { id: 6, value: "Sponsors", label: 'Sponsors' },
];

export const appGuideDocument = [
  { name: 'Filename.PDF' },
];
export const vipContentDocument = [
  { name: 'Welcome Session' },
  { name: 'The Virtual Experience' },
];
export const vipContentSessions = [
  {
    _id: 0,
    name: "Jerry Hill",
    desc: "Nullam pulvinar felis malesuada...",
    thumbnail: images.headerNotification1,
  },
  {
    _id: 1,
    name: "Beatrice McDonald",
    desc: "Duis blandit neque puru...",
    thumbnail: images.headerNotification2,
  },
  {
    _id: 2,
    name: "Nancy Lawson",
    desc: "Nullam pulvinar felis malesuada",
    thumbnail: images.headerNotification3,
  },
  {
    _id: 3,
    name: "Debra Weber",
    desc: "Nullam pulvinar felis malesuada",
    thumbnail: images.headerNotification4,
  },
  {
    _id: 4,
    name: "Dan Miller",
    desc: "Duis blandit neque puru...",
    thumbnail: images.profile7,
  },
  {
    _id: 5,
    name: "Pamela Wagner",
    desc: "Nullam pulvinar felis malesuada",
    thumbnail: images.profile1,
  },
];

export const DefaultDenialMsg = "This event is Full"