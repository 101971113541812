const images = {
  DashboardBG: require('assets/images/callum-wale-AU1rKyKPJco-unsplash.png'),
  card1: require('assets/images/annie-spratt-0ZPSX_mQ3xI-unsplash.png'),
  card2: require('assets/images/photo-1541701494587-cb58502866ab.png'),
  card3: require('assets/images/alex-ZR48YvUpk04-unsplash.png'),
  cardThumbnail1: require('assets/images/8dc3cde32411fb65c0c8f823751697d3.png'),
  cardThumbnail2: require('assets/images/abstract.png'),
  ratingStar: require('assets/images/rating_icon.png'),
  cardThumbnail3: require('assets/images/abstract-logo-flame-shape_1043-44.png'),
  profile1: require('assets/images/photo-1482852748104-b57570c42bba.png'),
  profile2: require('assets/images/photo-1494790108377-be9c29b29330.png'),
  profile3: require('assets/images/photo-1527980965255-d3b416303d12.png'),
  profile3_2x: require('assets/images/photo-1527980965255-d3b416303d12@2x.png'),
  profile4: require('assets/images/photo-1533227268428-f9ed0900fb3b.png'),
  profile5: require('assets/images/photo-1541701494587-cb58502866ab.png'),
  profile6: require('assets/images/photo-1542740348-39501cd6e2b4.png'),
  profile7: require('assets/images/photo-1548213238-0da7521bd6e0.png'),
  profile8: require('assets/images/photo-1548543604-a87c9909abec.png'),
  profile9: require('assets/images/photo-1554727242-741c14fa561c.png'),
  profile10: require('assets/images/photo-1555092394-6ed41ac4afb8.png'),
  profile11: require('assets/images/photo-1562124638-724e13052daf.png'),
  profile12: require('assets/images/photo-1592621385645-e41659e8aabe.png'),
  profile13: require('assets/images/photo-1562124638-724e13052daf15.png'),
  profile14: require('assets/images/photo-1542740348-39501cd6e2b41.png'),
  profile15: require('assets/images/photo-1548543604-a87c9909abec2.png'),
  profile16: require('assets/images/photo-1562124638-724e13052daf11.png'),
  profile17: require('assets/images/photo-1542740348-39501cd6e2b445.png'),
  profile18: require('assets/images/photo-1548543604-a87c9909abec12.png'),
  profile19: require('assets/images/photo-1554727242-741c14fa561c10.png'),
  profile20: require('assets/images/photo-1562124638-724e13052daf86.png'),
  profile21: require('assets/images/photo-1542740348-39501cd6e2b4112.png'),
  profile22: require('assets/images/photo-1548543604-a87c9909abec126.png'),
  squareProfile: require('assets/images/SquareProfile.png'),
  benjiCiko: require('assets/images/photo-1474176857210-7287d38d27c6.png'),
  benjiCiko2x: require('assets/images/photo-1474176857210-7287d38d27c6@2x.png'),
  headerLogo: require("../assets/images/EVE_Logo3.png"),
  logo: require('assets/images/logo.png'),
  buttonNotification: require("../assets/icons/notification.svg"),
  headerNotification1: require("../assets/images/photo-notification1.png"),
  headerNotification2: require("../assets/images/photo-notification2.png"),
  headerNotification3: require("../assets/images/photo-notification3.png"),
  headerNotification4: require("../assets/images/photo-notification4.png"),
  OfficePic: require('assets/images/officePic.png'),
  postImage: require('assets/images/photo-1568992688065-536aad8a12f6.png'),
  postImage2x: require('assets/images/photo-1568992688065-536aad8a12f6.png'),
  settings: require('assets/icons/settings.png'),
  greenTick: require('assets/icons/correct.png'),
  redClose: require('assets/icons/cancel.png'),
  blackClose: require("../assets/icons/close.png"),
  mobileHeader: require("../assets/images/headway-F2KRf_QfCqw-unsplash.png"),
  edit: require("../assets/icons/orangeEdit.svg"),
  grayEdit: require("../assets/icons/grayEdit.svg"),
  clone: require("../assets/icons/clone.svg"),
  comment: require('assets/icons/comment.svg'),
  download: require('assets/icons/download.svg'),
  correctWhite: require('assets/icons/correctWhite.svg'),
  //Events Types
  virtualEventType: require('assets/icons/virtualEvent.svg'),
  virtualEventTypeWhite: require('assets/icons/virtualEventWhite.svg'),
  inPersonType: require('assets/icons/InPerson.png'),
  inPersonTypeWhite: require('assets/icons/InPersonWhite.png'),
  //Arrow
  downArrow: require('assets/icons/down-arrow.png'),
  backArrowWhite: require('assets/icons/backArrowWhite.png'),
  nextArrowWhite: require('assets/icons/next.png'),
  nextArrowOrange: require('assets/icons/arrow.png'),
  blackLeftArrow: require('assets/icons/left-arrow.svg'),
  downArrowOrange: require("../assets/icons/down-arrow-orange.png"),
  // icons
  imageUpload: require("../assets/icons/uploadImage.png"),
  docsUpload: require("../assets/icons/uploadDocs.png"),
  report: require("../assets/icons/Report.png"),
  logsIcon: require("../assets/icons/logs.svg"),
  //user Layout
  defaultUser: require("../assets/images/defaultuser.webp"),
  defaultCover: require("../assets/images/defaultCover.png"),
  defaultLogo: require("../assets/images/defaultLogo.jpg"),
  userLayout1: require("../assets/images/user-layout-pic-1.png"),
  userLayout2: require("../assets/images/user-layout-pic-2.png"),
  instructionRightIcon: require("../assets/images/instruction-right-icon.png"),
  //Features
  overview: require("../assets/icons/FeaturesIcons/overview.png"),
  overviewWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/overview.png'),
  breakoutSession: require("../assets/icons/FeaturesIcons/breakout session.png"),
  breakoutSessionWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/breakout session.png'),
  liveStream: require("../assets/icons/FeaturesIcons/livestream.png"),
  liveStreamWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/livestream.png'),
  networking: require("../assets/icons/FeaturesIcons/networking.png"),
  networkingWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/networking.png'),
  announcements: require("../assets/icons/FeaturesIcons/Announcements.svg"),
  check: require("../assets/icons/check.png"),
  announcementWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/AnnouncementsWhite.svg'),
  appGuide: require("../assets/icons/FeaturesIcons/App-Guide.svg"),
  appGuideWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/App-GuideWhite.svg'),
  bookMeetings: require("../assets/icons/FeaturesIcons/BookMeetings.png"),
  bookMeetingsWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/Book-MeetingsWhite.png'),
  helpDesk: require("../assets/icons/FeaturesIcons/Help-Desk.svg"),
  helpDeskWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/Help-DeskWhite.svg'),
  presenters: require("../assets/icons/FeaturesIcons/Presenters.svg"),
  presentersWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/PresentersWhite.svg'),
  livePollsQA: require("../assets/icons/FeaturesIcons/LivePolls+QA.svg"),
  livePollsQAWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/LivePolls+QAWhite.svg'),
  recordings: require("../assets/icons/FeaturesIcons/Recordings.svg"),
  recordingsWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/RecordingsWhite.svg'),
  schedule: require("../assets/icons/FeaturesIcons/Schedule.svg"),
  scheduleWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/ScheduleWhite.svg'),
  schedule1V1: require("../assets/icons/FeaturesIcons/Schedule-1V1.svg"),
  schedule1V1White: require('assets/icons/FeaturesIcons/FeaturesIconWhite/Schedule-1V1White.svg'),
  slides: require("../assets/icons/FeaturesIcons/Slides.svg"),
  slidesWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/SlidesWhite.svg'),
  sponsors: require("../assets/icons/FeaturesIcons/Sponsors.svg"),
  sponsorsWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/SponsorsWhite.svg'),
  streamOptions: require("../assets/icons/FeaturesIcons/Stream-Options.svg"),
  streamOptionsWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/Stream-OptionsWhite.svg'),
  survey: require("../assets/icons/FeaturesIcons/Survey.svg"),
  surveyWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/SurveyWhite.svg'),
  vipContent: require("../assets/icons/FeaturesIcons/VIP-Content.svg"),
  vipContentWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/VIP-ContentWhite.svg'),
  virtualExpo: require("../assets/icons/FeaturesIcons/Virtual-Expo.svg"),
  virtualExpoWhite: require('assets/icons/FeaturesIcons/FeaturesIconWhite/Virtual-ExpoWhite.svg'),
  emptyImage: require("../assets/icons/FeaturesIcons/emptyImage.png"),
  addIcon: require('assets/icons/FeaturesIcons/add.png'),
  plus: require('assets/icons/FeaturesIcons/plus.png'),
  blackPlus: require('assets/icons/blackPlus.png'),
  homeIcon: require('assets/icons/FeaturesIcons/home.png'),
  logoutIcon: require('assets/icons/FeaturesIcons/logout.png'),
  settingsIcon: require('assets/icons/FeaturesIcons/settings.png'),
  settingIcon: require('assets/icons/settings.png'),
  userIcon: require('assets/icons/FeaturesIcons/user.png'),
  mobile1: require('assets/icons/FeaturesIcons/mobile1.png'),
  mobile2: require('assets/icons/FeaturesIcons/mobile2.png'),
  mobile3: require('assets/icons/FeaturesIcons/mobile3.png'),
  cover1: require('assets/icons/FeaturesIcons/cover-image.png'),
  eventLoginBanner:require('assets/images/event-login-banner.png'),

  bluePencil: require('assets/icons/FeaturesIcons/pen.png'),
  pencilEdit: require('assets/icons/pencil.png'),
  eyeBlack: require('assets/icons/eye.svg'),
  deleteRed: require('assets/icons/trash.png'),
  assignTo: require('assets/icons/FeaturesIcons/assignTo.png'),

  calendar: require("../assets/icons/calendar.svg"),
  clock: require('assets/icons/clock.svg'),
  gps: require('assets/icons/gps.svg'),
  seeMore: require('assets/icons/see-more.svg'),

};

export default images;
