import { message as notification } from "antd"

const NotificationWithIcon = (
    type: string,
    message: string
) => {

    if (type === "success") {
        notification.success(message)
    } else if (type === "error") {
        notification.error(message)
    } else {
        notification.info(message)
    }
}


export default NotificationWithIcon