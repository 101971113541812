import React, { ChangeEvent, ReactNode, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Form } from 'formik'
// import { useHistory } from 'react-router-dom'
// components
import { MutationFunction } from '@apollo/react-hooks'
import { WSModal } from 'components/common'
import WSToolTip from 'components/common/WSTooltip/WSTooltip'
import {
  Loader,
  WSButton,
  WSCheckbox,
  WSInputSearch,
} from 'components/FormComponent'
import ImportModal from './Import'
// constants
import images from 'constants/Images'
import {
  externalBrowserCheckboxTip,
} from 'utils/ToolTips'
import SortFilterBy from 'containers/SortFilterBy/SortFilterBy'
import { BtnVariant } from 'constants/Enums'
import { useLocation } from 'react-router-dom'

interface ModalProps {
  infoMessage?: string
  showModal?: boolean
  onCancel?: () => void
  handleSubmit?: (onCancel: () => void) => void
  loading?: boolean
  children?: ReactNode
  title?: string
  footer?: ReactNode[]
  isEdit?: boolean
  isLoading?: boolean
  addMore?: boolean
  modalClass?: string
}
export const FeatureModal = ({
  infoMessage,
  onCancel,
  showModal,
  children,
  title = 'New Item',
  footer,
  loading,
  isEdit,
  isLoading,
  addMore,
  modalClass,
}: ModalProps) => {
  const { BORDERED, LINK } = BtnVariant
  const Footer = !isEdit ? [
    <Form key='buttons'>
      {addMore && (
        <WSButton
          variant={BORDERED}
          type='button'
          className='float-l border-none add-more-text'
          label='Add More'
        />
      )}
      <WSButton
        buttonLoading={loading}
        className='float-r box-shadow-none'
        label='Save Changes'
        rightIcon={images.nextArrowWhite}
        width='13px'
        height='15px'
      />
      <WSButton
        type='button'
        variant={LINK}
        inlineClass='textbox-label'
        onClick={onCancel}
        label='Close'
        className='mr-3'
      />
    </Form>,
  ] : []
  return (
    <Form>
      <WSModal
        className={`${modalClass} features-add`}
        width='600px'
        title={
          <>
            <span className='modal-title-info font-bold'>{title}</span>
            {infoMessage && <WSToolTip title={infoMessage}>
              <span className='info-title-btn'>i</span>
            </WSToolTip>}
          </>
        }
        open={showModal}
        onCancel={onCancel}
        footer={footer || Footer}
      >
        {isLoading ? (
          <Loader divClassName='loading' />
        ) : (
            <fieldset disabled={isEdit}>{children}</fieldset>
          )}
      </WSModal>
    </Form>
  )
}

interface FeatureModalCheckboxprops {
  values?: string[]
  name?: string
}
export const FeatureModalTwoCheckBox = ({
  values,
  name = 'externalLinkSelect',
}: FeatureModalCheckboxprops) => {
  return (
    <>
      <div className='checkbox-with-tooltip'>
        <WSCheckbox
          disabled
          labelArr={['Open link in external browser']}
          value={['Open link in external browser']}
          name={name}
          className='app-guide-checkbox'
        />
        <WSToolTip title={externalBrowserCheckboxTip}>
          <span className="info-gray-btn font-bold border-red-50">i</span>
        </WSToolTip>
      </div>
      <div className='checkbox-with-tooltip'>
        {/* <WSCheckbox   need for future
          labelArr={['Pass Data to URL']}
          value={values}
          name={name}
          className='app-guide-checkbox'
        />
        <WSToolTip title={dataToURLCheckboxTip}>
          <span className="info-gray-btn font-bold border-red-50">i</span>
        </WSToolTip> */}
      </div>
    </>
  )
}

interface LeftContentProps {
  subTitle?: string
  NewItemModal?: any
  userType?: string
  itemLabel?: string
  showNewItemModal?: boolean
  onCancelNewItemModal?: () => void
  initData?: Object
  isEdit?: boolean
  setIsEdit?: (isEdit: boolean) => void
  isLoading?: boolean
  mutationFunction?: MutationFunction
  eventId?: string
  headersArray?: string[]
  hideNewItem?: boolean
}
export const FeatureHeaderLeftContent = ({
  subTitle,
  NewItemModal,
  userType,
  itemLabel = 'New Item',
  showNewItemModal,
  onCancelNewItemModal,
  initData,
  isEdit,
  setIsEdit,
  isLoading,
  eventId,
  headersArray,
  mutationFunction,
  hideNewItem,
}: LeftContentProps) => {
  const [newItemModal, setNewItemModal] = useState<boolean>(false)
  const [importModal, setImportModal] = useState<boolean>(false)
  const onCancel = () => {
    setNewItemModal(false)
    setImportModal(false)
  }
  const onModalShow = () => {
    setIsEdit && setIsEdit(false)
    setNewItemModal(!newItemModal)
  }
  // const history = useHistory()
  const location = useLocation()
  return (
    <div className='d-flex'>
      <NewItemModal
        onCancel={showNewItemModal ? onCancelNewItemModal : onCancel}
        showModal={showNewItemModal || newItemModal}
        userType={userType}
        history={location}
        initData={initData}
        isEdit={isEdit}
        isLoading={isLoading}
      />
      {!hideNewItem && (
        <WSButton
          variant={BtnVariant.GRADIENT}
          label={itemLabel}
          className='box-shadow-none'
          onClick={onModalShow}
        />
      )}
      <ImportModal
        onCancel={onCancel}
        subTitleName={subTitle}
        showModal={importModal}
        mutationLoading={isLoading}
        mutationFunction={mutationFunction}
        eventId={eventId}
        headersArray={headersArray}
      />
      {subTitle && (
        <WSButton
          label='Import'
          variant={BtnVariant.BORDERED}
          className='import-button ml-1 cursor-pointer'
          onClick={() => setImportModal(!importModal)}
        />
      )}
    </div>
  )
}

interface RightContentProps {
  currentPage?: number
  sortingFunction?: (e: object) => void
  setSorting?: (e: boolean) => void
  sortByArray?: { name: string }[]
  eventId?: string
  searchOnChange?: (e: ChangeEvent<HTMLInputElement>) => void
}
export const FeatureHeaderRightContent = ({
  currentPage,
  setSorting,
  sortingFunction,
  sortByArray,
  eventId,
  searchOnChange,
}: RightContentProps) => {
  return (
    <div className='d-flex sortby-search'>
      <WSInputSearch
        name='Search'
        icon={<SearchOutlined />}
        inputClass='table-card-search-box'
        searchOnChange={searchOnChange}
      />
      <SortFilterBy
        label='Sort By'
        currentPage={currentPage}
        sortingFunction={sortingFunction}
        setSorting={setSorting}
        sortByArray={sortByArray}
        eventId={eventId}
      />
    </div>
  )
}
