import gql from "graphql-tag";
import { eventSettingSchema, imageSchema, privacySettingSchema } from "../CommonSchemas";

const GET_ALL_EVENTS = gql`
  query getAllEvents(
    $offset: Int
    $limit: Int
    $eventStatus: EVENTSTATUS
    $userId: String
    $duration: EVENTDURATION
    $isLive: Boolean,
    $isPublished: Boolean
  ) {
    getAllEvents(
      offset: $offset
      limit: $limit
      eventStatus: $eventStatus
      userId: $userId,
      duration: $duration,
      isLive: $isLive,
      isPublished: $isPublished
    ) {
      nodes {
        _id
        name
        createdBy{
          name
        }
        updatedAt
        description
        eventType {
          _id
          name
        }
        publishedOn
        isLive
        isCompleted
        description
        eventType {
          _id
          name
        }
        isPublished
        startDateTime
        endDateTime
        logo { url }
        coverImage { url }
        eventStatus
        
        startDateTime
        endDateTime
        logo {
          url
        }
        coverImage {
          url
        }
        eventStatus
      }
      totalCount
    }
  }
`;

const GET_ALL_LOOKUPS = gql`
  query getAllLookups(
    $offset: Int
    $limit: Int
    $type: String
    $sortBy: LOOKUPSORTING
  ) {
    getAllLookups(
      offset: $offset
      limit: $limit
      type: $type
      sortBy: $sortBy
    ) {
      nodes {
        _id
        description
        name
      }
      totalCount
    }
  }
`;

const DELETE_EVENT = gql`
  mutation deleteEvent($id: String!) {
    deleteEvent(id: $id) {
      _id
      name
    }
  }
`;

const GET_EVENT_DETAIL = gql`
  query getEventDetail($eventId: String!) {
    getEventDetail(eventId: $eventId) {
      _id
      startDateTime
      endDateTime
      name
      slug
      isPublished
      description
      emailAddressToReachYou
      numberOfattendees
      createdBy {
        name
        companyName
        email
      }
      ${privacySettingSchema}
      eventType {
        _id
        name
      }
      features {
        _id
        name
      }
      isPreview
      publishedOn
      isLive
      isCompleted
     ${eventSettingSchema}
      location {
        venueName
        address1
        address2
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
    }
  }
`;

const GET_EVENT_FEATURES = gql`
  query getEventDetailFeature($eventId: String!) {
    getEventDetailFeature(eventId: $eventId) {
      featureItems {
        featureId {
          _id
          name
        }
      }
    }
  }
`;

const GET_EVENT_LOCATIONS = gql`
  query getEventLocations {
    getEventLocations {
      _id
      venueName
      address1
      address2
      country
      zip
      geometricLocation {
        type
        coordinates
      }
    }
  }
`;

const GET_ALL_EVENT_FEATURES = gql`
  query getAllEventFeatures($offset: Int, $limit: Int) {
    getAllEventFeatures(offset: $offset, limit: $limit) {
      nodes {
        _id
        featureItems {
          _id
          featureId {
            _id
            name
          }
        }
      }
    }
  }
`;

const GLOBAL_SEARCH = gql`
  query searchRecords($searchTerm: String!) {
    searchRecords(searchTerm: $searchTerm) {
      latestEvents{
        _id
        name
        updatedAt
        description
        eventType {
          _id
          name
        }
        publishedOn
        isLive
        isCompleted
        description
        eventType {
          _id
          name
        }
        isPublished
        startDateTime
        endDateTime
        logo { url }
        coverImage { url }
        eventStatus
        
        startDateTime
        endDateTime
        logo {
          url
        }
        coverImage {
          url
        }
        eventStatus
      }
      newRequest {
        _id
        name
        updatedAt
        description
        eventType {
          _id
          name
        }
        publishedOn
        isLive
        isCompleted
        description
        eventType {
          _id
          name
        }
        isPublished
        startDateTime
        endDateTime
        logo { url }
        coverImage { url }
        eventStatus
        
        startDateTime
        endDateTime
        logo {
          url
        }
        coverImage {
          url
        }
        eventStatus
      }
      systemManagers {
         _id
        name
        email
        status
        designation {
          _id
          name
        }
        role{
          _id
          name
        }
        isAssigned
        companyName
        ${imageSchema}
      }
    }
  }
`;

const GET_SURVEY_ANSWERS = gql`
query getDownloadResultForSurveys($eventId: String!) {
  getDownloadResultForSurveys(eventId: $eventId)
}`

const GET_POLL_ANSWERS = gql`
query getDownloadResultForPoll($eventId: String!) {
  getDownloadResultForPoll(eventId: $eventId)
}`

const GET_QUESTION_FOR_DOWNLOAD = gql`
  query getQuestionsForDownload($moduleId: String!) {
    getQuestionsForDownload(moduleId: $moduleId){
      question
      questionDate
      userName
      likesCount
      replies{
        reply
        replyDate
        userName
      }
      comments{
        comment
        commentDate
        userName
      }
    }
}`

const GET_ALL_BANNER = gql`
query getAllBanners($offset: Int, $limit: Int) {
  getAllBanners(offset: $offset, limit: $limit) {
   nodes {
    _id
    name
    description
    linkType
   }
   totalCount
  }
}`

const GET_EVENT_REPORT = gql`
query getEventReport($eventId: String!){
  getEventReport(eventId: $eventId){
    _id 
    name
    description
    slug
    emailAddressToReachYou
    numberOfattendees
    eventType {
      _id
      name
    }
    location {
      venueName
      address1
      address2
      country
      zip
      geometricLocation{
        type
        coordinates
      }
    }
    startDateTime
    endDateTime
    ${privacySettingSchema}
    ${eventSettingSchema}
    eventStatus
    
    features {
      _id
      name
    }
    isPublished
    remark
    isCompleted
    isLive
    publishedOn
    tracks{
      _id
      name
      color
    }
    totalUsers{
      name
      email
      loginTime
      logoutTime
    }
  }
}
`;

export {
  GET_POLL_ANSWERS,
  GET_SURVEY_ANSWERS,
  GET_QUESTION_FOR_DOWNLOAD,
  GET_ALL_BANNER,
  GLOBAL_SEARCH,
  GET_EVENT_FEATURES,
  GET_ALL_EVENTS,
  GET_EVENT_DETAIL,
  GET_ALL_LOOKUPS,
  DELETE_EVENT,
  GET_EVENT_LOCATIONS,
  GET_ALL_EVENT_FEATURES,
  GET_EVENT_REPORT,
};
