import React, { ReactNode } from 'react'
import { Modal } from 'antd';
import './WSModal.scss'

interface Props {
  open?: boolean;
  onCancel?: () => void;
  children?: ReactNode;
  onConfirm?: () => void;
  footer?: Array<ReactNode>;
  className?: string;
  width?: string | number;
  title?: string | ReactNode;
  confirmLoading?: boolean;
}
const WSModal = ({
  open,
  onCancel,
  children,
  onConfirm,
  footer,
  className,
  width,
  title,
  confirmLoading,
}: Props) => {
  return (
    <>
      <Modal
        className={className}
        open={open}
        title={title}
        onOk={onConfirm}
        onCancel={onCancel}
        footer={footer}
        width={width}
        confirmLoading={confirmLoading}
        centered
      >
        {children}
      </Modal>
    </>
  );
};

export default WSModal;