import React, { useState } from 'react'
import { withFormik } from 'formik'
import { CSVLink } from 'react-csv'
import { Upload } from 'antd'
import { MutationFunction } from '@apollo/react-hooks'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { GraphQLError } from 'graphql'
// constants
import images from 'constants/Images'
import { importHeaderTip } from 'utils/ToolTips'
import { BtnVariant, NOTIFICATION_STATUS } from 'constants/Enums'
// components
import { WSButton, WSImage } from 'components/FormComponent'
import {
  GrayDesc,
  MainTitle,
  SubTitle,
} from 'components/common/WSTitle'
import { FeatureModal } from './FeaturesReuseableParts'
import NotificationWithIcon from 'constants/Toster'
import { uploadCSVFile } from 'utils/CommonFunctions'
import {
  IMPORT_FILE,
  SELECT_FILE,
} from 'utils/NotificationMessages'
import ErrorToolTip from 'containers/ErrorToolTip/errorToolTip'
// css
import './import.scss'
const { Dragger } = Upload;
interface ImportProps {
  showModal?: boolean
  onCancel?: () => void
  subTitleName?: string
  fileList?: (File | object)[]
  setFileList?: () => void
  mutationFunction?: MutationFunction
  mutationLoading?: boolean
  eventId?: string
  setFileUpload?: () => void
  fileUpload?: string
  headersArray?: string[]
  scheduleId?: string
}
const ImportModal = ({
  showModal,
  onCancel,
  subTitleName,
  mutationFunction,
  mutationLoading,
  eventId,
  headersArray,
  scheduleId
}: ImportProps) => {
  const [fileUpload, setFileUpload] = useState<UploadFile>()
  const [graphqlError, setGraphqlError] = useState<GraphQLError[]>([])
  const [errorBtn, setErrorBtn] = useState<boolean>(false)
  const { SUCCESS, ERROR } = NOTIFICATION_STATUS
  const modalClose = () => {
    onCancel && onCancel()
    setErrorBtn(false)
    setGraphqlError([])
    setFileUpload(undefined)
  }

  const SaveChanges = () => {
    const id = scheduleId ? { scheduleId } : eventId ? { eventId: eventId } : {};
    (fileUpload?.name &&
      mutationFunction &&
      mutationFunction({ variables: { file: fileUpload, ...id } })
        .then(() => {
          modalClose()
          setErrorBtn(false)
          setGraphqlError([])
          NotificationWithIcon(SUCCESS, IMPORT_FILE)
        })
      .catch((err) => {
        setGraphqlError(err?.graphQLErrors)
        setErrorBtn(true)
        })) ||
      NotificationWithIcon(ERROR, SELECT_FILE)
  }
  return (
    <FeatureModal
      infoMessage={importHeaderTip}
      showModal={showModal}
      onCancel={modalClose}
      title={`Import ${subTitleName}`}
      modalClass='custom-import-download-model'
      footer={[
        <WSButton
          key='close'
          variant={BtnVariant.LINK}
          type='button'
          className='close'
          inlineClass='textbox-label'
          onClick={modalClose}
          label='Close'
        />,
        <WSButton
          key='submit'
          type='button'
          buttonLoading={mutationLoading}
          className='box-shadow-none'
          label='Upload'
          onClick={SaveChanges}
          rightIcon={images.nextArrowWhite}
          width='13px'
          height='15px'
        />,
      ]}
    >
      <div className='import-modal d-flex flex-direction-column '>
        <div className='d-flex '>
          <div className='import-model-number d-flex justify-content-center align-item-center'>
            <span className='font-bold import-number'>1</span>
          </div>
          <div className='download d-flex flex-direction-column'>
            <SubTitle
              name={`Download the ${subTitleName} Template`}
              inlineClassName='title'
            />
            <CSVLink
              filename={`${subTitleName}.csv`}
              data={[]}
              headers={headersArray}
              className='download-btn import-modal-download-btn'
            >
              Download{' '}
              <WSImage
                imagesPath={images.download}
                height={40}
                width={40}
                inlineClass='download-image'
              />
            </CSVLink>
          </div>
        </div>
        <div className='d-flex '>
          <div className='import-model-number d-flex justify-content-center align-item-center'>
            <span className='font-bold import-number'>2</span>
          </div>
          <div className='text-content'>
            <MainTitle name='Add Content' inlineClassName='font-light' />
            <GrayDesc name={text} />
          </div>
        </div>
        <div className='d-flex '>
          <div className='import-model-number d-flex justify-content-center align-item-center'>
            <span className='font-bold import-number'>3</span>
          </div>
          <div className='import-model-upload-file '>
            <GrayDesc name='Upload your file' inlineClassName='title' />
            <Dragger 
              accept=".csv" 
              fileList={fileUpload ? [fileUpload] : []} 
              beforeUpload={(e: File) => uploadCSVFile(e, setFileUpload)} 
              onChange={({ file }: UploadChangeParam) => {
                if (file?.status === "removed") {
                  setGraphqlError([])
                  setErrorBtn(false)
                  setFileUpload(undefined)
                }
              }}
              className="import-model-dragger-image-attachments"
            >
              <WSButton
                name='import-networking-file'
                variant={BtnVariant.BORDERED}
                label='Upload Attachments'
                className="import-model-upload-attachment-btn"
              />
            </Dragger>
            {errorBtn && <ErrorToolTip errors={graphqlError} />}
          </div>
        </div>
      </div>
    </FeatureModal>
  )
}

const ImportModalFormik = withFormik<ImportProps, {}>({
  mapPropsToValues: () => {
    return {}
  },
  handleSubmit: () => { },
})(ImportModal)

export default ImportModalFormik
let text =
  "Add content to the template. Please don't edit the formatting by deleting or renaming columns. More detailed instructions are inside the template file."
