import React from 'react'
import { GraphQLError } from 'graphql'
// components
import WSTable from 'components/common/WSTable'
import WSToolTip from 'components/common/WSTooltip/WSTooltip'
import { WSButton } from 'components/FormComponent'
// css
import './errorToolTip.scss'

interface ErrorProps {
  errors?: GraphQLError[]
}

const ErrorToolTip = ({ errors }: ErrorProps) => {

  const columns = [
    {
      title: 'Row No.',
      render: (text: { [name: string]: string | number }) => (
        <span>{text['rowNumber']}</span>
      ),
    },
    {
      title: 'Errors',
      render: (text: { [name: string]: string | number }) =>
        Object.keys(text)?.map((key) => {
          const { rowNumber, ...noRowNumber } = text
          return (
            <div key={noRowNumber[key]} className='d-flex flex-column'>
              <span>{noRowNumber[key]}</span>
            </div>
          )
        }),
    },
  ]

  const errorLogs = () => {
    return (
      <>
        <WSTable
          className='errors-table'
          columns={columns}
          pagination={false}
          // dataSource={
          //   errors?.length
          //     ? errors[0]?.extensions?.exception?.response?.message
          //     : []
          // }
        />
      </>
    )
  }
  return (
    <>
      <WSToolTip
        // title={errors?.length ? errorLogs : ''}
        placement='top'
        overlayClassName='tooltip-error'
      >
        <div className='errors'>
          <WSButton type='button' className='errors-btn' label='i' />
        </div>
      </WSToolTip>
    </>
  )
}

export default ErrorToolTip
