import React, { ReactNode, useContext } from "react";
import { Card, Row, Col } from "antd";
import { WSImage } from "../components/FormComponent";
import { MainTitle } from "../components/common/WSTitle";
// import { useHistory } from "react-router-dom";
import RouterPath from "../constants/RouterPath";
import { EventContextProps } from "../constants/GlobalEventTypeScript";
import EventDetailContext from "../UserContext";
import { useLocation, useNavigate } from "react-router-dom";

interface TableCardProps {
  eventTitle?: string;
  rightContent?: React.ReactNode;
  imagePath?: string;
  totalQuestions?: string;
  redirectPathName?: string;
  state?: object | number | string;
  divClassName?: string;
  tableHeaderContent?: ReactNode
  stepCurrent?: number
}

const TableCard = (props: TableCardProps) => {
  const {
    eventTitle,
    rightContent,
    imagePath,
    redirectPathName = RouterPath.addEvents,
    state: propState,
    tableHeaderContent,
    divClassName,
  } = props;
  // const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const EventContext: EventContextProps = useContext(EventDetailContext);
  const { storeFieldData } = EventContext;
  const { notifications } = RouterPath
  const onClick = () => {
    if (location?.state?.fromNotification) {
      navigate(notifications)
    }
    else {
      storeFieldData(location?.state?.fieldDataStorage);
      navigate(redirectPathName, {state:{
        ...location?.state,
        current: 1,
    }});
    }
  }
  return (
    <div>
      {eventTitle && (
        <Row className="padding-top-bottom">
          <Col sm={24} md={6} lg={5}>
            <div
              className="d-flex align-item-center justify-content-center float-l cursor-pointer"
              onClick={onClick}
            >
              <WSImage imagesPath={imagePath} width="20px" />
              <MainTitle name={eventTitle} inlineClassName="mb-0 ml-10" />
            </div>
          </Col>
          {rightContent}
        </Row>
      )}

      <div>
        <Card className={`card-table ${divClassName}`}>
          {tableHeaderContent}
        </Card>
      </div>
    </div>
  );
};


export default TableCard;
