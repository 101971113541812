import React from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
 
interface Props{
  center?: Coords;
  zoom?: number;
  className?: string;
}

const GoogleMap = (props: Props) => {
 
  const {center, zoom, className} = props;

    return (
      <div className={className}>
        <GoogleMapReact
          {...props}
          center={center}
          defaultCenter={center}
          defaultZoom={zoom}
        >
        </GoogleMapReact>
      </div>
    );
}
 
export default GoogleMap;