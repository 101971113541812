import gql from 'graphql-tag'
import { eventSettingSchema, privacySettingSchema } from '../CommonSchemas'

const SAVE_GENERAL_SETTINGS = gql`
  mutation saveEventSetting($input: CreateEventSettingInput!) {
    saveEventSetting(input: $input) {
      _id
      name
      description
      eventType {
        _id
        name
      }
      isPublished
      startDateTime
      endDateTime
      eventStatus
      publishedOn
      isLive
      isCompleted
      ${eventSettingSchema}
    }
  }
`

const PREVIEW_EVENT = gql`
  mutation previewEvent($eventId: String!) {
    previewEvent(eventId: $eventId)
  }
`

const PUBLISH_EVENT = gql`
  mutation publishEvent($eventId: String!) {
    publishEvent(eventId: $eventId) {
      _id
      slug
      name
      updatedAt
      description
      emailAddressToReachYou
      numberOfattendees
      eventType {
        _id
        name
      }
      location {
        _id
        venueName
        address1
        address2
        city
        state
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
      publishedOn
      isLive
      isCompleted
      isPublished
      isPreview
      ${eventSettingSchema}
      startDateTime
      endDateTime
      ${privacySettingSchema}
      eventStatus
    }
  }
`

const CREATE_EVENT_STEP1 = gql`
  mutation createEvent($file: ExcelUpload, $input: ManageEventInput!) {
    createEvent(file: $file, input: $input) {
      _id
      name
      updatedAt
      description
      emailAddressToReachYou
      numberOfattendees
      eventType {
        _id
        name
      }
      location {
        _id
        venueName
        address1
        address2
        city
        state
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
      publishedOn
      isLive
      isCompleted
      isPublished
      isPreview
      ${eventSettingSchema}
      startDateTime
      endDateTime
      ${privacySettingSchema}
      eventStatus
    }
  }
`

const INVITE_USERS = gql`
  mutation inviteUsers($eventId: String!, $settingId: String!, $file: Upload!) {
    inviteUsers(eventId: $eventId, settingId: $settingId, file: $file)
  }
`

const UPDATE_EVENT = gql`
  mutation updateEvent($file: ExcelUpload, $input: UpdateEventInput!) {
    updateEvent(file: $file, input: $input) {
      _id
      name
      updatedAt
      description
      emailAddressToReachYou
      numberOfattendees
      eventType {
        _id
        name
      }
      location {
        _id
        venueName
        address1
        address2
        city
        state
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
      publishedOn
      isLive
      isCompleted
      isPublished
      isPreview
      ${eventSettingSchema}
      startDateTime
      endDateTime
      ${privacySettingSchema}
      eventStatus
    }
  }
`

const GENERATE_EVENT_CODE = gql`
  mutation generateSettingCode {
    generateSettingCode
  }
`

const CREATE_UPDATE_EVENT_FEATURE = gql`
  mutation createOrUpdateEventFeature($input: EventFeaturesInput!) {
    createOrUpdateEventFeature(input: $input) {
      _id
      featureItems {
        featureId {
          _id
          name
        }
      }
    }
  }
`

const DELETE_EVENT_FEATURE = gql`
  mutation deleteEventFeature($id: String!) {
    deleteEventFeature(id: $id) {
      _id
    }
  }
`

const ASSIGN_SYSTEM_USER_TO_EVENT = gql`
  mutation assignSystemUserToEvent($updateEvent: AssignUserToEvent!) {
    assignSystemUserToEvent(updateEvent: $updateEvent) {
      _id
      systemUsers {
        _id
      }
    }
  }
`

const SET_EVENT_PREVIEW_STATUS = gql`
  mutation setEventPreviewStatus($value: Boolean!, $id: String!) {
    setEventPreviewStatus(value: $value, id: $id) {
      _id
      name
    }
  }
`

const CHANGE_EVENT_STATUS = gql`
  mutation changeEventStatus(
    $value: Boolean!
    $eventId: String!
    $remark: String
  ) {
    changeEventStatus(value: $value, eventId: $eventId, remark: $remark) {
      _id
      name
    }
  }
`

const CLONE_EVENT = gql`
  mutation cloneEvent($input: CloneEventInput!) {
    cloneEvent(input: $input) {
      _id
      name
    }
  }
`

const REMOVE_FEATURE_FROM_EVENT = gql`
  mutation removefeaturesFromEvent($eventId: String!, $featureId: String!) {
    removefeaturesFromEvent(eventId: $eventId, featureId: $featureId) {
      _id
    }
  }
`

const SAVE_EVENT_BANNER = gql`
  mutation saveEventBanner($input: SaveBannerInput!) {
    saveEventBanner(input: $input) {
       _id
      startDateTime
      endDateTime
      name
      slug
      isPublished
      description
      emailAddressToReachYou
      numberOfattendees
      createdBy {
        name
        companyName
        email
      }
      ${privacySettingSchema}
      eventType {
        _id
        name
      }
      features {
        _id
        name
      }
      ${eventSettingSchema}
      isPreview
      publishedOn
      isLive
      isCompleted
      location {
        venueName
        address1
        address2
        country
        zip
        geometricLocation {
          type
          coordinates
        }
      }
    }
  }
`
export {
  SAVE_EVENT_BANNER,
  PREVIEW_EVENT,
  INVITE_USERS,
  GENERATE_EVENT_CODE,
  PUBLISH_EVENT,
  SAVE_GENERAL_SETTINGS,
  CREATE_EVENT_STEP1,
  UPDATE_EVENT,
  ASSIGN_SYSTEM_USER_TO_EVENT,
  SET_EVENT_PREVIEW_STATUS,
  CHANGE_EVENT_STATUS,
  CLONE_EVENT,
  CREATE_UPDATE_EVENT_FEATURE,
  DELETE_EVENT_FEATURE,
  REMOVE_FEATURE_FROM_EVENT,
}
