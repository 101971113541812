import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import RouterPath from 'constants/RouterPath'
import LiveStreamTable from 'pages/event/eventForms/Livestream/LiveStreamTable'




const Home = React.lazy(() => import('pages/home'))
const SettingsPage = React.lazy(() => import('pages/settings/SettingsPage'))
const GmailTemplate = React.lazy(
  () => import('pages/template/manageTemplate')
)

const SMTPTemplate = React.lazy(() => import('pages/template/manageSmtp'))
const AddSMTP = React.lazy(
  () => import('pages/template/addSmtpFormikHandler')
)
const AddGmailTemplate = React.lazy(
  () => import('pages/template/addGmailTemplateFormikHandler')
)

const Search = React.lazy(() => import('pages/search'))

const LoginScreen = React.lazy(
  () => import('pages/auth/login/loginFormHandler')
)
const OtpScreen = React.lazy(() => import('pages/auth/otp'))
const SetNewPassword = React.lazy(
  () => import('pages/auth/password/newPasswordHandler')
)
const ForgotPassword = React.lazy(
  () => import('pages/auth/password/forgotPasswordHandler')
)
const ChangePassword = React.lazy(
  () => import('pages/auth/password/ChangePasswordHandler')
)
const Status = React.lazy(() => import('pages/auth/status'))

const ActiveEvents = React.lazy(
  () => import('pages/event/EventList/activeEvents')
)
const EventsList = React.lazy(
  () => import('pages/event/EventList/eventsList')
)
const Networking = React.lazy(
  () => import('pages/event/eventForms/Networking/networking')
)
const BookMeetings = React.lazy(
  () => import('pages/event/eventForms/Book Meetings/bookMeetings')
)
const Announcements = React.lazy(
  () => import('pages/event/eventForms/Annoncements/announcements')
)
const Presenters = React.lazy(
  () => import('pages/event/eventForms/Presenters/presenters')
)
const Recordings = React.lazy(
  () => import('pages/event/eventForms/Recordings/recordings')
)
const Slides = React.lazy(
  () => import('pages/event/eventForms/Slides/slides')
)
const StreamOptions = React.lazy(
  () => import('pages/event/eventForms/Stream Option/streamOptions')
)
const Schedule = React.lazy(
  () => import('pages/event/eventForms/Schedule/schedule')
)
const Sponsors = React.lazy(
  () => import('pages/event/eventForms/Sponsors/sponsors')
)
const HelpDesk = React.lazy(
  () => import('pages/event/eventForms/HelpDesk/HelpDeskHandler')
)
const EventForm = React.lazy(() => import('pages/event/eventForms'))
const LivePolls = React.lazy(
  () => import('pages/event/eventForms/LivePolls/livePolls')
)
const AddTotalUsers = React.lazy(
  () => import('pages/Users/totalUsers/totalUsers')
)
const SystemManagers = React.lazy(() => import('pages/Users/systemManagers'))
const AddSystemManagers = React.lazy(
  () => import('pages/Users/systemManagers/systemManagers')
)
const ManageSystemManagers = React.lazy(
  () => import('pages/Users/Profiles/ManagerUserProfileHandler')
)
const Survey = React.lazy(
  () => import('pages/event/eventForms/Survey/survey')
)
const TotalUsers = React.lazy(() => import('pages/Users/totalUsers'))
const ManageTotalUser = React.lazy(
  () => import('pages/Users/Profiles/ManagerUserProfileHandler')
)
const Notifications = React.lazy(() => import('pages/notifications'))
const NewRequest = React.lazy(() => import('pages/newRequest'))
const ReportAnalytics = React.lazy(() => import('pages/reportAnalytics'))
const ViewProfile = React.lazy(
  () => import('pages/Users/Profiles/ViewProfileHandler')
)
const AMASession = React.lazy(
  () => import('pages/event/eventForms/AMA Session/AMASession')
)
const AppRouter: React.FC = () => {
  const location = useLocation()
  const options ={
    location:location as any
  }
  return (
    <Routes>
      <Route  path={RouterPath.homeScreen} element={<Home/>} />
      <Route  path={RouterPath.settings} element={<SettingsPage />} />
      <Route  path={RouterPath.searchScreen} element={<Search />} />
      {/* Authentication */}
      <Route path={RouterPath.activeEvents} element={<ActiveEvents />}/>
      <Route  path={RouterPath.eventsList} element={<EventsList />} />
      <Route  path={RouterPath.networking} element={<Networking />} />
      <Route path={RouterPath.bookMeetings} element={<BookMeetings/>}/>
      <Route path={RouterPath.announcements} element={<Announcements/>}/>
      <Route  path={RouterPath.presenters} element={<Presenters/>} />
      <Route  path={RouterPath.recordings} element={<Recordings />} />
      <Route  path={RouterPath.slides} element={<Slides />} />
      <Route path={RouterPath.streamOptions} element={<StreamOptions />}/>
      <Route path={RouterPath.liveStream} element={<LiveStreamTable />}/>
      <Route  path={RouterPath.schedule} element={<Schedule/>} />
      <Route  path={RouterPath.sponsors} element={<Sponsors />} />
      <Route  path={RouterPath.livePolls} element={<LivePolls />} />
      <Route  path={RouterPath.helpDesk} element={<HelpDesk />} />
      <Route  path={RouterPath.survey} element={<Survey />} />
      <Route  path={RouterPath.AMASession} element={<AMASession />} />
      <Route path={RouterPath.SMTPScreen} element={<SMTPTemplate />}/>
      <Route path={RouterPath.emailScreen} element={<GmailTemplate />}/>
      <Route  path={RouterPath.addSmtpScreen} element={<AddSMTP />} />
      <Route path={RouterPath.addEmailScreen} element={<AddGmailTemplate />}/>
      <Route  path={RouterPath.errorScreen} element={<Status />} />
      {/* event */}
       <Route  path={RouterPath.addEvents} element={<EventForm />} />
      <Route  path={RouterPath.totalUsers} element={<TotalUsers />} />
      <Route path={RouterPath.addTotalUsers} element={<AddTotalUsers />}/>
      <Route path={RouterPath.systemManagers} element={<SystemManagers />}/>
      <Route path={RouterPath.addSystemManagers}element={<AddSystemManagers />}/>
       <Route path={RouterPath.manageSystemManagers} 
         // render={(props) => <ManageSystemManagers initValue={props.history} />} 
         element={<ManageSystemManagers {...options}/>}/> 
      <Route path={RouterPath.manageTotalUsers}
        // render={(props) => <ManageTotalUser initValue={props.history} />}
        element={ <ManageTotalUser {...options} />}/>
      <Route path={RouterPath.notifications} element={<Notifications />}/>
      <Route  path={RouterPath.newRequest} element={<NewRequest/>} />
      <Route path={RouterPath.reportAnalytics} element={<ReportAnalytics/>}/>
      <Route path={RouterPath.viewProfile} element={<ViewProfile/>} />
      <Route path={RouterPath.changePassword} element={<ChangePassword/>}/>  
      {/* <Redirect to='/'  /> */}
      <Route path='*' element={<Home/>} />
    </Routes>
  )
}

const AuthRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={RouterPath.loginScreen} element={<LoginScreen />}/>
      <Route path={RouterPath.optScreen} element={<OtpScreen />}/>
      <Route path={RouterPath.forgotPasswordScreen} element={<ForgotPassword />}/>
      <Route path={RouterPath.setNewPassword} element={<SetNewPassword />}/>
      <Route  path={RouterPath.errorScreen} element={<Status />} /> 
      {/* <NavLink to='/login'  /> */}
      <Route path='*' element={<LoginScreen/>} />
    </Routes>
  )
}

export { AppRouter, AuthRouter }
