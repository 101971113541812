// import * as React from 'react'
// import { DatePicker } from 'antd'
// import { Field, FieldProps } from 'formik';
// import Labels from './Labels/Labels';
// import moment from 'moment'
// import dayjs from 'dayjs'
// import MyErrorMessage from "components/FormComponent/WSErrorMessage";
// interface Props {
//   label?: string;
//   onChange?: () => void;
//   style?: any;
//   divClassName?: string
//   className?: string;
//   required?: boolean;
//   infoMessage?: string;
//   data?: string;
//   placeholder?: string;
//   name: string
//   labelClass?: string
//   disabled?: boolean
// }

// const DateComponent = (props: Props) => {
//   const { label, divClassName, className, placeholder, required, name, infoMessage, labelClass, disabled } = props;
//   function disabledDate(current: any) {
//     // Can not select days before today
//     return current && current.valueOf() < moment().startOf('day');
//   }
  
//   return (
//     <div className={divClassName}>
//       {label && <Labels required={required} infoMessage={infoMessage} label={label} labelClass={labelClass} />}
//       <Field name={name}>
//         {({ form }: FieldProps) => (
//           <>
//             <DatePicker
//               disabledDate={disabledDate}
//               // value={dayjs(form.values[name] || moment(), 'YYYY-MM-DD')}
//               value={form.values[name]?dayjs(form.values[name], 'YYYY-MM-DD'):dayjs()}
//               // defaultValue={form.values[name] ? dayjs(form.values[name],'YYYY-MM-DD') : dayjs()} 
//               className={`width-100 ${className}`}
//               format="MM-DD-YYYY"
//               placeholder={placeholder}
//               onChange={(e, e2) => {
//                 form.setFieldValue(name, moment(moment(e2), "MM-DD-YYYY").format("YYYY-MM-DD") || moment(moment(), "MM-DD-YYYY").format("YYYY-MM-DD"))}}
//               onBlur={() => form.setFieldTouched(name, true)}
//               disabled={disabled}
//             />
//             <MyErrorMessage name={name} />
//           </>
//         )}
//       </Field>
//     </div>
//   );
// }

// export default DateComponent


import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field, FieldProps } from 'formik';
import Labels from './Labels/Labels';
import moment from 'moment';
import MyErrorMessage from 'components/FormComponent/WSErrorMessage';

interface Props {
  label?: string;
  onChange?: () => void;
  style?: any;
  divClassName?: string;
  className?: string;
  required?: boolean;
  infoMessage?: string;
  data?: string;
  placeholder?: string;
  name: string;
  labelClass?: string;
  disabled?: boolean;
}

const DateComponent = (props: Props) => {
  const { label, divClassName, className, placeholder, required, name, infoMessage, labelClass, disabled } = props;

  return (
    <div className={divClassName}>
      {label && <Labels required={required} infoMessage={infoMessage} label={label} labelClass={labelClass} />}
      <Field name={name}>
        {({ form }: FieldProps) => (
          <>
            <DatePicker
              disabled={disabled}
              disabledKeyboardNavigation
              selected={form.values[name] ? moment(form.values[name], 'YYYY-MM-DD').toDate() : undefined}
              className={`width-100 ${className} ant-picker`}
              dateFormat="MM-dd-yyyy"
              placeholderText={placeholder}
              onChange={(date:any) => {
                form.setFieldValue(name, moment(date).format('YYYY-MM-DD') || '');
              }}
              onBlur={() => form.setFieldTouched(name, true)}
              minDate={moment().toDate()}
            />
            <MyErrorMessage name={name} />
          </>
        )}
      </Field>
    </div>
  );
};

export default DateComponent;
