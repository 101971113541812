import React, { useMemo, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import 'react-quill/dist/quill.snow.css'
import { useLazyQuery } from '@apollo/react-hooks'
import { Field, FieldProps } from 'formik'
import clsx from 'clsx'

import MyErrorMessage from '../WSErrorMessage'
import Labels from 'components/common/Labels/Labels'
import Loader from '../Loader'

import { uploadFile } from 'utils/S3FileUpload'
import { beforeUpload } from 'utils/CommonFunctions'
import NotificationWithIcon from 'constants/Toster'
import { NOTIFICATION_STATUS } from 'constants/Enums'
import { GET_TEMPLATE_URL } from 'graphQl/Template/Schema'

import './WSTextEditor.scss'

// for adding image attributes into quill-editor
// start
Quill.register('modules/imageResize', ImageResize)

const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style',
  'src',
  'class',
]

class ImageFormat extends BaseImageFormat {
  static formats(domNode: HTMLImageElement) {
    return ImageFormatAttributesList.reduce(function (
      formats: { [name: string]: string },
      attribute: string
    ) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute) || ''
      }
      return formats
    },
    {})
  }
  format(name: string, value: string) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register(ImageFormat, true)
// end

interface EditorProps {
  name?: string
  label?: string
  infoMessage?: string
  required?: boolean
  disabled?: boolean
  isUploadImage?: boolean
}
let quill: ReactQuill | null

const Editor = ({
  infoMessage = 'A brief paragraph to describe this item.',
  label = 'Description',
  name = 'desc',
  required = true,
  disabled,
  isUploadImage = false,
}: EditorProps) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [getTemplateUrl, { data }] = useLazyQuery(GET_TEMPLATE_URL, {
    onCompleted: () => {
      const range = quill?.getEditor().getSelection() || { index: 0 }
      setTimeout(() => {
        quill
          ?.getEditor()
          .insertEmbed(range.index, 'image', `${data?.getTemplateimageUrl}`)
        quill?.getEditor().enable(true)
        setShowLoader(false)
      }, 2000)
    },
    onError: (err) =>
      NotificationWithIcon(NOTIFICATION_STATUS.ERROR, err.message),
  })

  // custom image handler
  const imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file: File =
        (input.files && input.files?.length && input.files[0]) || ({} as File)
      const isFileOk = beforeUpload(file, 5)
      if (isFileOk) {
         quill?.getEditor().enable(false)
         setShowLoader(true)
        const res = await uploadFile(file, null)
        await getTemplateUrl({
          variables: { key: res?.length ? res[0].key : '' },
        })
      } else {
        console.debug(
          'Image was not uploaded due to file is not fulfilling requirements'
        )
      }
    }
  }

  // useMemo used to stop infinite re-rendering caused by async handler function
  const modules = useMemo(() => {
    return {
      // to resize image
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      toolbar: {
        container: [
          [{ size: [] }],
          ['bold', 'italic', 'underline'],
          ['clean'],
          [{ color: [] }, { background: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
          isUploadImage ? ['link', 'image'] : ['link'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }
  }, [])
  return (
    <>
      {name && (
        <Labels
          required={required}
          infoMessage={infoMessage}
          label={label || name}
        />
      )}
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <div>
            <ReactQuill
              ref={(el) => {
                quill = el
              }}
              readOnly={disabled}
              className={clsx('text-editor', { 'opacity-4': showLoader })}
              theme='snow'
              onChange={field.onChange(field.name)}
              value={field.value || ''}
              modules={modules}
              onBlur={() => form.setFieldTouched(name, true)}
            />
            {showLoader && <Loader divClassName='quill-loader' />}
            <MyErrorMessage name={name} />
          </div>
        )}
      </Field>
    </>
  )
}
export default Editor
