import React from 'react';
// import ReactDOM from 'react-dom';
import 'assets/css/index.scss';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import {createRoot} from 'react-dom/client';
// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );
const rootElement = document.getElementById("root")
const root = createRoot(rootElement as HTMLElement)
root.render(
  <App />,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
