import React from "react";
// import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
// constant
import images from "constants/Images";
import { BtnVariant } from "constants/Enums";
import { UserWithThumbnailProps } from "constants/GlobalEventTypeScript";
import RouterPath from "constants/RouterPath";
import { getBrowserSignature } from "Signature";
// component
import { WSButton, WSImage } from "../FormComponent";
import { GrayDesc, SmallBrownText, SubTitle } from "./WSTitle";
import Confirmation from "containers/ConfirmAction/ConfirmAction";
import WSToolTip from "./WSTooltip/WSTooltip";
import { GET_USER_DETAIL } from "graphQl/Profiles/Schema";
// styles
import "pages/Users/Profiles.scss";
import { Navigate, Route, useNavigate } from "react-router-dom";

interface UserProps {
  data: UserWithThumbnailProps;
  spanClass?: string;
  divClass?: string;
  viewDetails?: boolean;
  noDescription?: boolean;
  desClassName?: string;
  onClick?: (id?: string) => any;
}

export const UserWithThumbnail = ({
  data,
  divClass,
  spanClass,
  viewDetails,
  noDescription = false,
  desClassName,
  onClick,
}: UserProps) => {
  const {
    _id = '',
    companyName = '',
    desc = '',
    designation = { name: '' },
    image = { url: '' },
    name = '',
    logo = { url: '' },
  } = data || {}

  return (
    <div className="d-flex justify-content-between width-100">
      <div
        className={`${!viewDetails && "cursor-pointer"} ${spanClass}`}
        onClick={
          (onClick && !viewDetails && (() => onClick(_id)))  as any
      } 
      >
        <WSImage
          imagesPath={
            image?.url || logo?.url || data?.thumbnail || images.defaultUser
          }
          width={40}
          height={40}
          inlineClass="border-red-50"
        />
        <div className={`ml-20 ${divClass}`}>
          <SubTitle name={name || "-"} inlineClassName="text-transform-cap" />
          {!noDescription && (
            <>
              {!desc && (
                <GrayDesc
                  inlineClassName={desClassName}
                  name={`${designation?.name || "-"} • ${companyName || "-"}`}
                />
              )}
              {desc && (
                <div className="d-flex flex-direction-column">
                  <GrayDesc
                    inlineClassName={desClassName}
                    name={designation?.name}
                  />
                  <GrayDesc name={desc} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {viewDetails && (
        <SmallBrownText
          name="View Details"
          inlineClassName="d-flex flex-shrink-0 justify-content-center align-item-center cursor-pointer"
          onClick={onClick && (() => onClick(_id))}
        />
      )}
    </div>
  );
};

interface UserManageBtnsProps {
  onConfirm?: () => void;
  setIsEdit?: Function;
  loading?: boolean;
  open?: boolean;
  onEdit?: () => void;
  setOpen?: (e: boolean) => void;
  editIcon?: string;
  isPreview?: boolean;
  isDelete?: boolean
  isEdit?: boolean
}

export const UserManageBtns = ({
  onConfirm,
  open,
  setOpen,
  loading,
  onEdit,
  setIsEdit,
  editIcon,
  isPreview = true,
  isDelete = true,
  isEdit = true
}: UserManageBtnsProps) => {
  const { pencilEdit, eyeBlack, deleteRed } = images;
  function RenderButton(
    state: boolean,
    icon: string,
    className: string,
    width: number,
    title?: string,
    onClick?: () => void
  ) {
    return (
      <WSToolTip title={title} placement="top">
        <div className="tooltip-content ml-1">
          <WSButton
            variant={BtnVariant.BORDERED}
            className={className}
            leftImageClass="m-0"
            label=""
            onClick={
              onClick ||
              (onEdit &&
                (() => {
                  onEdit();
                  setIsEdit && setIsEdit(!state);
                })) || (() => { })
            }
            leftIcon={icon}
            width={width}
          />
        </div>
      </WSToolTip>
    );
  }
  const onCancel = () => {
    setOpen && setOpen(false);
  };

  return (
    <div className="d-flex align-item-center">
      {isEdit && RenderButton(
        true,
        editIcon || pencilEdit,
        "table-edit-btn cursor-pointer",
        17,
        editIcon ? "Comments" : "Edit"
      )}
      {isPreview &&
        RenderButton(
          false,
          eyeBlack,
          "table-eye-btn cursor-pointer",
          20,
          "Preview"
        )}
      {isDelete && RenderButton(
        false,
        deleteRed,
        "table-delete-btn cursor-pointer",
        15,
        "Delete",
        () => setOpen && setOpen(true)
      )}
      <Confirmation
        showModal={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        loading={loading}
      />
    </div>
  );
};
