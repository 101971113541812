import { CallMutation } from "graphQl/graphqlHandler";
import {
  ApolloCache,
  DocumentNode,
  FetchResult,
  TypedDocumentNode,
  MutationFunction, QueryLazyOptions
} from "@apollo/react-hooks";
import moment, { Moment } from "moment";
import {
  CheckboxTitle,
  DATE_FORMATS,
  NOTIFICATION_STATUS,
} from "constants/Enums";
import NotificationWithIcon from "constants/Toster";
import {
  ID_ERROR,
  NEW_ITEM_CREATED,
  NEW_ITEM_UPDATED,
  RECORD_DELETED,
} from "./NotificationMessages";
import { ExternalLinkProps } from "constants/GlobalEventTypeScript";

export const camelCaseToNormalCase = (word: string) => {
  const result = word && word.replace(/([A-Z])/g, " $1");
  const finalResult =
    result && result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const getLocalStorageItem = (key = "") => {
  return localStorage.getItem(key) || null;
};

export const setLocalStorageItem = (key = "", string = "") => {
  return localStorage.setItem(key, string);
};

export const removeLocalStorageItem = (key = "") => {
  return localStorage.removeItem(key);
};

export const nameFormatter = (stringArray: string[], separator = " ") => {
  return stringArray.filter(Boolean).join(separator);
};

export const splitUrlFromUnderscore = (
  splitValue: string,
  url: string,
  pos?: any
) => {
  const splitUrl = url ? url.split(splitValue) : "";

  return splitUrl[pos || splitUrl.length - 1];
};

export const localStorageClear = () => {
  return localStorage.clear();
};

export const objectToSingleDataArray = (arr: any[], key: any, passed: any) => {
  let output;

  if (key && passed) {
    output =
      arr &&
      arr.map((data) => {
        return {
          value: data[passed],
          label: data[key],
        };
      });
  } else {
    output =
      arr &&
      arr.map((data) => {
        return {
          value: data.value,
          label: data.label,
        };
      });
  }

  return output;
};

export const getLocalInfo = (key = "user_info") => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key) || "")
    : false;
};

export const mergeByProperty: any = (
  target: any[],
  source: any[],
  prop: string
) => {
  return (
    target &&
    target.map((item) => {
      return {
        ...item,
        ...source.find((element) => element?.[prop] === item?.[prop]),
      };
    })
  );
};

export const splitAddress = (address: string) => {
  return address.split(",");
};

export const requiredMessage = (inputName: string) => {
  return `${inputName} is required.`;
};

export const removePTag = (data: string) => {
  const first = data?.replace("<p>", "");
  const sec = first?.replace("</p>", "");
  return sec?.replace("&nbsp;", " ");
};

export const invalidMessage = (inputName: string) => {
  return `Invalid ${inputName}.`;
};

// convert long date into sort
export const dateConvert = (date: Date) => {
  return JSON.stringify(date).slice(1, 11);
};

export const dateToISOFormat = (date: string) => {
  return moment(date).toISOString()
}

export const isoToFormat = (date: string, format: string) => {
  return date ? moment(date).format(format) : moment().format(format)
}

// Date Formate
export const dateFormate = (
  startDate: string | undefined | null,
  endDate: string | undefined | null
) => {
  const { MMM_D_YYYY, D_YYYY, ll, MMM_D } = DATE_FORMATS
  if (startDate !== endDate) {
    const dateCompare = moment(startDate).isSame(
      moment(endDate),
      "day"
    );
    const monthCompare = moment(startDate).isSame(
      moment(endDate),
      "month"
    );
    const yearCompare = moment(startDate).isSame(
      moment(endDate),
      "year"
    );
    return (
      (dateCompare && isoToFormat(startDate || "", ll)) || // month,date,year same.
      (monthCompare &&
        isoToFormat(startDate || "", `${MMM_D} - `).concat(
          isoToFormat(endDate || "", D_YYYY)
        )) || // month same date and year diff.
      (yearCompare &&
        isoToFormat(startDate || "", `${MMM_D} - `).concat(
          isoToFormat(endDate || "", MMM_D_YYYY)
        )) || // year same,month and date diff.
      (!yearCompare &&
        isoToFormat(startDate || "", `${MMM_D_YYYY} - `).concat(
          isoToFormat(endDate || "", MMM_D_YYYY)
        ))
    ); // year diff.
  }
};

export const addDataInCache = (alreadyAdded: Array<any>, newRecord?: object | Array<any>) => {
  return [{ ...newRecord }, ...alreadyAdded];
};

export const removeDataInCache = (
  alreadyAddedArr: any = [],
  compareData: string
) => {

  return alreadyAddedArr.filter(
    (item: { _id: string }) => item._id !== compareData
  );
};

export const updateAPI = (
  cache: ApolloCache<Function>,
  APIResponse: any,
  existingAPI: DocumentNode | TypedDocumentNode,
  addVariables: Object,
  getAPISchemaName: string,
  createAPISchemaName: string,
  updateAPISchemaName: string,
  cancelModal: (() => void) | undefined,
  messageName: string,
  updateCondition: string
) => {
  const existingData: any = cache.readQuery({
    query: existingAPI,
    variables: addVariables,
  });
  const filteredData = updateCondition
    ? removeDataInCache(
      existingData[getAPISchemaName].nodes,
      APIResponse[updateAPISchemaName]._id
    )
    : addDataInCache(
      existingData[getAPISchemaName].nodes,
      APIResponse[createAPISchemaName]
    );
  const updatedData = updateCondition
    ? addDataInCache(filteredData, APIResponse[updateAPISchemaName])
    : filteredData;
  cache.writeQuery({
    query: existingAPI,
    variables: addVariables,
    data: { [getAPISchemaName]: { nodes: updatedData, totalCount: updateCondition ? existingData[getAPISchemaName].totalCount : existingData[getAPISchemaName].totalCount + 1 } },
  });
  cancelModal && cancelModal();
  NotificationWithIcon(
    NOTIFICATION_STATUS.SUCCESS,
    updateCondition
      ? NEW_ITEM_UPDATED(messageName)
      : NEW_ITEM_CREATED(messageName)
  );
};

export const DeleteAPI = (
  mutation: MutationFunction,
  deleteId: string,
  existingAPI: DocumentNode | TypedDocumentNode,
  getAPISchemaName: string,
  deleteAPISchemaName: string,
  addVariables: Object,
  setOpen: (e: boolean) => void,
) => {
  CallMutation(mutation, {
    variables: { id: deleteId },
    update(
      cache: ApolloCache<Function>,
      { data: APIResponse }: FetchResult<any>
    ) {
      setOpen(false);
      const cacheObj = { query: existingAPI, variables: addVariables };
      const existingData: any = cache.readQuery({
        ...cacheObj,
      });
      const filteredData = removeDataInCache(
        existingData[getAPISchemaName].nodes,
        APIResponse[deleteAPISchemaName]._id
      );
      cache.writeQuery({
        ...cacheObj,
        data: {
          [getAPISchemaName]: { nodes: filteredData },
          totalCount: existingData[getAPISchemaName].totalCount - 1,
        },
      });
      NotificationWithIcon(NOTIFICATION_STATUS.SUCCESS, RECORD_DELETED);
    },
  });
};

export const GetExternalLinksArray = (data: ExternalLinkProps[]) => {
  const { DATA_TO_URL } = CheckboxTitle;
  const finalData = data?.map((item) => {
    if (item?.linkType === "Website") {
      const dataToUrl = item?.passdataToUrl && [DATA_TO_URL] || []
      return { ...item, externalLinkSelect: dataToUrl }
    }
    else return { ...item }
  })
  return finalData
};

export function GetInputsWithExternalLinks(
  inputs: object,
  externalLinks: ExternalLinkProps[],
) {
  const externalLinkData =
    (externalLinks?.length &&
      externalLinks?.filter((item) => item.linkType !== "")) ||
    [];
  const finalData = externalLinkData?.length ? externalLinkData?.map(item => {
    const { externalLinkSelect, ...noSelect } = item
    if (item?.linkType === "Website") {
      if (item?.externalLinkSelect?.length) {
        return {
          ...noSelect,
          openlinkExternalBrowser: true,                                                   
          passdataToUrl: true
        }
      }
      else return { ...noSelect, openlinkExternalBrowser: true, passdataToUrl: false }
    } else return { ...noSelect }
  }) : []
  const inputToReturn = { ...inputs, externalLinks: finalData };
  return inputToReturn;
}

export function getSingleData(queryFunction: (options?: QueryLazyOptions<Record<string, any>>) => void, variables: { id?: string, eventId?: string, fingerPrint?: string }) {  
  JSON.stringify(variables) !== "{}"
    ? queryFunction({ variables: { ...variables } })
    : NotificationWithIcon(NOTIFICATION_STATUS.ERROR, ID_ERROR);
}

export const checkIsAllowQaUndefined = (isAllowQa: boolean | undefined) => {
  return isAllowQa === undefined ? true : isAllowQa
}