const RouterPath = {
  homeScreen: "/",
  searchScreen: "/search",
  settings: "/settings",
  // Authentication
  loginScreen: "/login",
  optScreen: '/otp-verification/:id',
  forgotPasswordScreen: "/forgot-password-email",
  errorScreen: "/status",
  addSmtpScreen: "/add-smtp",
  setNewPassword: "/forgot-password/:id",
  changePassword: "/change-password",
  // event
  addEvents: "/events-list/add",
  activeEvents: "/active-events",
  eventsList: "/events-list",
  networking: "/networking",
  bookMeetings: "/bookmeetings",
  announcements: "/announcements",
  presenters: "/presenters",
  recordings: "/recordings",
  slides: "/slides",
  streamOptions: "/streamoptions",
  schedule: "/schedule",
  sponsors: "/sponsors",
  livePolls: "/livePolls",
  helpDesk: "/helpDesk",
  survey: "/survey",
  SMTPScreen: "/smtp-manager",
  addEmailScreen: "/add-template",
  emailScreen: "/template-manager",
  totalUsers: "/total-users",
  manageTotalUsers: "/total-users/manage",
  addTotalUsers: "/total-users/add",
  systemManagers: "/system-managers",
  addSystemManagers: "/system-managers/add",
  manageSystemManagers: "/system-managers/manage",
  notifications: "/notifications",
  newRequest: "/newRequest",
  reportAnalytics: "/report-analytics",
  viewProfile: '/view-profile',
  AMASession: '/AMA-session',
  liveStream: '/live-stream'
};

export default RouterPath;
