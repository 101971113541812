import { useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { Loader, WSCheckbox, WSImage } from "components/FormComponent";
import {
  API_SCHEMA,
  DATE_FORMATS,
  FEATURES_NAME,
  GET_API_NAME,
} from "constants/Enums";
import {
  SubTitle,
  GrayDesc,
} from "components/common/WSTitle";
import { isoToFormat } from "utils/helper";
import {
  ImageProps,
  PaginationProps,
} from "constants/GlobalEventTypeScript";
import { EmptyList } from "components/common/EmptyList";
import "./AvailSession.scss";
import moment from "moment";
interface SessionProps {
  query: string;
  eventId: string;
  name: string;
  values?: string[];
}

interface CheckBoxData {
  _id?: string;
  image?: ImageProps;
  attachments?: ImageProps[];
  name?: string;
  sessionDate?: string;
  sessionTime?: string;
  location?: string;
  subTitle?: string;
  designation?: { name: string };
  documentType: string;
}

const AvailSession = ({ query, eventId, name, values }: SessionProps) => {
  const [current, setCurrent] = useState(1);
  const handlePageChange = ({ current }: PaginationProps) => {
    setCurrent(current);
  };
  const { APP_GUIDE } = FEATURES_NAME;
  const { data, loading } = useQuery(API_SCHEMA[query], {
    variables:
      query === APP_GUIDE
        ? { eventId }
        : { limit: 10, offset: current, eventId: eventId },
  });

  const CreateCheckBoxes = (data: CheckBoxData) => {
    return (
      <div className="table-notification-list d-flex align-item-center">
        {data?.image?.url && (
          <WSImage
            imagesPath={data?.image?.url}
            width={40}
            height={40}
            inlineClass="border-red-50"
          />
        )}
        <div className={`ml-20 notification-title-desc`}>
          {data?.name && (
            <SubTitle name={data?.name} inlineClassName="text-transform-cap" />
          )}
          <div className="d-flex justify-content-between">
            {data?.documentType && <GrayDesc name={data?.documentType} />}
            {data?.attachments?.length ? (
              <div
                className="cursor-pointer ml-0-8"
                onClick={() =>
                  data?.attachments?.length &&
                  window.open(data?.attachments[0]?.url)
                }
              >
                Check attachment
              </div>
            ) : null}
          </div>
          {data?.sessionDate && (
            <GrayDesc
              name={`${isoToFormat(
                data?.sessionDate,
                DATE_FORMATS.MMM_D_YYYY
              )} (${moment(data.sessionTime, "hh:mm a").format("h:mm A")} • ${data?.location}`}
            />

          )}
          <GrayDesc name={data?.designation?.name || data?.subTitle} />
        </div>
      </div>
    );
  };

  const checkboxData =
    query === APP_GUIDE
      ? data?.[GET_API_NAME[query]]?.map((data: CheckBoxData) => {
        return {
          value: data?._id,
          label: CreateCheckBoxes(data),
        };
      })
      : data?.[GET_API_NAME[query]]?.nodes
        ? data?.[GET_API_NAME[query]]?.nodes?.map((data: CheckBoxData) => {
          return {
            value: data?._id,
            label: CreateCheckBoxes(data),
          };
        })
        : [];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {checkboxData?.length ? (
            <WSCheckbox labelArr={checkboxData} name={name} value={values} className="avail-session-checkbox" />
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </>
  );
};

export default AvailSession;
