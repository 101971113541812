import axios from 'axios'
import { AppConfig } from 'config/config';
import { getLocalStorageItem, nameFormatter } from './helper';
import { v1 as uuidv1 } from 'uuid';
import { MEDIA_TYPE } from 'constants/Enums';

export const uploadFile = async (file: any, siteFileKey: any, multiple = false) => {

  const allFiles = multiple ? file : [file]
  let images: any[] = []
  await Promise.all(
    allFiles && allFiles.map((singleFile: File) => {
      
      const mimeType: string = singleFile.type
      const mediaType: string = mimeType.split('/')[0]
      
      const fileKey = siteFileKey || nameFormatter([uuidv1(), file?.name?.split(".", 2)[1]], ".")
      return axios.get(`${AppConfig.REACT_APP_IMAGE_UPLOAD_URL}?filename=${fileKey}&token=${getLocalStorageItem('auth_token')}&fileType=${mimeType}&mediaType=${mediaType}`)
        .then(async (response) => {
          const signedRequest = response.data.signedRequest;
          const options = {
            headers: {
              "Content-Type": mimeType
            },
            timeout: 15000
          };
          await axios.put(signedRequest, singleFile , options)
            .then(async (result) => {
              images = [...images, {
                url: URL.createObjectURL(singleFile),
                key: fileKey,
                type: mimeType,
                mediaType: MEDIA_TYPE[mediaType],
                name: singleFile.name
              }]

            })
            .catch(error => {
              console.log(error, 'internal')
            })
        })
        .catch(error => {
          console.log(error, 'out')
        })
    })
  )
  return images
}

export const handleSingleFileUpload = async (file: File, name: string, setFieldValue: Function, values?: any) => {

  if (file) {
    const res = await uploadFile(file, null)
    return setFieldValue(name, res.map(item => ({ ...item })))
    // for multiple
    // res.length && name === "attachments" ? 
    //   setFieldValue(name, [...res.map(item => ({ ...item })), ...values?.attachments]) :
      
  }
}