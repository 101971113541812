import React from 'react'
import { Switch } from 'antd';

interface SwitchProps {
    defaultChecked?: boolean;
    size?: "default" | "small";
    className?: string
    checked?: boolean
    onChange?: (checked: boolean) => void
    loading?:boolean
}

const WSSwitch = ({ defaultChecked, size, className, checked, onChange,loading }: SwitchProps) => {

    return (<Switch onChange={onChange} checked={checked} defaultChecked={defaultChecked} size={size} className={className} loading={loading} />);
}
export default WSSwitch;