import React from "react";
import { WSButton } from "components/FormComponent";
import { ImageUpload } from "pages/features/HandleImage";
import images from "constants/Images";
import { acceptImageFileFormats, BtnVariant } from "constants/Enums";

export const ImageAndDoc = (
  setFieldValue?: (
    name: string,
    event: (string | undefined)[] | string
  ) => void,
  values?: any
) => {
  const { imageUpload, docsUpload, blackClose } = images;
  const { BORDERED } = BtnVariant;
  function closeButton(onClick: () => void, className: string) {
    return (
      <WSButton
        type="button"
        label=""
        leftIcon={blackClose}
        className={className}
        width={8}
        onClick={onClick}
      />
    );
  }
  return (
    <div className='d-flex justify-content-center align-item-center'>
      <ImageUpload
        accept={acceptImageFileFormats}
        showImage={false}
        values={values}
        setFieldValue={setFieldValue}
        mainClassName='custom-help-desk-upload-image-container'
        uploadBtn={
          <WSButton
            className='help-desk-image-upload cursor-pointer'
            type='button'
            variant={BORDERED}
            leftIcon={
              (values?.file?.length && values.file[0]?.url) || imageUpload
            }
            leftImageClass='m-0'
            width='37px'
            height='37px'
            label=''
          />
        }
      />
      {values?.file?.length
        ? closeButton(
            () => setFieldValue && setFieldValue('file', []),
            'img-remove'
          )
        : null}
      <ImageUpload
        name='doc'
        accept='application/*'
        showImage={false}
        values={values}
        setFieldValue={setFieldValue}
        mainClassName='custom-help-desk-upload-image-container help-desk-file-upload-button'
        uploadBtn={
          <WSButton
            className='help-desk-file-upload cursor-pointer'
            variant={BORDERED}
            leftImageClass='m-0'
            leftIcon={docsUpload}
            width='37px'
            type='button'
            height='37px'
            label=''
          />
        }
      />
      {values?.doc?.length
        ? closeButton(
            () => setFieldValue && setFieldValue('doc', []),
            'doc-remove'
          )
        : null}
    </div>
  )
};
