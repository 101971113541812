import React from 'react'

const EventDetailContext = React.createContext({
    storeFieldData: (e: any) => { },
    fieldDataStorage: {},
    addState: () => { },
    minusState: () => { },
    setFieldData: (e: any) => { },
    currentStep: {state: 0, current: 0}
})

export const EventProvider = EventDetailContext.Provider
export const EventConsumer = EventDetailContext.Consumer
export default EventDetailContext