import React, { ReactNode } from 'react'
import { WSImage, Loader } from '..'
import { ButtonVariant } from 'constants/Enums'
import './Button.scss'
interface ButtonProps {
  type?: 'submit' | 'button' | 'reset' | undefined
  danger?: boolean
  label?: string
  className?: string
  rightIcon?: string
  leftIcon?: string
  message?: string
  size?: 'large' | 'small'
  name?: string
  onClick?: any
  shape?: 'circle' | 'circle-outline' | 'round' | undefined
  inlineClass?: string
  rightImageClasses?: string
  leftImageClass?: string
  width?: string | number
  height?: string | number
  icon?: ReactNode
  children?: ReactNode
  variant?: string
  disabled?: boolean
  buttonLoading?: boolean
}

const WSButton = (props: ButtonProps) => {
  const {
    label = 'Submit',
    className,
    onClick,
    rightIcon,
    leftIcon,
    children,
    inlineClass,
    rightImageClasses,
    leftImageClass,
    width,
    height,
    variant,
    type = 'submit',
    disabled = false,
    buttonLoading,
    icon
  } = props

  return (
    <button
      className={`${
        ButtonVariant[variant || 'gradient'].className
      } ${className} ws-button`}
      type={type}
      disabled={buttonLoading}
      onClick={onClick && onClick}
    >
      {buttonLoading ? (
        <Loader loaderColor='text-white' />
      ) : (
        <div
          className={`d-flex align-item-center justify-content-center ${inlineClass}`}
        >
          {leftIcon && (
            <WSImage
              imagesPath={leftIcon || ''}
              inlineClass={`${leftImageClass} mr-10`}
              width={width}
              height={height}
            />
          )}
          {icon && icon}
          {label || ''}
          {rightIcon && (
            <WSImage
              imagesPath={rightIcon || ''}
              inlineClass={`${rightImageClasses} ml-10`}
              width={width}
              height={height}
            />
          )}
        </div>
      )}
      {children}
    </button>
  )
}

export default WSButton
