import React, { ReactNode, ChangeEvent, KeyboardEventHandler } from "react";
import { Input } from "antd";

interface InputProps {
  name?: string;
  inputClass?: string;
  style?: React.CSSProperties;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  icon?: ReactNode;
  bordered?: boolean;
  searchOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  defaultValue?: string;
}

const WSInputSearch = (props: InputProps) => {
  const {
    name,
    inputClass = "",
    onKeyDown,
    placeholder = "Search",
    icon,
    bordered = true,
    searchOnChange,
    value,
    defaultValue
  } = props;

  const betterFunction = (e: any) => debounce(searchOnChange && searchOnChange(e), 500);
  // will change this any if found some meaningful type.. try to apply event type but it's not accepted
  const debounce = (fn: any, delay: number) => {
    let timer: any;    
    return function (...args: any) {
      if(timer) clearTimeout(timer)
      timer = setTimeout(() => {
        fn(...args)
      }, delay)
    }
  }


  return (
    <Input
      value={value}
      defaultValue={defaultValue}
      type="search"
      className={`ant-input d-flex ${inputClass}`}
      bordered={bordered}
      placeholder={placeholder}
      prefix={icon}
      name={name}
      onKeyUp={(e) => betterFunction(e)}
      onKeyDown={onKeyDown}
    />
  );
};

export default WSInputSearch;
