import React, { ReactNode } from 'react'
import { WSModal } from 'components/common'
import { SubTitle } from 'components/common/WSTitle'
import './ConfirmAction.scss'

interface ConfirmationProps {
  showModal?: boolean;
  onCancel?: () => void;
  loading?: boolean
  onConfirm?: () => void
  children?: ReactNode
  footer?: ReactNode[];
}

const Confirmation = ({
  showModal,
  onCancel,
  loading,
  onConfirm,
  children = <SubTitle name="Are you sure to perform this action ?" />,
  footer
}: ConfirmationProps) => {
  return (
    <WSModal
      open={showModal}
      title="Alert"
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmLoading={loading}
      className="confirm-modal"
      footer={footer}
      width="490px"
    >
     {children} 
    </WSModal>
  );
};

export default Confirmation;
