import Schedule1V1 from './Schedule1V1'
import BreakoutSession from './BreakoutSession'
import VIPContent from './VIPContent/VIPContentHandler'
import AppGuide from './AppGuide/AppGuideHandler'
import LiveStream from '../event/eventForms/Livestream/LiveStream'
import VirtualExpo from './VirtualExpo'

import './Features.scss'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Schedule1V1,
  BreakoutSession,
  VIPContent,
  AppGuide,
  // LiveStream,
  VirtualExpo,
};
