import gql from "graphql-tag";

const DELETE_NETWORKING = gql`
  mutation deleteFeatureNetworking($id: String!){
    deleteFeatureNetworking(id: $id){
      _id
    }
  }
`; 

const DELETE_SPONSOR = gql`
  mutation deleteFeatureSponsor($id: String!){
    deleteFeatureSponsor(id: $id){
      _id
    }
  }
`; 

const DELETE_LIVE_STREAM = gql`
  mutation deleteFeatureLiveStream($id: String!){
    deleteFeatureLiveStream(id: $id){
      _id
    }
  }
`; 

const DELETE_ATTENDEE = gql`
  mutation deleteFeatureAttendees($id: String!){
    deleteFeatureAttendees(id: $id){
      _id
    }
  }
`; 

const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($id: String!){
    deleteSchedule(id: $id){
      _id
    }
  }
`; 

const DELETE_PRESENTER = gql`
  mutation deletePresenter($id: String!){
    deletePresenter(id: $id){
      _id
    }
  }
`; 

const DELETE_RECORDING = gql`
  mutation deleteFeatureRecording($id: String!){
    deleteFeatureRecording(id: $id){
      _id
    }
  }
`; 

const DELETE_SLIDE = gql`
  mutation deletefeatureSlide($id: String!){
    deletefeatureSlide(id: $id){
      _id
    }
  }
`; 

const DELETE_STREAM_OPTION = gql`
  mutation deleteStreamOption($id: String!){
    deleteStreamOption(id: $id){
      _id
    }
  }
`; 

const DELETE_BOOK_MEETINGS = gql`
  mutation deleteBookingMeeting($id: String!){
    deleteBookingMeeting(id: $id){
      _id
    }
  }
`; 

const DELETE_ANNOUNCEMENT = gql`
  mutation deleteFeatureAnnouncement($id: String!) {
    deleteFeatureAnnouncement(id: $id) {
      _id
    }
  }
`;

const DELETE_AMA_SESSION = gql`
  mutation deleteFeatureAmaSessions($id: String!) {
    deleteFeatureAmaSessions(id: $id) {
      _id
    }
  }
`;

const DELETE_LIVE_POLLS = gql`
  mutation deletePoll($id: String!) {
    deletePoll(id: $id) {
      _id
    }
  }
`;

const DELETE_SURVEY = gql`
 mutation deleteSurvey($id: String!){
   deleteSurvey(id: $id){
     _id
   }
 }
`

const DELETE_BANNER = gql`
 mutation deleteEventBanner($id: String!){
  deleteEventBanner(id: $id)
 }
`
export {
  DELETE_ATTENDEE,
  DELETE_LIVE_POLLS,
  DELETE_LIVE_STREAM,
  DELETE_NETWORKING,
  DELETE_SPONSOR,
  DELETE_BOOK_MEETINGS,
  DELETE_PRESENTER,
  DELETE_RECORDING,
  DELETE_SCHEDULE,
  DELETE_SLIDE,
  DELETE_STREAM_OPTION,
  DELETE_ANNOUNCEMENT,
  DELETE_AMA_SESSION,
  DELETE_SURVEY,
  DELETE_BANNER
}