import React from "react";
import { WSSelect } from "components/common";
import { DocumentNode, TypedDocumentNode, useQuery } from "@apollo/react-hooks";
import { GET_ALL_SCHEDULE } from "graphQl/Features/QuerySchema";
// import { useHistory } from "react-router-dom";
import { FEATURES_API } from "constants/Enums";
import { useLocation } from "react-router-dom";

export interface InternalLinkProps {
  featureSchema?: DocumentNode | TypedDocumentNode;
  apiName?: string;
}

const InternalLink = ({
  featureSchema = GET_ALL_SCHEDULE,
  apiName = FEATURES_API.getAllSchedule,
}: InternalLinkProps) => {

  // const history = useHistory();
  const location = useLocation()
  const { fieldDataStorage = { getEventDetail: { _id: "" }, _id: "" } } =
    location?.state || {};
  const { data: linkData } = useQuery(featureSchema, {
    variables: {
      offset: 1,
      limit: 20,
      eventId: fieldDataStorage?._id || fieldDataStorage?.getEventDetail?._id,
    },
  });
  return (
    <div className="modal-form">
      <WSSelect
        name="searchLink"
        required
        infoMessage="Search your features for items to link..."
        optionList={linkData?.[apiName]?.nodes || []}
        className="width-100"
      />
      <div className="d-flex justify-content-center align-item-center">
        <span className="internal-link-info text-align-center">
          This item is not linked to any other items yet! Search your features
          above to add item links.
        </span>
      </div>
    </div>
  );
};

export default InternalLink;
